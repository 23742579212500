import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useEffectAsync } from './reactHelper';

import { getDeviceName } from './map/mapUtil';
import ExportButton from './common/ExportButton';
import MenuTitle from './MenuTitle';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Snackbar, InputAdornment, Tooltip, CircularProgress, makeStyles, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Accordion, AccordionSummary, AccordionDetails, Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, TextField, ButtonGroup, IconButton, FormGroup, FormControlLabel, FormLabel, FormHelperText, Switch, Menu, Typography, RadioGroup, Radio, Backdrop, Fab, Fade, Modal } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import { DataGrid, ptBR } from '@material-ui/data-grid';

//https://github.com/Hacker0x01/react-datepicker/
//https://reactdatepicker.com/
//https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import MainToolbar from './MainToolbar';

import t from './common/localization';
import { validateEmail } from './common/stringUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faGasPump } from '@fortawesome/free-solid-svg-icons'

const filterColor = '#78A9FF50'

const colorRed = 'rgba(255, 0, 0, 0.3)';
const colorYellow = 'rgba(255, 255, 0, 0.3)';
const colorGreen = 'rgba(0, 255, 0, 0.3)';
const colorBlue = 'rgba(0, 0, 255, 0.3)';

const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(2),
      padding: '5px'
    },
    dataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outline: 'none' },
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(8),
      right: theme.spacing(2),
    },
  }));

const RefillPanel = () => {

    const classes = useStyles();
    const history = useHistory();
    var is_manager = global.user && global.user.hasOwnProperty('userLimit') && global.user.userLimit != 0;

    const [mapStyle, setMapStyle] = useState("streets-v12");
    const [infoDialog, setInfoDialog] = useState(false);
    const [refillList, setRefillList] = useState([]);
    const [selectedRefill, setSelectedRefill] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterType, setFilterType] = useState(-1);
    const [filterGroup, setFilterGroup] = useState('all');
    const [filterFuelType, setFilterFuelType] = useState('all');
    const [filterDevice, setFilterDevice] = useState('all');
    const [filterDriver, setFilterDriver] = useState('all');
    const [filterText, setFilterText] = useState(global.refillPanelSearchText);
    const [updateResult, setUpdateResult] = useState(false);
    const [dialogTab, setDialogTab] = useState('details');
    const [settingsMenu, setSettingsMenu] = useState(null);
    const [showAppQrCode, setShowAppQrCode] = useState(false);
    const [appVersion, setAppVersion] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const [emailTo, setEmailTo] = useState("");
    const [emailCc, setEmailCc] = useState(validateEmail(useSelector(state => state.session.user.email)) ? useSelector(state => state.session.user.email) : '');

    const groups = useSelector(state => state.groups.items);
    const groupsArray = useSelector(state => Object.values(state.groups.items));
    const devicesArray = useSelector(state => Object.values(state.devices.items));
    const driversArray = useSelector(state => Object.values(state.drivers.items));

    const [startDate, setStartDate] = useState(global.refillPanelFromDate || new Date());
    const [endDate, setEndDate] = useState(global.refillPanelToDate || new Date());
    const [showCalendar, setShowCalendar] = useState(false);

    const maximumPeriodDays = 365;

    const [resultStatus, setResultStatus] = useState(false);
    const [statusDialog, setStatusDialog] = useState({title: '', message: ''});
  
    const [stations, setStations] = useState([]);
  
    const showStatusDialog = (title, message) => {
      setStatusDialog({title: title, message: message});
      setResultStatus(true);
    }
  
    const hideStatusDialog = () => {
      setResultStatus(false);
    };

    const dateButtonLabel = () => {
      var label = moment(startDate).format('DD/MM/YYYY')
      if (endDate) {
        if (moment(endDate).format('DD/MM/YYYY') != moment(startDate).format('DD/MM/YYYY')) label += ' - ' + moment(endDate).format('DD/MM/YYYY')
      } else {
        label += ' - ...'
      }
      return label
    }

    useEffectAsync(async () => {
      const response = await fetch('/report/api.php/refill_app_version');
      var apkVersion = false;
      if (response.ok) {
        try {
          const result = await response.json()
          apkVersion = result.apk_version;
        } catch (e) {}
      }
      setAppVersion(apkVersion);
    }, [showAppQrCode]);

    useEffect(() => {
      if (!showCalendar && !endDate) {
        setEndDate(startDate);
      }
    }, [showCalendar])

    useEffect(() => {
      if (endDate) getRefills();
    }, [endDate])

    global.selectedMenu = "/refills"

    const availableFuelTypes = [
      {id: 'diesel'},
      {id: 'gasoline'},
    ]

    const openInfoDialog = (refill, item) => {
      item.newStatusDetails = '';
      setSelectedRefill(item)
      setDialogTab('details')
      setInfoDialog(true);
    }

    const closeInfoDialog = (refill) => {
      setInfoDialog(false);
    }

    const columns = [
        {
          field: 'id',
          headerName: t('type'),
          parser: (item) => item.type === "M" ? "Manual" : "App",
          sortable: false,
          minWidth: 90,
          renderCell: (item) => (
            <Button
              onClick={(event) => {openInfoDialog(event, item.row)}}
              style={{
                backgroundColor: item.row.operatorName === null ? colorRed : colorGreen,
                height: '25px',
                fontSize: '0.7em',
                border: 'none',
                minWidth: '70px'
              }}
              variant="outlined">
                {item.row.type === "M" ? "Manual" : "App"}
            </Button>
          ),
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'costDate',
          headerName: t('date'),
          parser: (item) => item.costDate,
          sortable: false,
          minWidth: 160,
          headerAlign: 'center',
        },
        {
          field: 'deviceName',
          headerName: t('vehicle'),
          parser: (item) => item.deviceName,
          sortable: false,
          minWidth: 100,
          headerAlign: 'center',
        },
        {
          field: 'deviceLicensePlate',
          headerName: t('licensePlate'),
          parser: (item) => item.deviceLicensePlate,
          sortable: false,
          minWidth: 100,
          headerAlign: 'center',
        },
        {
          field: 'groupId',
          headerName: t('group'),
          parser: (item) => groups[item.value] ? groups[item.value].name : '',
          sortable: false,
          minWidth: 100,
          renderCell: (item) => (
            <>
              {groups[item.value] ? groups[item.value].name : ''}
            </>
          ),
          headerAlign: 'center',
        },
        {
          field: 'fuelType',
          headerName: t('fuel'),
          parser: (item) => t("fuel_"+item.fuelType),
          sortable: false,
          minWidth: 110,
          renderCell: (item) => (
            <>
              {t("fuel_"+item.value)}
            </>
          ),
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'litres',
          headerName: t('litres'),
          parser: (item) => item.litres,
          sortable: false,
          minWidth: 10,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'consumption',
          headerName: 'L/100',
          parser: (item) => item.consumption,
          sortable: false,
          minWidth: 10,
          //<Tab label="Histórico" value="history"/>
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'km',
          headerName: 'Conta km',
          parser: (item) => item.km,
          sortable: false,
          minWidth: 10,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'hours',
          headerName: 'Conta horas',
          parser: (item) => item.hours,
          sortable: false,
          minWidth: 120,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'delta_km',
          headerName: 'Km Percorridos',
          parser: (item) => item.delta_km,
          sortable: false,
          minWidth: 130,
          align: 'center',
          headerAlign: 'center',
        },

        {
          field: 'delta_hours',
          headerName: 'Horas decorridas',
          parser: (item) => item.delta_hours,
          sortable: false,
          minWidth: 140,
          align: 'center',
          headerAlign: 'center',
        },

        {
          field: 'driverName',
          headerName: t('driver'),
          parser: (item) => item.driverName,
          sortable: false,
          minWidth: 150,
          headerAlign: 'center',
        },
        {
          field: 'operatorName',
          headerName: t('operator'),
          parser: (item) => item.operatorName,
          sortable: false,
          minWidth: 150,
          headerAlign: 'center',
        },
        {
          field: 'stationId',
          headerName: t('refillStation'),
          parser: (item) => getStationName(item.stationId),
          sortable: false,
          minWidth: 200,
          renderCell: (item) => (
            <>
              {getStationName(item.row.stationId)}
            </>
          ),
          headerAlign: 'center',
        },
        {
          field: 'siteName',
          headerName: t('location'),
          parser: (item) => (item.latitude && item.longitude) ? item.latitude + ', ' + item.longitude : '',
          renderCell: (item) => (
            item.row.latitude && item.row.longitude &&
            <a
              href={'https://www.google.com/maps/place/' + item.row.latitude + ',' + item.row.longitude + '/@' + item.row.latitude + ',' + item.row.longitude + ',12z'}
              target='_blank'
              style={{color: '#78A9FF', textDecoration: 'none'}}
              >
              { t('map') }
            </a>
          ),
          sortable: false,
          minWidth: 100,
        headerAlign: 'center',
        },
      ];

    const getRefills = async () => {
      if (moment(endDate).diff(moment(startDate), 'days')+1 > maximumPeriodDays) {
        showStatusDialog('Oops!', `Por favor seleccione um perído máximo de ${maximumPeriodDays} dias`)
      } else {
        setLoading(true);

        var refill_stations = await fetch('/report/api.php/refill_stations');
        if (refill_stations.ok) {
          refill_stations = await refill_stations.json();
          refill_stations = refill_stations.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
          }, {});
          setStations(refill_stations);

          const response = await fetch(`/report/api.php/refills/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, { method: 'GET' });
          if (response.ok) {
            setRefillList(await response.json());
            setLoading(false);
          }
        }

      }
    }

    const getStationName = (stationId) => {
      if (stationId>0 && stations[stationId]) {
        return stations[stationId].name;
      };
      return "";
    }

    const handleAdd = () => {
      global.extraData = -1;
      history.push('/management/refill');
    }

    const handleEdit = (refill) => {
      global.extraData = refill.deviceId;
      history.push(`/management/refill/${refill.id}`);
    }

    const [removeDialog, setRemoveDialog] = React.useState(false);

    const handleRemove = (refill) => {
      setRemoveDialog(refill);
    }

    const handleRemoveConfirm = () => {
      removeRefill(removeDialog);
    }

    const handleRemoveCancel = () => {
      setRemoveDialog(false);
    }

    const removeRefill = async (refill) => {
      const response = await fetch(`/report/api.php/refill/${refill.deviceId}/${refill.id}`, { method: 'DELETE' });

      if (response.ok) {
        setRemoveDialog(false);
        closeInfoDialog();
        getRefills();
      }
      else
      {
        var error_details = response.status + ' ' + response.statusText
        alert(t('zoneRemove'), `${t('zoneRemoveSuccess')} (${error_details})`, 'error')
      }
    };

    const filterRefills = (refillList) => {
      return refillList.filter(refill => 

          (filterType == -1 ? true : filterType == refill.type)
          &&
          (filterGroup != 'all' ? filterGroup == refill.groupId : true)
          &&
          (filterDevice != 'all' ? filterDevice == refill.deviceId : true)
          &&
          (filterDriver != 'all' ? filterDriver == refill.driverId : true)
          &&
          (filterFuelType != 'all' ? filterFuelType == refill.fuelType : true)
          &&
          (filterText != '' ?
            (refill.deviceName && refill.deviceName.toLowerCase().includes(filterText.toLowerCase()))
            || (refill.deviceLicensePlate && refill.deviceLicensePlate.toLowerCase().includes(filterText.toLowerCase()))
            || (refill.driverName && refill.driverName.toLowerCase().includes(filterText.toLowerCase()))
            || (refill.operatorName && refill.operatorName.toLowerCase().includes(filterText.toLowerCase()))
            || (refill.siteName && refill.siteName.toLowerCase().includes(filterText.toLowerCase()))
            : true)
        );
    }

    const getTotalLitres = (refillList) => {
      var getTotalLitres = 0;
      refillList.forEach((refill) => {
        getTotalLitres += Number(refill['litres']);
      });
      return getTotalLitres;
    }

    const sendemail = async () => {
      if (validateEmail(emailTo)) {
        if (validateEmail(emailCc) || emailCc == '') {

          const response = await fetch('/report/api.php/share_refill/' + selectedRefill.id, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
              {
                emailTo: emailTo,
                emailCc: emailCc,
              }),
          });

          if (response.ok) {
            setUpdateResult({message: "Abastecimento enviado por e-mail!", color: 'green'})
          } else {
            setUpdateResult({message: "Erro a enviar e-mail!", color: 'red'})
          }
          setEmailDialog(false)
        } else {
          setUpdateResult({message: "Endereço de e-mail de CC inválido", color: 'red'})
        }
      } else {
        setUpdateResult({message: "Endereço de e-mail de destino inválido", color: 'red'})
      }
    }

    /*
    https://v4.mui.com/api/data-grid/data-grid/
    https://v4.mui.com/components/data-grid/columns/#data-grid-columns
    https://v4.mui.com/components/data-grid/rows/#data-grid-rows
    */

    //console.log("filterRefills(refillList)", filterRefills(refillList))

    return (
      <div className={classes.root}>
        
        <MainToolbar />

        <Modal
          open={showAppQrCode}
          onClose={() => setShowAppQrCode(false)}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Fade in={showAppQrCode}>
            <div style={{ padding: '25px', backgroundColor: 'white', borderRadius: '25px', textAlign: 'center' }}>
              <img src='/qr_refill_app.png' width='250px' />
              <a href="https://abastecimento.ecoogps.com" target="_blank" style={{ display: 'block', fontSize: '1.1em' }}>
                https://abastecimento.ecoogps.com
              </a>
              { appVersion &&
                <div>
                  <br></br>
                  Última versão: {appVersion}
                </div>
              }
            </div>
          </Fade>
        </Modal>

        <MenuTitle title="Central de abastecimentos" style={{ margin: '0px', marginTop: '5px' }} />

        <div style={{ display: 'flex', marginBottom: '5px' }}>

          <div style={{ display: 'flex' }}>

            <Select
                value={filterType}
                onChange={(refill) => setFilterType(refill.target.value)}
                variant="outlined"
                style={{
                  minWidth: 'fit-content',
                  marginLeft: '5px',
                  width: '50px',
                  height: '30px',
                  borderRadius: '50px',
                  position: 'relative',
                  top: '5px',
                  fontSize: '0.8rem',
                  backgroundColor: filterType == -1 ? '' : filterColor,
                }}
                >
                  <MenuItem value={-1}>Todos os tipos</MenuItem>
                  <MenuItem value="M">Manual</MenuItem>
                  <MenuItem value="A">App</MenuItem>
            </Select>
            
            <Select
              value={filterDevice}
              onChange={(refill) => setFilterDevice(refill.target.value)}
              variant="outlined"
              style={{
                minWidth: 'fit-content',
                marginLeft: '5px',
                width: '50px',
                height: '30px',
                borderRadius: '50px',
                position: 'relative',
                top: '5px',
                fontSize: '0.8rem',
                backgroundColor: filterDevice == "all" ? '' : filterColor,
              }}
              >
                <MenuItem value="all">Todos os veículos</MenuItem>
                { devicesArray.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map(device => (
                  <MenuItem value={device.id}>{getDeviceName(device)}</MenuItem>
                ))
                }
            </Select>

            <Select
              value={filterGroup}
              onChange={(refill) => setFilterGroup(refill.target.value)}
              variant="outlined"
              style={{
                minWidth: 'fit-content',
                marginLeft: '5px',
                width: '50px',
                height: '30px',
                borderRadius: '50px',
                position: 'relative',
                top: '5px',
                fontSize: '0.8rem',
                backgroundColor: filterGroup == "all" ? '' : filterColor,
              }}
              >
                <MenuItem value="all">Todos os grupos</MenuItem>
                { groupsArray.map(group => (
                  <MenuItem value={group.id}>{group.name}</MenuItem>
                ))
                }
            </Select>

            <Select
              value={filterFuelType}
              onChange={(refill) => setFilterFuelType(refill.target.value)}
              variant="outlined"
              style={{
                minWidth: 'fit-content',
                marginLeft: '5px',
                width: '50px',
                height: '30px',
                borderRadius: '50px',
                position: 'relative',
                top: '5px',
                fontSize: '0.8rem',
                backgroundColor: filterFuelType == "all" ? '' : filterColor,
              }}
              >
                <MenuItem value="all">Todos os combustíveis</MenuItem>
                { availableFuelTypes.map(fuelType => (
                  <MenuItem value={fuelType.id}>{t("fuel_"+fuelType.id)}</MenuItem>
                ))
                }
            </Select>
            
            <Select
              value={filterDriver}
              onChange={(refill) => setFilterDriver(refill.target.value)}
              variant="outlined"
              style={{
                minWidth: 'fit-content',
                marginLeft: '5px',
                width: '50px',
                height: '30px',
                borderRadius: '50px',
                position: 'relative',
                top: '5px',
                fontSize: '0.8rem',
                backgroundColor: filterDriver == "all" ? '' : filterColor,
              }}
              >
                <MenuItem value="all">Todos os condutores</MenuItem>
                { driversArray.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map(driver => (
                  <MenuItem value={driver.id}>{driver.name}</MenuItem>
                ))
                }
            </Select>

            
            <TextField
              style={{
                marginLeft: '5px',
                minWidth: '200px',
                position: 'relative',
                top: '5px'
              }}
              variant="outlined"
              value={filterText}
              onChange={(refill) => {setFilterText(refill.target.value); global.refillPanelSearchText = refill.target.value}}
              placeholder={t('search')}
              InputProps={{
                style: {
                  height: '30px',
                  padding: '0px 0px 0px 10px',
                  borderRadius: '50px',
                  fontSize: '0.8rem',
                  backgroundColor: filterText == "" ? '' : filterColor,
                },
                startAdornment: (
                  <div>
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                    <InputAdornment style={{right: '0px', position: 'absolute'}} position='end'>
                      <IconButton
                        onClick={() => {setFilterText(''); global.refillPanelSearchText = ''}}
                        onMouseDown={() => {setFilterText(''); global.refillPanelSearchText = ''}}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  </div>
                )
              }}
            />

            <ExportButton
              title='Central de abastecimentos'
              columns={columns}
              data={filterRefills(refillList)}
              filename='Refills'
              style={{marginTop: '5px'}}
            />

            <Tooltip title="Actualizar dados">
              <IconButton
                onClick={() => { getRefills(false) }}
                style={{padding: '0px', color: '#78A9FF', marginLeft: '10px', top: '3px'}}
                >
                  <FontAwesomeIcon icon={faSyncAlt} style={{color: '#78A9FF', fontSize: '0.8em'}}/>
              </IconButton>
            </Tooltip>
            
            <span style={{ marginLeft: '10px', position: 'relative', top: '10px', whiteSpace: 'nowrap' }}>
              { filterRefills(refillList).length + " " + (filterRefills(refillList).length == 1 ? t('refill') : t('refills') ) }
            </span>

            <Button
              onClick={ (e) => { e.preventDefault(); setShowCalendar(!showCalendar); } }
              color="primary"
              variant="outlined"
              style={{ backgroundColor: 'white', height: '30px', right: '5px', position: 'fixed', marginTop: '5px'}}
            >
              {dateButtonLabel()}
            </Button>

              {showCalendar &&
                <div style= {{ position: 'fixed', marginTop: '40px', right: '5px', zIndex: 999 }}>
                  <DatePicker
                    inline
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(item) => {
                      setStartDate(item[0]);
                      setEndDate(item[1]);
                      global.refillPanelFromDate = item[0];
                      global.refillPanelToDate = item[1];
                      if (item[1]) setShowCalendar(false)
                    }}
                    dateFormat="dd/MM/yyyy"
                    todayButton="Hoje"
                    calendarStartDay={1}
                    title="qwertwetwe"
                    isClearable={true}
                    locale={pt}
                    customInput={ () => (<button>xxxxxxxxxx</button>) }
                  />
                </div>
              }
            
          </div>

        </div>

        <div className={classes.content}>

          <Dialog open={infoDialog} onClose={closeInfoDialog} maxWidth="xl">
            <DialogContent style={{ minWidth: '450px' }}>
              <DialogContentText>

                <Tabs
                  value={dialogTab}
                  onChange={(refill, newValue) => setDialogTab(newValue)}
                  variant="fullWidth"
                  TabIndicatorProps={{style: {background: '#78A9FF'}}}
                  textColor="primary"
                  style={{minHeight: '0px'}}
                  >
                    <Tab label="Detalhes" value="details"/>
                </Tabs>

                <div style={{ marginTop: '20px' }}>

                  { dialogTab == 'details' && (
                    <div style={{ display: 'flex' }}>

                      { selectedRefill.latitude && selectedRefill.longitude &&
                        <span>
                          <a href={`https://www.google.com/maps/place/${selectedRefill.latitude},${selectedRefill.longitude}`} target="_blank">
                            <img src={`https://api.mapbox.com/styles/v1/mapbox/${mapStyle}/static/pin-l+00b3ff(${selectedRefill.longitude},${selectedRefill.latitude}/${selectedRefill.longitude},${selectedRefill.latitude},15,0/300x300?access_token=pk.eyJ1IjoidHJhY2tpbmdpbnRlZ3JhdGlvbiIsImEiOiJja2t0cGJiZmYwcXFnMnFwYmFsczNwdjAyIn0.jFlT6lSwgMBsyIKvSEIqGQ`} />
                          </a><br></br>
                          <FormControl component="fieldset">
                            <RadioGroup row value={mapStyle} onChange={(e, value) => setMapStyle(value)}>
                              <FormControlLabel value="streets-v12" control={<Radio color="primary" />} label={t('layerRoad')} />
                              <FormControlLabel value="satellite-streets-v12" control={<Radio color="primary" />} label={t('layerSatellite')} />
                            </RadioGroup>
                          </FormControl>
                        </span>
                      }
                      <table
                      style={{
                        paddingLeft: '10px'
                      }}>
                        <tbody>
                          <tr>
                            <td><b>{t('vehicle')}:</b></td>
                            <td>{selectedRefill.deviceName}</td>
                          </tr>
                          <tr>
                            <td><b>{t('licensePlate')}:</b></td>
                            <td>{selectedRefill.deviceLicensePlate}</td>
                          </tr>
                          <tr>
                            <td><b>{t('group')}:</b></td>
                            <td>{groups[selectedRefill.groupId] ? groups[selectedRefill.groupId].name : ''}</td>
                          </tr>

                          <tr>
                            <td><b>{t('date')}:</b></td>
                            <td>{selectedRefill.costDate}</td>
                          </tr>

                          <tr>
                            <td><b>{t('type')}:</b></td>
                            <td>{selectedRefill.type === "M" ? "Manual" : "App"}</td>
                          </tr>
                          { selectedRefill.operatorName &&
                            <tr>
                              <td><b>{t('operator')}:</b></td>
                              <td>{selectedRefill.operatorName + (selectedRefill.operatorCode ? ` (${selectedRefill.operatorCode})` : '')}</td>
                            </tr>
                          }
                          <tr>
                            <td><b>{t('fuel')}:</b></td>
                            <td>{t("fuel_"+selectedRefill.fuelType)}</td>
                          </tr>
                          <tr>
                            <td><b>{t('litres')}:</b></td>
                            <td>{selectedRefill.litres}</td>
                          </tr>
                          <tr>
                            <td><b>{t('km')}:</b></td>
                            <td>{selectedRefill.km}</td>
                          </tr>
                          <tr>
                            <td><b>{t('hours')}:</b></td>
                            <td>{selectedRefill.hours}</td>
                          </tr>
                          <tr>
                            <td><b>{t('driver')}:</b></td>
                            <td>{selectedRefill.driverName}</td>
                          </tr>
                          <tr>
                            <td><b>{t('details')}:</b></td>
                            <td>{selectedRefill.details}</td>
                          </tr>
                          <tr>
                            <td><b>{t('refillStation')}:</b></td>
                            <td>{getStationName(selectedRefill.stationId)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Tooltip title={t('sendByEmail')}>
                <Button onClick={() => setEmailDialog(true)} color="primary" variant="outlined">
                  <MailOutlineIcon />  
                </Button>
              </Tooltip>
              { is_manager &&
                <Button onClick={() => handleRemove(selectedRefill)} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
                  {t('remove')}
                </Button>
              }
              <Button onClick={() => handleEdit(selectedRefill)} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
                {t('edit')}
              </Button>   
              <Button onClick={closeInfoDialog} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
                {t('back')}
              </Button>
            </DialogActions>

          </Dialog>

          <Dialog open={emailDialog} onClose={() => setEmailDialog(false)}>
            <DialogContent style={{ minWidth: '600px' }}>
              <DialogContentText component={'span'}>
                <Typography>
                  Enviar abastecimento por e-mail
                </Typography>
                <br />
                <TextField
                  value={emailTo}
                  onChange={(event) => setEmailTo(event.target.value)}
                  style={{ minWidth: '100%' }}
                  variant="outlined"
                  label="Para"
                />
                <br />
                <br />
                <TextField
                  value={emailCc}
                  onChange={(event) => setEmailCc(event.target.value)}
                  style={{ minWidth: '100%' }}
                  variant="outlined"
                  label="CC"
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={sendemail} color="primary" variant="outlined">
                Enviar
              </Button>
              <Button onClick={() => { setEmailDialog(false) }} color="primary" variant="outlined">
                {t('cancel')}
              </Button>
            </DialogActions>
          </Dialog>
          
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={updateResult}
            autoHideDuration={3000}
            onClose={() => setUpdateResult(false)}
            message={updateResult.message}
            ContentProps={{
              style: {
                backgroundColor: updateResult.color
              }
            }}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setUpdateResult(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />

          <Backdrop className={classes.backdrop} open={loading} onClick={e => setLoading(false)} style={{ zIndex: 999 }}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Dialog open={resultStatus} onClose={hideStatusDialog}>
            <DialogTitle id="form-dialog-title">
              {statusDialog.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText component="span">
                <Grid container spacing={1}>
                  <Grid item>
                    <img src="images/emoji_worried_face.png" width="32px"/>
                  </Grid>
                  <Grid item style={{ marginTop: '4px' }}>
                    <Box>
                    {statusDialog.message}
                    </Box>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={hideStatusDialog} color="primary" variant="outlined">OK</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={removeDialog}>
            <DialogContent>
              <DialogContentText>{t('refillRemove')}?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemoveCancel} color="primary" variant="outlined">{t('cancel')}</Button>
              <Button onClick={handleRemoveConfirm} color="primary" variant="contained">{t('remove')}</Button>
            </DialogActions>
          </Dialog>

          <DataGrid
            className={classes.dataGrid}
            rows={filterRefills(refillList)}
            columns={columns}
            pageSize={100}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            rowHeight={30}
            headerHeight={30}
            disableColumnMenu={true}
            localeText={ptBR.props.MuiDataGrid.localeText}
            />

            <span
              style={{
                position: 'absolute',
                bottom: '10px',
                left: '10px'
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{
                  backgroundColor: 'white',
                  height: '30px',
                  padding: '0px 10px 0px 10px',
                }}
                onClick={(event) => setSettingsMenu(event.currentTarget)}
              >
                  <SettingsIcon />
                  &nbsp;
                  {t('settings')}
              </Button>

              <span style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>
                { filterRefills(refillList).length + " " + (filterRefills(refillList).length == 1 ? t('refill') : t('refills') ) }
                { filterRefills(refillList).length>0 ? 
                  ", " + getTotalLitres(filterRefills(refillList)) + " L"
                :
                  ""
                }
              </span>

            </span>

            <Menu
              anchorEl={settingsMenu}
              keepMounted
              open={Boolean(settingsMenu)}
              onClose={() => setSettingsMenu(null)}
            >
              
              <MenuItem id="refill_operators" onClick={() => history.push('/settings/refill_operators')}>
                <ListItemIcon style={{minWidth: '20px'}}>
                  <FontAwesomeIcon icon={faUser} style={{color: '#78A9FF', fontSize: '1.4em'}} />
                </ListItemIcon>&nbsp;
                <Typography>
                  {t('refillOperators')}
                </Typography>
              </MenuItem>

              { is_manager &&
                <MenuItem id="refill_stations" onClick={() => history.push('/settings/refill_stations')}>
                  <ListItemIcon style={{minWidth: '20px'}}>
                    <FontAwesomeIcon icon={faGasPump} style={{color: '#78A9FF', fontSize: '1.4em'}} />
                  </ListItemIcon>&nbsp;
                  <Typography>
                    {t('refillStations')}
                  </Typography>
                </MenuItem>
              }

              <MenuItem id="qr_codes" onClick={() => history.push('/settings/device_qr_codes')}>
                <ListItemIcon style={{minWidth: '20px'}}>
                  <FontAwesomeIcon icon={faCar} style={{color: '#78A9FF', fontSize: '1.4em'}} />
                </ListItemIcon>&nbsp;
                <Typography>
                  {t('vehicles')}
                </Typography>
              </MenuItem>
              
              <MenuItem id="qr_code_app" onClick={() => {setSettingsMenu(null); setShowAppQrCode(true)}}>
                <ListItemIcon style={{minWidth: '20px'}}>
                  <PhoneIphoneIcon style={{ color: '#78A9FF' }}/>
                </ListItemIcon>&nbsp;
                <Typography>
                  App Android
                </Typography>
              </MenuItem>
              
            </Menu>
          
          <Box>
            <Fab size="medium" color="primary" className={classes.fab} style={{position: 'fixed'}} onClick={handleAdd}>
              <AddIcon />
            </Fab>
          </Box>
            
        </div>
      </div>
    );
}

export default RefillPanel;
