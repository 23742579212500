import t from './common/localization'
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

const MenuTitle = ({ title = false, style = {margin: '0px'}, extraData=false }) => {

  const goBack = () => {
    window.history.back()
  };

  return (
    <h2 style={style}>
      <IconButton
        variant="outlined"
        color="primary"
        style={{padding: '0px', marginLeft: '5px', marginRight: '5px'}}
        onClick={goBack}>
        <ArrowBack />
      </IconButton>
      {title}
      { extraData &&
        <span style={{ float: 'right' }}>
          {extraData}
        </span>
      }
    </h2>
  );
};

export default MenuTitle;
