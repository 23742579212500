import React, { useState } from 'react';
import { useEffectAsync } from '../reactHelper';
import TextField from '@material-ui/core/TextField';
import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionDetails, AccordionSummary, Typography, makeStyles } from '@material-ui/core';
import { addEditString } from '../common/stringUtils';
import TransferList from '../common/TransferList';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {SelectWithSearch} from '../form/SelectField';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const DriverPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({});

  return (
    <EditItemView endpoint="drivers" item={item} setItem={setItem} title={ t('drivers') + ' > ' + addEditString(t('addDriver'), t('editDriver')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>
              <TextField
                margin="normal"
                variant="outlined"
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.attributes && item.attributes.phone ? item.attributes.phone : ""}
                onChange={e => setItem({...item, attributes: {...item.attributes, phone: e.target.value}})}
                label={t('phone')}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.attributes && item.attributes.email ? item.attributes.email : ""}
                onChange={e => setItem({...item, attributes: {...item.attributes, email: e.target.value}})}
                label="e-mail"
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.attributes && item.attributes.notes ? item.attributes.notes : ""}
                onChange={e => setItem({...item, attributes: {...item.attributes, notes: e.target.value}})}
                label={t('notes')}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.uniqueId && item.uniqueId.startsWith('random_') ? '' : (item.uniqueId || '')}
                onChange={event => setItem({...item, uniqueId: event.target.value})}
                label={t('rfidId')}
                />

              <TransferList
                title={t('associatedGroups')}
                item={item}
                setItem={setItem}
                element="associated_groups"
                available={["store", "groups"]}
                associated={["api", "/report/api.php/groups", "driverId"]}
                />

            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={true} style={{marginBottom: '20px'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Carta de condução
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

              <TextField
                margin="normal"
                variant="outlined"
                value={item.attributes && item.attributes.licenseNumber ? item.attributes.licenseNumber : ""}
                onChange={e => setItem({...item, attributes: {...item.attributes, licenseNumber: e.target.value}})}
                label="Nº Carta"
                style={{ width: '25%' }}
                />

              <SelectWithSearch
                label="Categoria"
                items={global.driving_categories}
                item={item.attributes && item.attributes.driving_category ? item.attributes.driving_category : ""}
                onChange={value => setItem({...item, attributes: {...item.attributes, driving_category: value}})}
                style={{marginTop: '15px', width: '50%'}}
                originalOrder={true}
                >
              </SelectWithSearch>

              <span style={{marginTop: '5px', width: '25%'}}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    clearable
                    variant="dialog"
                    format="DD/MM/yyyy"
                    margin="normal"
                    label="Validade"
                    value={item.attributes && item.attributes.expiryDate ? item.attributes.expiryDate : null}
                    onChange={event => setItem({...item, attributes: {...item.attributes, expiryDate: event}})}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </span>

            </AccordionDetails>
          </Accordion>

        </>
      }
    </EditItemView>
  );
}

export default DriverPage;
