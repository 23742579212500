import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { sessionReducer as session } from './session';
import { devicesReducer as devices } from './devices';
import { groupsReducer as groups } from './groups';
import { geofencesReducer as geofences } from './geofences';
import { devicesExtraInfoReducer as devices_extrainfo } from './devices_extrainfo';
import { positionsReducer as positions } from './positions';
import { driversReducer as drivers } from './drivers';
import { maintenancesReducer as maintenances } from './maintenances';
import { tagsReducer as tags } from './tags';

const reducer = combineReducers({
  session,
  devices,
  groups,
  geofences,
  devices_extrainfo,
  positions,
  drivers,
  maintenances,
  tags,
});

export { sessionActions } from './session';
export { devicesActions } from './devices';
export { groupsActions } from './groups';
export { devicesExtraInfoActions } from './devices_extrainfo';
export { positionsActions } from './positions';
export { geofencesActions } from './geofences';
export { driversActions } from './drivers';
export { maintenancesActions } from './maintenances';
export { tagsActions } from './tags';

export default configureStore({ reducer });
