import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportLuandaSpeeding = () => {
  global.selectedMenu = "/report/luanda_speeding"

  const report_name = t('reportLuandaSpeeding')
  const endpoint = '/report/report_luanda_speeding.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    extraFieldList = {{
        "text1_value": 60,
        "text1_name": t('luandaSpeedingInside') + ' (km/h)',

        "text2_value": 90,
        "text2_name": t('luandaSpeedingOutside') + ' (km/h)'
      }}
    >
      <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportLuandaSpeeding;
