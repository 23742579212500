import React, {  } from 'react';
import { useSelector } from 'react-redux';

import PositionsMap from './PositionsMap';

const CurrentPositionsMap = ({listVisible, deviceFilterGroup, deviceFilterSearch, deviceFilterTab, setCameraDialogOpen}) => {
  global.replayMode = false
  const positions = useSelector(state => Object.values(state.positions.items));
  return (<PositionsMap positions={positions} listVisible={listVisible}
    deviceFilterGroup={deviceFilterGroup}
    deviceFilterSearch={deviceFilterSearch}
    deviceFilterTab={deviceFilterTab}
    setCameraDialogOpen={setCameraDialogOpen}
  />);
}

export default CurrentPositionsMap;
