import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionDetails, makeStyles } from '@material-ui/core';
import { addEditString } from '../common/stringUtils';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const GeofencePage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({});

  return (
    <EditItemView endpoint="geofences" item={item} setItem={setItem} title={ t('zones') + ' > ' + addEditString(t('addGeofence'), t('editGeofence')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

                <TextField
                    margin="normal"
                    variant="outlined"
                    value={item.name || ''}
                    onChange={event => setItem({...item, name: event.target.value})}
                    label={t('name')}
                    />

                <TextField
                    margin="normal"
                    variant="outlined"
                    value={item.description || ''}
                    onChange={event => setItem({...item, description: event.target.value})}
                    label={t('description')}
                    />

                <TextField
                    margin="normal"
                    variant="outlined"
                    label={t('speedLimit') + " (km/h)"}
                    value={item.attributes && item.attributes.speedLimit ? item.attributes.speedLimit * 1.852 : 0}
                    onChange={e => setItem({...item, attributes: {...item.attributes, speedLimit: e.target.value/1.852 }})}
                    style={{ width: '25%' }}
                />

                <TransferList
                  title={t('associatedGroups')}
                  item={item}
                  setItem={setItem}
                  element="associated_groups"
                  available={["store", "groups"]}
                  associated={["api", "/report/api.php/groups", "geofenceId"]}
                  searchKey="geofenceId"
                  />

                <TransferList
                  title={t('associatedVehicles')}
                  item={item}
                  setItem={setItem}
                  element="associated_devices"
                  available={["store", "devices"]}
                  associated={["api", "/report/api.php/devices", "geofenceId"]}
                  searchKey="geofenceId"
                  />

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default GeofencePage;
