import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, FormControl, Checkbox, Grid, Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../attributes/EditAttributesView';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FormLabel from '@material-ui/core/FormLabel';
import SelectField from '../form/SelectField';
import { addEditString } from '../common/stringUtils';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const CostPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [item, setItem] = useState({});

  moment.locale((global && global.language) ? global.language.replace('pt', 'pt-br').replace('en', 'en-gb').replace('ar', 'en-gb') : 'en-gb');

  var devices = useSelector(state => Object.values(state.devices.items));

  //var deviceId = history.location.data.selectedId;
  var deviceId = global.extraData;

  if (!deviceId) history.push(`/management/summary`)
  
  const get_device_name = (id) => {
    var result = ''
    devices.forEach(function(device, index) {
      if (device.id == id) {
        result = device.attributes.license_plate
      }
    });
    return result;
  }
  
  const handleToggleCostExpiration = () => {
    setItem({...item, warning: item.warning == 0 ? 1 : 0})
  }

  useEffect(() => {
    if (!item.hasOwnProperty('cost_date')) {
      item.cost_date = moment().format('YYYY-MM-DD')
    }
    if (!item.hasOwnProperty('expiry_date')) {
      item.expiry_date = moment().format('YYYY-MM-DD')
    }
    if (!item.hasOwnProperty('warning')) {
      item.warning = 0
    }
    
  }, [item]);
  
  const cost_types = [
    { "id": "insurance", "name": "Seguro" },
    { "id": "license", "name": "Licença" },
    { "id": "tax", "name": "Imposto" },
    { "id": "cleaning", "name": "Lavagem" },
    { "id": "other", "name": "Outro" }
  ];

  return (
    <EditItemView endpoint={`../report/api.php/cost/${deviceId}`} item={item} setItem={setItem} title={ t('costs') + ' > ' + get_device_name(deviceId) + ' > ' + addEditString(t('addCost'), t('editCost'))}>
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Data do custo"
                  format="DD/MM/yyyy"
                  ampm={false}
                  variant="dialog"
                  value={item.cost_date}
                  onChange={event => setItem({...item, cost_date: event.format('YYYY-MM-DD')})}
                />
              </MuiPickersUtilsProvider>

              <SelectField
                margin="normal"
                value={item.cost_type || ''}
                emptyValue={null}
                onChange={event => setItem({...item, cost_type: event.target.value})}
                data={cost_types}
                label="Tipo de custo"
                />  

              <TextField
                margin="normal"
                variant="outlined"
                multiline
                value={item.entity || ''}
                onChange={event => setItem({...item, entity: event.target.value})}
                label="Entidade"
                />

              <TextField
                margin="normal"
                variant="outlined"
                multiline
                value={item.designation || ''}
                onChange={event => setItem({...item, designation: event.target.value})}
                label="Descrição"
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.invoice || ''}
                onChange={event => setItem({...item, invoice: event.target.value})}
                label="Nr. da factura"
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.cost || ''}
                onChange={event => setItem({...item, cost: event.target.value})}
                label={"Valor (" + global.currency + ")"}
                />

              <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <Switch
                  color="primary"
                  onClick={handleToggleCostExpiration}
                  checked={item.warning == 1}
                />
                <FormLabel>Custo com data de expiração</FormLabel>                
              </div>
              
              {
                item.warning == 1 &&
                <>
                  <MuiPickersUtilsProvider utils={MomentUtils} >
                    <DatePicker
                      label="Data de expiração"
                      format="DD/MM/yyyy"
                      ampm={false}
                      variant="dialog"
                      value={item.expiry_date}
                      onChange={event => setItem({...item, expiry_date: event.format('YYYY-MM-DD')})}
                    />
                  </MuiPickersUtilsProvider>             
                </>
              }

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default CostPage;
