import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useEffectAsync } from '../reactHelper';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, MenuItem, ListItemText, FormControl, InputLabel, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkField from '../form/LinkField';
import SelectField from '../form/SelectField';
import { addEditString } from '../common/stringUtils';
import { getDeviceName } from '../map/mapUtil';
import TransferList from '../common/TransferList';
import {SelectWithSearch} from '../form/SelectField';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const RefillStationPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({});
  const [stations, setStations] = useState([]);

  const fuel_types = [
    { "id": "diesel", "name": "Gasóleo" },
    { "id": "gasoline", "name": "Gasolina" },
  ];

  useEffectAsync(async () => {
    var response = await fetch('/report/api.php/refill_stations');
    if (response.ok) {
      response = await response.json();

      var stationList = [{id: 0, name: "Nenhum"}]

      response.forEach((station) => {
        stationList[station['id']] = station;
        if (stationList[station['id']]['code']) stationList[station['id']]['name'] += ` (${stationList[station['id']]['code']})`
      });

      setStations(stationList);
    }
  }, []);

  return (
    <EditItemView endpoint="../report/api.php/refill_stations" item={item} setItem={setItem} title={ t('refillStations') + ' > ' + addEditString(t('addStation'), t('editStation')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                autoComplete="off"
                />
                
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.code || ''}
                onChange={event => setItem({...item, code: event.target.value})}
                label={t('code')}
                autoComplete="off"
                />

              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.location || ''}
                onChange={event => setItem({...item, location: event.target.value})}
                label={t('location')}
                autoComplete="off"
                />

              <SelectField
                margin="normal"
                value={item.fuel_type || ''}
                emptyValue={null}
                onChange={event => setItem({...item, fuel_type: event.target.value})}
                data={fuel_types}
                label="Tipo de combustível"
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.capacity || ''}
                onChange={event => setItem({...item, capacity: event.target.value})}
                label="Capacidade total"
                />

              <SelectWithSearch
                label="Associação a posto de abastecimento principal"
                items={stations}
                item={item.mainStationId}
                onChange={value => setItem({...item, mainStationId: value})}
                style={{marginTop: '10px'}}
                >
              </SelectWithSearch>


            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default RefillStationPage;
