import React from 'react';
import t from './localization';
import { Menu, MenuItem, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileExcel, faFileCode, faFileCsv } from '@fortawesome/free-solid-svg-icons'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import moment from 'moment';

export const ExportButton = ({
  enabled = true,
  availableFormats = ["pdf", "excel", "csv", "xml"],
  style={},
  columns=[],
  data=[],
  filename='Export',
  includeTimestamp = true,
  title = '',
  }) => {

  const [exportMenu, setExportMenu] = React.useState(null);
  
  const defaultStyle = { marginLeft: '10px', backgroundColor: 'white', height: '30px', padding: '0px 55px 0px 55px', width: '10px' };

  const exportData = async (format) => {

    /*
      console.log("Export")
      console.log("columns", columns)
      console.log("data", data)
    */

    //Exclude columns with empty name
    columns = columns.filter(function (column) { return column.title != ''; });
  
    const outputFilename = filename + (includeTimestamp ? "_" + moment().format('YYYY-MM-DD_HH.mm.ss') : "")
  
    switch (format) {
      case 'pdf':
  
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'mm',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
          compress: true,
         });
  
        pdf.setFontSize(18);
        pdf.setFont('Helvetica', 'bold');

        pdf.setTextColor("#78A9FF");
        pdf.setFillColor("#000000");
        pdf.text(title, 5, 10);
        pdf.setFontSize(10);

        const imageURL = '/images/ecoogps.jpg';

        // Add image to the document
        pdf.addImage(imageURL, 'JPEG', 260, 1.5, 32, 10);
          
        const headers = columns.map(column => column.hasOwnProperty('headerName') ? column.headerName : column.title);
  
        var exportData = []
        data.forEach(line => {
          var dataLine = []
          columns.forEach(column => {
            if (typeof column.parser === 'function') {
              dataLine.push(column.parser(line))
            } else {
              dataLine.push(line[column.field])
            }
          });
          exportData.push(dataLine)
        });
        
        //https://github.com/simonbengtsson/jsPDF-AutoTable
        pdf.autoTable({
          head: [headers],
          body: exportData,
          margin: {
            bottom: 5,
            left: 5,
            right: 5
          },
          headStyles: {
            valign: 'middle',
            textColor: '#000000',
            fillColor: '#CCE7F5',
            fontSize: 8,
          },
          bodyStyles: {
            valign: 'middle',
            fontSize: 8,
          },
        });
        
        pdf.save(`${outputFilename}.pdf`);
  
        break;
  
      case 'excel':
        var exportData = []
  
        data.forEach(line => {
          var newLine = {}
          columns.forEach(column => {
            if (typeof column.parser === 'function') {
              newLine[column.hasOwnProperty('headerName') ? column.headerName : column.title] = column.parser(line)
            } else {
              newLine[column.hasOwnProperty('headerName') ? column.headerName : column.title] = line[column.field]
            }
          });
          exportData.push(newLine)
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${outputFilename}.xlsx`);
        break;
  
      case 'csv':
        var exportData = []
  
        var headerLine = []
        columns.forEach(column => {
          headerLine.push(column.hasOwnProperty('headerName') ? column.headerName : column.title)
        });
        exportData
        .push(headerLine.map(value => `"${value}"`)
        .join(","))
  
        data.forEach(line => {
          var dataLine = []
          columns.forEach(column => {
            if (typeof column.parser === 'function') {
              dataLine.push(column.parser(line))
            } else {
              dataLine.push(line[column.field])
            }
          });
          exportData.push(dataLine
            .map(value => value === null ? `""` : `"${value}"`)
            .join(","))
        });
        downloadFile(`${outputFilename}.csv`, "text/csv", exportData.join("\r\n"));
        break;
  
      case 'xml':
        exportData = ""
  
        var headerLine = []
        columns.forEach(column => {
          headerLine.push(column.hasOwnProperty('headerName') ? column.headerName : column.title)
        });
        exportData += "\t<line>\n" + headerLine.map(value => `\t\t<value>${value}</value>`).join("\n") + "\n\t</line>\n";
  
        data.forEach(line => {
          var dataLine = []
          columns.forEach(column => {
            if (typeof column.parser === 'function') {
              dataLine.push(column.parser(line))
            } else {
              dataLine.push(line[column.field])
            }
          });
          exportData += "\t<line>\n" + dataLine.map(value => value === null ? "" : value).map(value => `\t\t<value>${value}</value>`).join("\n") + "\n\t</line>\n";
        });
  
        exportData = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<data>\n"+exportData+"</data>";
  
        downloadFile(`${outputFilename}.xml`, "application/xml", exportData);
        break;
    
      default:
        break;
    }
    setExportMenu(null);
  }
  
  function downloadFile(filename, type, data) {
    const csvContent = "data:"+type+";charset=UTF-8," + encodeURIComponent(data);
    const link = document.createElement("a");
    link.href = csvContent;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ ...defaultStyle, ...style }}
        onClick={(event) => setExportMenu(event.currentTarget)}
        disabled={!enabled}
      >
          <DownloadIcon />
          &nbsp;
          {t('export')}
      </Button>
      <Menu
        anchorEl={exportMenu}
        keepMounted
        open={Boolean(exportMenu)}
        onClose={() => setExportMenu(null)}
        disabled={!enabled}
      >
        { availableFormats.includes('pdf') &&
        <MenuItem id="pdf" onClick={() => exportData('pdf')}>
          <ListItemIcon style={{minWidth: '20px'}}>
            <FontAwesomeIcon icon={faFilePdf} style={{color: '#78A9FF', fontSize: '1.4em'}} />
          </ListItemIcon>&nbsp;
          <Typography>PDF</Typography>
        </MenuItem>
        }
        { availableFormats.includes('excel') &&
        <MenuItem id="excel" onClick={() => exportData('excel')}>
          <ListItemIcon style={{minWidth: '20px'}}>
            <FontAwesomeIcon icon={faFileExcel} style={{color: '#78A9FF', fontSize: '1.4em'}} />
          </ListItemIcon>&nbsp;
          <Typography>Excel</Typography>
        </MenuItem>
        }
        { availableFormats.includes('csv') &&
        <MenuItem id="csv" onClick={() => exportData('csv')}>
          <ListItemIcon style={{minWidth: '20px'}}>
            <FontAwesomeIcon icon={faFileCsv} style={{color: '#78A9FF', fontSize: '1.4em'}} />
          </ListItemIcon>&nbsp;
          <Typography>CSV</Typography>
        </MenuItem>
        }
        { availableFormats.includes('xml') &&
        <MenuItem id="xml" onClick={() => exportData('xml')}>
          <ListItemIcon style={{minWidth: '20px'}}>
            <FontAwesomeIcon icon={faFileCode} style={{color: '#78A9FF', fontSize: '1.4em'}} />
          </ListItemIcon>&nbsp;
          <Typography>XML</Typography>
        </MenuItem>
        }
      </Menu>
    </>
    )
}

export default ExportButton;