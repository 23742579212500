export const geofenceColors = [
	{code: '3bb2d0'}, //Azul
	{code: '00d084'}, //Verde
	{code: 'fcb900'}, //Amarelo
	{code: 'ff6900'}, //Laranja
	{code: 'f44336'}, //Vermelho
	{code: 'b23bd0'}, //Roxo
	{code: '795548'}, //Castanho
	{code: '000000'}, //Preto
];
