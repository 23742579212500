import t from './localization';
import { useLocation } from 'react-router-dom';

export const prefixString = (prefix, value) => {
  return prefix + value.charAt(0).toUpperCase() + value.slice(1);
}

export const exactAlertType = (alert) => {
  if (typeof(alert) != 'undefined') {
    if (alert.type == 'alarm') {
      if (alert.attributes.hasOwnProperty('alarms')) {
        if (alert.attributes.alarms == 'seatbelt,lights,seatbelt+lights') {
          return 'seatbeltAndLights'
        }
        return alert.attributes.alarms
      }
    }
  }  
  return alert.type;
}

export const unprefixString = (prefix, value) => {
  return value.charAt(prefix.length).toLowerCase() + value.slice(prefix.length + 1);
}

export const addEditString = (addString, editString) => {
  const path = useLocation();
  var pathArray = path.pathname.split('/')
  if (isNaN(pathArray[pathArray.length-1])) {
    return addString;
  }
  return editString;
}

export const isAdd = () => {
  const path = useLocation();
  var pathArray = path.pathname.split('/')
  if (isNaN(pathArray[pathArray.length-1])) {
    return true;
  }
  return false;
}

export const validateEmail = (email) => {
  return /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(String(email).toLowerCase());
}

export const generateRandomString = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}