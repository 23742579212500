import { useEffect, useState, useCallback } from 'react';

import { map } from './Map';
import { loadIcon } from './mapUtil';

const TrafficSignsMap = () => {

    useEffect(() => {

        global.loadedTrafficSigns = []

        if (!map.getSource('signs'))
            map.addSource('signs', {
                type: 'vector',
                tiles: ['https://tiles.mapillary.com/maps/vtp/mly_map_feature_traffic_sign/2/{z}/{x}/{y}?access_token=MLY|3671872262913436|33101ac0d2ae528bd645350f0d064718'],
                minzoom: 1,
                maxzoom: 14,
                });
        
        if (!map.getLayer('signs'))
        map.addLayer({
            id: "signs",
            type: "symbol",
            "source-layer": "traffic_sign",
            source: "signs",
            layout: {
                "visibility": (global.user_details.attributes.map_showTrafficSigns ? 'visible' : 'none'),
                "icon-image": ["get", "value"],
                "icon-size": 0.3
            },
            });
        
        map.on('styleimagemissing', (event) => {
            const id = event.id;
            if (global.user_details.attributes.map_showTrafficSigns && !map.hasImage(id) && !global.loadedTrafficSigns.includes(id)) {
                global.loadedTrafficSigns.push(id)
                loadSvgAsync(id)
            }
            })
        
        async function loadSvgAsync(id) {
            const imageData = await loadIcon(`traffic_signs/${id}.svg`, 1.2);
            map.addImage(id, imageData, { pixelRatio: window.devicePixelRatio/1.5 });
            }
            

        return () => {
            if (map.getLayer('signs')) map.removeLayer('signs');
            if (map.getSource('signs')) map.removeSource('signs');
        };

    }, []);

    return (<></>);

}

export default TrafficSignsMap;