import { ExitToApp } from '@material-ui/icons';
import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'devices',
  initialState: {
    items: {},
    selectedId: null
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach(device => {
        let newDevice = Object.assign({}, device);
        newDevice.positions = [];

        if (device.hasOwnProperty('attributes') && device.attributes.hasOwnProperty('has_canbus') && device.attributes.has_canbus) {
          global.has_canbus = true;
        }
        if (device.hasOwnProperty('attributes') && device.attributes.hasOwnProperty('pto_port') && device.attributes.pto_port>0) {
          global.has_pto = true;
        }
        if (device.phone.startsWith("213")) {
          global.showReportEcoDriving = true;
          global.showReportRag = true;
        }

        newDevice.has_immobilization = newDevice.attributes.has_immobilization == "1"
        newDevice.has_temperature = newDevice.attributes.has_temperature == "1"
        
        state.items[device['id']] = newDevice;
      });
    },
    update(state, action) {
      //state.selectedId = null;
      action.payload.forEach(item => state.items[item['id']] = item);
    },
    updateDummy(state, action) {
      //state.selectedId = null;
    },
    select(state, action) {
      if (action.payload) {
        state.selectedId = action.payload.id;
        global.selectedId = action.payload.id;
        global.showPopup = true;
      }
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
  }
});

export { actions as devicesActions };
export { reducer as devicesReducer };
