import React, { useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import MainToolbar from '../MainToolbar';
import { makeStyles, IconButton, Paper } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import t from '../common/localization';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import MenuTitle from '../MenuTitle';
import { useWindowDimensions } from '../common/WindowDimensions';

import MaterialTable from 'material-table'
import ExportButton from '../common/ExportButton';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#78A9FF11 !important"
    }
  },
  paper: {
    padding: theme.spacing(1, 2, 2), minWidth: 'max-content'
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
}));

const UsersView = ({ updateTimestamp, onMenuClick }) => {

  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);
  
  const { pageHeight } = useWindowDimensions();
  const userId = useSelector(state => state.session.user && state.session.user.id);

  document.getElementById('root').style.overflow = 'hidden';

  useEffectAsync(async () => {
    const response = await fetch('/api/users');
    if (response.ok) {
      var users = await response.json();

      users = users.filter(user => !user.email.startsWith('temp_') && user.id != userId);

      var columns = [
        {
          title: '',
          field: 'id',
          cellStyle: {padding: '0px'},
          render: rowData =>
            <IconButton onClick={(event) => onMenuClick(event.currentTarget, rowData.id)}>
              <MoreVertIcon />
            </IconButton>
        },
        { title: t('name'), field: 'name', defaultSort: 'asc', cellStyle: {whiteSpace: 'nowrap'} },
        { title: 'e-mail', field: 'email', cellStyle: {whiteSpace: 'nowrap'} },
        { title: t('phone'), field: 'phone'},
      ]

      setColumns(columns);
      setItems(users.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    }
  }, [updateTimestamp]);
  
  return (
    <>
      <MenuTitle title={t('users')} style={{ position: 'fixed', top: '50px', left: '20px', zIndex: '100' }}/>

      <MaterialTable
        title=""
        icons={tableIcons}
        columns={columns}
        data={items}
        onSearchChange={(value) => global.list_search = value}
        options={{
          sorting: true,
          toolbar: true,
          
          search: true,
          searchText: global.list_search ? global.list_search : '',
          searchFieldVariant: 'outlined',
          searchFieldStyle: {
            height: '35px',
            padding: '0px 0px 0px 10px',
            marginTop: '10px',
            borderRadius: '50px'
          },
          
          paging: true,
          pageSize: 50,
          pageSizeOptions: [10, 50, 100],
          emptyRowsWhenPaging: false,

          padding: "dense",
          maxBodyHeight: pageHeight - 160,
        }}
        actions={[
          {
            isFreeAction: true,
          }
        ]}
        components={{
          Action: props => (
            <div style={{ marginLeft: '20px', top: '5px', position: 'relative' }}>
              <ExportButton
                filename="Users"
                title={t('users')}
                columns={columns}
                data={items}
              />
            </div>
          )
        }}
      />
    </>
  )
}

const UsersPage = () => {
  global.selectedMenu = "/settings/users"
  const classes = useStyles();
  return (
    <>
      <MainToolbar />
      <div className={classes.content}>
        <Paper className={classes.paper} style={{display: 'contents'}}>
          <EditCollectionView content={UsersView} editPath="/settings/user" endpoint="users" allowAddRemove = {true} itemName="utilizador"/>
        </Paper>
      </div>
    </>
  );
}

export default UsersPage;
