import { deployNotification, updateNotification, closeNotification } from "../WebNotification";
import VisibilityIcon from '@material-ui/icons/Visibility';
import t from '../common/localization';
import { getDeviceName } from '../map/mapUtil';

export const followDevice = (device) => {

    global.followDeviceId = device.id;

    const notificationTitle = t('followingVehicle') + " \"" + getDeviceName(device) + "\"";
    const notificationId = "followDevice";

    //If closed, open it
    deployNotification(notificationTitle, false, {
        position: "top-center",
        theme: "dark",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        pauseOnFocusLoss: true,
        icon: ({theme, type}) =>  <VisibilityIcon style={{color: '#78A9FF' }} />,
        onClose: () => global.followDeviceId = false,
        toastId: notificationId
    });

    //If alread open, updade it
    updateNotification(notificationId, notificationTitle)
    
  return null;
}

export const stopFollowingDevice = () => {

    global.followDeviceId = null;
    const notificationId = "followDevice";

    closeNotification(notificationId)
    
  return null;
}

