import t from './common/localization'
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpIcon from '@material-ui/icons/Help';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const RemoveDialog = ({ open, endpoint, itemId, onResult, itemName = 'item' }) => {
  const handleRemove = async () => {
    const response = await fetch(`/api/${endpoint}/${itemId}`, { method: 'DELETE' });
    if (response.ok) {
      onResult(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => { onResult(false) }}>

        <DialogTitle>
          {t('remove')}
        </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Box>
              <HelpIcon style={{ fontSize: '2rem', color: '#3f51b5'}}/>
            </Box>
            <div style={{ marginLeft: '5px', marginTop: '5px' }}>
              {t('remove')} {itemName}?
            </div>
          </Grid>          
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{padding: '15px'}}>
        <Button variant="outlined" color="primary" autoFocus onClick={() => onResult(false)} style={{paddingLeft: '40px', paddingRight: '40px' }}>{t('cancel')}</Button>
        <Button variant="contained" color="primary" onClick={handleRemove} style={{paddingLeft: '40px', paddingRight: '40px' }}>{t('remove')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
