import moment from 'moment';
import "moment/locale/pt";
import 'moment/locale/es';
import 'moment/locale/fr';
import t from '../common/localization';

export const formatPosition = (value, key) => {
  if (value != null && typeof value === 'object') {
    value = value[key];
  }
  switch (key) {
    case 'fixTime':
    case 'deviceTime':
    case 'serverTime':
      return moment(value).format('LLL');
    case 'latitude':
    case 'longitude':
      return value.toFixed(5);
    case 'distance':
      return formatNumber(value/1000, 1);
    case 'ignition':
      if (value) {
        return "Ligada";
      } else {
        return "Desligada";
      }
    case 'speed':
    case 'course':
      return value.toFixed(1);
    case 'batteryLevel':
      return value + '%';
    default:
      if (typeof value === 'number') {
        return formatNumber(value);
      } else if (typeof value === 'boolean') {
        return formatBoolean(value);
      } else {
        return value;
      }
  }
};

export const formatBoolean = (value) => {
  return value ? t('sharedYes') : t('sharedNo');
};

export const formatNumber = (value, precision = 1) => {
  return Number(value.toFixed(precision));
};

export const formatDate = (value, format = 'D/M/YYYY HH:mm:ss') => {
  if (!value) return '';
  return moment(value).format(format);
};

export const extensiveTime = (time) => {
  time = time.split(":");
  var result = (parseInt(time[0]) > 0 ? parseInt(time[0]) + 'h ' : '' )
  var result = result + (parseInt(time[1]) > 0 ? parseInt(time[1]) + 'm ' : '' )
  var result = result + (parseInt(time[2]) > 0 ? parseInt(time[2]) + 's' : '' )
  return result;
};

export const formatTimeAgo = (time, filter = false) => {
  moment.locale(global.language);
  if (filter) if (moment.duration( moment().diff(time)).asHours() > 24) return 'Invalid date';
  return moment(time)
    .fromNow(true)
    .replace('horas', 'h')
    .replace('hours', 'h')
    .replace('heurs', 'h')
    .replace('minutos', 'min')
    .replace('minutes', 'min')
};

export const formatDistance = (value, unit) => {
  switch (unit) {
    case 'mi':
      return `${(value * 0.000621371).toFixed(2)} Mi`;
    case 'nmi':
      return `${(value * 0.000539957).toFixed(2)} Nmi}`;
    case 'km':
    default:
        return `${(value * 0.001).toFixed(2)} Km`;
  }
};

export const formatSpeed = (value, unit) => {
  switch (unit) {
    case 'kmh':
      return `${(value * 1.852).toFixed(1)} km/h`;
    case 'mph':
      return `${(value * 1.15078).toFixed(1)} mph`;
    case 'kn':
    default:
        return `${(value * 1).toFixed(1)} kn`;
  }  
};

export const formatVolume = (value, unit) => {
  switch (unit) {
    case 'impGal':
      return `${(value / 4.546).toFixed(2)} gal`;
    case 'usGal':
      return `${(value / 3.785).toFixed(2)} gal`;
    case 'ltr':
    default:
        return `${(value / 1).toFixed(2)} L`;
  }  
}

export const formatHours = (value, format = 'HH:mm:ss') => {
  return moment.utc(value).format(format);
};

export const getHMS = (seconds) => {
  if (isNaN(seconds)) return "";
  return new Date(seconds * 1000).toISOString().slice(11, 19);
};

export const formatBigNumber = (number) => {
  if (number) {
    return new Intl.NumberFormat('pt-PT').format(number)
  }
  return '';
};

export const formatBigCurrency = (number) => {
  if (number) {
    return new Intl.NumberFormat('pt-PT').format(number) + ' ' + global.currency
  }
  return '';
};

export const validHM = (time) => {
  const regex = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');
  return regex.test(time);
};

export const IsoTimeToDMYHMS = (date) => {
  try {
    return moment.utc(date).format('DD/MM/YYYY HH:mm:ss');
  } catch {}
  return date;
};
