import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { map } from './Map';
import mapboxgl from '!mapbox-gl';
import { geofenceToFeature } from './mapUtil';
import { geofencesActions } from './../store';

const SelectedGeofenceMap = () => {
  const dispatch = useDispatch();

  const mapCenter = useSelector(state => {
    if (state.geofences.selectedGeofenceId) {
		const feature = geofenceToFeature(state.geofences.items[state.geofences.selectedGeofenceId])

		if (feature == null) return null;

		if (feature.geometry.type == 'Point') {
			return [feature.geometry.coordinates[0], feature.geometry.coordinates[1]];
		}
		else {
			var points = feature.geometry.coordinates;

			if (feature.geometry.type == 'Polygon') {
				points = points[0];
			}
			return points;
		}

    }
    return null;
  });

  useEffect(() => {
    if (mapCenter != null && global.selectedGeofenceId) {
		if (Array.isArray(mapCenter[0])) {
			if (mapCenter.length) {
			  const bounds = mapCenter.reduce((bounds, item) => bounds.extend(item), new mapboxgl.LngLatBounds(mapCenter[0], mapCenter[0]));
			  map.fitBounds(bounds, {
				padding: { top: 100, bottom: 100, left: 100, right: 100 },
			  });
			}
		}
		else {
			map.flyTo({ center: mapCenter, zoom: 15, speed: 3 });
		}

      global.selectedGeofenceId = null;
      global.selectedGeofenceName = null;
	  dispatch(geofencesActions.update2(null));
    };
  }, [mapCenter]);

  return null;
}

export default SelectedGeofenceMap;
