import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import t from './common/localization';
import SetDialog from './SetDialog';
import { getDeviceName, immobilizationState } from './map/mapUtil';
import { IsoTimeToDMYHMS } from './common/formatter';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons'

const SetBlocking = ({ open, setOpen, deviceId }) => {

    const devices = useSelector(state => state.devices);
    const positions = useSelector(state => state.positions);

    const [statusDialog, setStatusDialog] = useState({title: '', message: '', icon: ''});
    const [resultStatus, setResultStatus] = useState(false);

    const [selectedName, setSelectedName] = useState('');

    const [blockingStatus, setBlockingStatus] = useState(false);

    const [blockingDetails, setBlockingDetails] = useState({
        ready: false,
        date: '',
        user: ''
    });
    
    useEffect(() => {
        if (devices.items && devices.items[deviceId]) setSelectedName(getDeviceName(devices.items[deviceId]));
    }, [deviceId]);

    useEffect(() => {
        if (open) {
            const isImobilized = immobilizationState(devices.items[deviceId], positions.items[deviceId])
            
            setBlockingStatus(isImobilized);
            setBlockingDetails({
                ready: false,
                date: '',
                user: ''
            })
            getBlockingDetails(isImobilized)
        }
    }, [open]);

    async function getBlockingDetails(isImobilized) {
        const response = await fetch(`/report/api.php/block_details/${deviceId}/${isImobilized ? 1 : 0}`);
        if (response.ok) {
          var result = await response.json();
          if (result.eventtime) {
            setBlockingDetails({
                ready: true,
                date: result.eventtime,
                user: result.user
            })    
          }
        }
      }



    const handleImmobilizationOnConfirm = () => {
        apiImmobilizationPost(deviceId, true);
        handleSetBlockingCancel();
      };
    
    const handleImmobilizationOffConfirm = () => {
        apiImmobilizationPost(deviceId, false);
        handleSetBlockingCancel();
    };

    const handleSetBlockingCancel = () => {
        setOpen(false);
    };

    const apiImmobilizationPost = async (deviceId, immobilizationValue) => {

        const response = await fetch(`/report/api.php/block`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({deviceId, immobilizationValue})
        });
    
        if (response.ok) {
          handleResultStatus(t('blocking'), 'Foi enviado o comando de ' + (immobilizationValue ? 'bloqueio' : 'desbloqueio') + '. Por favor aguarde.', 'ok')
          var lastPositionId = positions.items[deviceId].id
          if (!global.pendingCommands.includes(lastPositionId)) global.pendingCommands.push(lastPositionId)
        }
        else
        {
          response.text().then(function (text) {
            var error_details = response.status + ' ' + response.statusText
            error_details = text.split('(')[0]
            handleResultStatus(t('blocking'), `Erro ao enviar o comando (${error_details})`, 'error')
          });
          
        }
    }

    const handleResultStatus = (title, message, icon) => {
        setStatusDialog({title: title, message: message, icon: icon});
        setResultStatus(true);
      }
    
    const handleResultStatusCancel = () => {
        setResultStatus(false);
    };

  return (
    <>
        <Dialog open={open} onClose={handleSetBlockingCancel}>
            <DialogTitle id="form-dialog-title">
                Bloqueio
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={'span'}>

                    O veículo {selectedName} encontra-se { blockingStatus ? t('blocked').toLowerCase() : t('unBlocked').toLowerCase() }
                    
                    { blockingDetails.ready &&
                        <span>
                            &nbsp;desde {IsoTimeToDMYHMS(blockingDetails.date)} pelo utilizador {blockingDetails.user}
                        </span>
                    }
                    .
                    <p>
                        Pretende bloquear ou desbloquear o veículo?
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSetBlockingCancel} color="primary" variant="outlined" style={{paddingLeft: '20px', paddingRight: '20px' }}>
                    {t('cancel')}
                </Button>
                <Button onClick={handleImmobilizationOnConfirm} color="primary" variant="outlined">
                    <span style={{ color: '#990000' }}>
                        <FontAwesomeIcon icon={faLock} />&nbsp;{t('block')}
                    </span>
                </Button>
                <Button onClick={handleImmobilizationOffConfirm} color="primary" variant="outlined">
                    <span style={{ color: '#009900' }}>
                        <FontAwesomeIcon icon={faLockOpen} />&nbsp;{t('unblock')}
                    </span>
                </Button>
            </DialogActions>
        </Dialog>

        <SetDialog open={resultStatus} setOpen={handleResultStatusCancel} dialogContent={statusDialog} />
    </>
  );
}

export default SetBlocking;
