import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainToolbar from '../MainToolbar';
import { makeStyles, IconButton, Paper, FormControlLabel, Switch } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import t from '../common/localization';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import MenuTitle from '../MenuTitle';
import ExportButton from '../common/ExportButton';
import { formatDate } from '../common/formatter';
import { getOdometerValue, getHourmeterValue } from '../map/mapUtil';
import { useWindowDimensions } from '../common/WindowDimensions';

import MaterialTable from 'material-table'
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#78A9FF11 !important"
    }
  },
  paper: {
    padding: theme.spacing(1, 2, 2), minWidth: 'max-content'
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
}));

const DevicesView = ({ updateTimestamp, onMenuClick }) => {

  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [grouping, setGrouping] = useState(false);
  const user = useSelector(state => state.session.user)
  const showGsm = user.attributes.web_permissions && user.attributes.web_permissions.includes('settings_show_gsm')
  
  const { pageHeight, pageWidth } = useWindowDimensions();
  document.getElementById('root').style.overflow = 'hidden';

  useEffectAsync(async () => {
    const response = await fetch('/report/api.php/devices?disabled=true');
    if (response.ok) {
      var devices = await response.json();

      const response2 = await fetch('/api/positions');
      if (response2.ok) {
        var positions = await response2.json();

        const response3 = await fetch('/api/groups');
        if (response3.ok) {
          var groups = await response3.json();

          var itemList = []
  
          for (let index = 0; index < devices.length; index++) {
            var item = {}
            item.id = devices[index].id
            item.name = devices[index].name
            item.notes = devices[index].contact
            item.licensePlate = devices[index].attributes.license_plate
            item.model = devices[index].model
            item.phone = devices[index].phone
            item.chassis = devices[index].attributes.chassis
            item.device_type = devices[index].attributes.device_type + ((devices[index].hasOwnProperty('attributes') && devices[index].attributes.hasOwnProperty('has_canbus') && devices[index].attributes.has_canbus == true) ? ' (CAN-Bus)' : '')
            item.fuel_type = t("fuel_"+devices[index].attributes.fuel_type)
            item.speedLimit = Math.round(devices[index].attributes.speedLimit*1.852, 0)

            item.group = ''
            for (let index2 = 0; index2 < groups.length; index2++) {
              if (groups[index2].id == devices[index].groupId) item.group = groups[index2].name
            }

            item.odometer = ''
            item.hourmeter = ''
            item.disabled = devices[index].disabled ? t('inactive') : t('active')
            item.showMenu = !devices[index].disabled
            item.lastPosition = ''
            item.address = ''
            for (let index3 = 0; index3 < positions.length; index3++) {
              if (positions[index3].deviceId == devices[index].id) {
                item.odometer = getOdometerValue(positions[index3].attributes, false)
                item.hourmeter = getHourmeterValue(positions[index3].attributes, false)
                item.lastPosition = formatDate(positions[index3].fixTime)
                item.address = positions[index3].address  
              } 
            }
            itemList.push(item)
          }
          setItems(itemList);
        }
      }
    }
  }, [updateTimestamp]);

  useEffect(() => {
    setColumns([
      { title: '',
      field: 'id',
      cellStyle: {padding: '0px'},
      render: rowData =>
        rowData.showMenu ?
          <IconButton onClick={(event) => onMenuClick(event.currentTarget, rowData.id)} style={{ padding: '5px' }}>
            <MoreVertIcon />
          </IconButton>
          :
          <></>
      },
      { title: t('name'), field: 'name', defaultSort: 'asc', cellStyle: {whiteSpace: 'nowrap'} },
      { title: t('licensePlate'), field: 'licensePlate', cellStyle: {whiteSpace: 'nowrap'} },
      { title: t('group'), field: 'group', defaultGroupOrder: grouping ? 1 : -1 },
      { title: t('model'), field: 'model' },
      showGsm ? { title: t('fuel'), field: 'fuel_type' } : null,
      showGsm ? { title: 'Chassis', field: 'chassis' } : null,
      showGsm ? { title: 'GSM', field: 'phone' } : null,
      showGsm ? { title: 'Hardware', field: 'device_type', cellStyle: {whiteSpace: 'nowrap'} } : null,
      showGsm ? { title: t('notes'), field: 'notes' } : null,
      { title: t('speedLimit'), field: 'speedLimit' },
      { title: t('odometer'), field: 'odometer' },
      { title: t('hourmeter'), field: 'hourmeter' },
      { title: t('status'), field: 'disabled' },
      { title: t('lastPosition'), field: 'lastPosition', cellStyle: {whiteSpace: 'nowrap'} },
      { title: t('address'), field: 'address', cellStyle: {whiteSpace: 'nowrap'} },
    ].filter((item) => item !== null));
  }, [updateTimestamp, grouping]);

  return (
    <>
      <MenuTitle title={t('vehicles')} style={{ position: 'fixed', top: '50px', left: '20px', zIndex: '100' }}/>

      <MaterialTable
        title=""
        icons={tableIcons}
        columns={columns}
        data={items}
        onSearchChange={(value) => global.list_search = value}
        options={{
          sorting: true,
          toolbar: true,
          
          search: true,
          searchText: global.list_search ? global.list_search : '',
          searchFieldVariant: 'outlined',
          searchFieldStyle: {
            height: '35px',
            padding: '0px 0px 0px 10px',
            marginTop: '10px',
            borderRadius: '50px'
          },
          
          paging: true,
          pageSize: 50,
          pageSizeOptions: [10, 50, 100],
          emptyRowsWhenPaging: false,

          padding: "dense",
          maxBodyHeight: pageHeight - 160,
        }}
        actions={[
          {
            isFreeAction: true,
          }
        ]}
        components={{
          Action: props => (
            <div style={{ marginLeft: '20px', top: '5px', position: 'relative' }}>
              <FormControlLabel
                label={t('grouping')}
                style={{marginLeft: '0px', marginBottom: '5px'}}
                control={
                  <Switch color="primary"
                    checked={grouping}
                    onChange={event => setGrouping(event.target.checked)}
                  />}
              />
              <ExportButton
                filename="Vehicles"
                title={t('vehicles')}
                columns={columns}
                data={items}
              />
            </div>
          )
        }}
      />
    </>
  )
}

const DevicesPage = () => {
  global.selectedMenu = "/settings/devices"
  const classes = useStyles();
  return (
    <>
      <MainToolbar />
      <div className={classes.content}>
        <Paper className={classes.paper} style={{display: 'contents'}}>
          <EditCollectionView content={DevicesView} editPath="/settings/device" endpoint="devices" allowAddRemove = {false} />
        </Paper>
      </div>
    </>
  );
}

export default DevicesPage;
