import React, { useState } from 'react';
import { useEffectAsync } from '../reactHelper';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionDetails, makeStyles } from '@material-ui/core';
import { addEditString } from '../common/stringUtils';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const GroupPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({});

  const [availableUsers, setAvailableUsers] = useState({});
  useEffectAsync(async () => {
    var response_users = await fetch('/api/users');
    if (response_users.ok) {
      setAvailableUsers((await response_users.json()).filter(user => !user.email.startsWith('temp_')));
    }
  }, []);

  return (
    <EditItemView endpoint="groups" item={item} setItem={setItem} title={ t('groups') + ' > ' + addEditString(t('addGroup'), t('editGroup')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>
              <TextField
                margin="normal"
                variant="outlined"
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                />

              <TransferList
                title={t('associatedVehicles')}
                item={item}
                setItem={setItem}
                element="associated_devices"
                available={["store", "devices"]}
                associated={["api", "/report/api.php/devices", "groupId"]}
                fixedWidth={300}
                />

              <TransferList
                title={t('associatedZones')}
                item={item}
                setItem={setItem}
                element="associated_geofences"
                available={["store", "geofences"]}
                associated={["api", "/api/geofences", "groupId"]}
                fixedWidth={300}
                />

              <TransferList
                title={t('associatedDrivers')}
                item={item}
                setItem={setItem}
                element="associated_drivers"
                available={["store", "drivers"]}
                associated={["api", "/api/drivers", "groupId"]}
                fixedWidth={300}
                />

              <TransferList
                title={t('associatedUsers')}
                item={item}
                setItem={setItem}
                element="associated_users"
                available={["value", availableUsers]}
                associated={["api", "/report/api.php/users", "groupId"]}
                fixedWidth={300}
                />             
            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default GroupPage;
