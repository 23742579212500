import React, { useState, useEffect } from 'react';
import moment from 'moment';
import t from './localization';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

export const PeriodSelector = ({
  period,
  setPeriod,
  setPeriodFrom,
  setPeriodTo,
  total=false,
  days=false,
  weeks=false,
  months=false,
  years=false,
  custom=false,
}) => {

  const [pastYears, setPastYears] = useState([]);

  useEffect(() => {
    if (years) {
      const pastYears = [];
      for (let i = 0; i < years; i++) {
        pastYears.push(moment().year() - i);
      }   
      setPastYears(pastYears);
    }
  }, []);

  const handlePeriodChange = (event) => {
    const period = event.target.value;
    global.period = period
    setPeriod(period)

    var from = false;
    var to = false;

    switch (period) {
      case 'today':
        from = moment().startOf('day');
        to = moment().endOf('day');
        break;
      case 'yesterday':
        from = moment().subtract(1, 'day').startOf('day');
        to = moment().subtract(1, 'day').endOf('day');
        break;
      case 'dayBeforeYesterday':
        from = moment().subtract(2, 'day').startOf('day');
        to = moment().subtract(2, 'day').endOf('day');
        break;
      case 'thisWeek':
        from = moment().startOf('week');
        to = moment().endOf('week');
        break;
      case 'previousWeek':
        from = moment().subtract(1, 'week').startOf('week');
        to = moment().subtract(1, 'week').endOf('week');
        break;
      case 'thisMonth':
        from = moment().startOf('month');
        to = moment().endOf('month');
        break;
      case 'previousMonth':
        from = moment().subtract(1, 'month').startOf('month');
        to = moment().subtract(1, 'month').endOf('month');
        break;
      case 'thisYear':
        from = moment().startOf('year');
        to = moment().endOf('year');
        break;
      case 'previousYear':
        from = moment().subtract(1, 'year').startOf('year');
        to = moment().subtract(1, 'year').endOf('year');
        break;
    }
    
    if (period>2000 && period<3000) {
      from = moment(period+"-01-01", "YYYY-MM-DD").startOf('year');
      to = moment(period+"-01-01", "YYYY-MM-DD").endOf('year');
    }

    setPeriodFrom(from)
    setPeriodTo(to)
  }

  return (
    <>
      <FormControl margin="normal" style={{ display: 'contents' }}>
        <InputLabel shrink>{t('period')}</InputLabel>
        <Select
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
          value={period}
          onChange={handlePeriodChange}
          label={t('period')}>
            { total && <MenuItem key='total' value="total">{t('total')}</MenuItem> }

            { days && <MenuItem key='today' value="today">{t('reportToday')}</MenuItem> }
            { days && <MenuItem key='yesterday' value="yesterday">{t('reportYesterday')}</MenuItem> }
            { days && <MenuItem key='dayBeforeYesterday' value="dayBeforeYesterday">{t('dayBeforeYesterday')}</MenuItem> }
            
            { weeks && <MenuItem key='thisWeek' value="thisWeek">{t('reportThisWeek')}</MenuItem> }
            { weeks && <MenuItem key='previousWeek' value="previousWeek">{t('reportPreviousWeek')}</MenuItem> }

            { months && <MenuItem key='thisMonth' value="thisMonth">{t('reportThisMonth')}</MenuItem> }
            { months && <MenuItem key='previousMonth' value="previousMonth">{t('reportPreviousMonth')}</MenuItem> }
          
            { pastYears.map(pastYear => <MenuItem key={pastYear} value={pastYear}>{t('year')} {pastYear}</MenuItem>) }

            { custom && <MenuItem key='custom' value="custom">{t('reportPickDates')}</MenuItem> }
        </Select>
      </FormControl>
    </>
    )
}

export default PeriodSelector;