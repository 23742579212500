import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { sessionActions } from './store';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import packageJson from '../package.json';
import t from './common/localization';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ErrorIcon from '@material-ui/icons/Error';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { validateEmail } from './common/stringUtils';

const useStyles = makeStyles(theme => ({
  root: {

    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      right: '100px',
    },

    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      right: '0px',
      left: '0px',
    },

    fontSize: '1.1rem',

    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    opacity: 0.85,
    paddingLeft: '30px',
    paddingRight: '30px',
    height: 'auto',
    width: '100%',
    backgroundColor: 'rgba(255 255, 255, 0.8) !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 20px 20px 20px',
  },
  logoLandscape: {
    position: 'fixed', 
    left: '30px',
    top: '30px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoPortrait: {
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  buttons: {
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '40%',
    },
  },

  input: {
    borderRadius: '50px',
    height: '35px',
    paddingLeft: '15px',
    backgroundColor: '#f5f5f5',
    border: 'solid 1px #dddddd',
    width: '100%',
    marginBottom: '40px'
  }

}));

const LoginPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const backgrounds = 9;

  const [backgroundIndex, setBackgroundIndex] = useState(Math.floor(Math.random()*backgrounds)+1);

  const newBackground = () => {
    setBackgroundIndex(backgroundIndex<backgrounds ? backgroundIndex + 1 : 1)
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showQrCode, setShowQrCode] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const [recoverResetDialog, setRecoverResetDialog] = useState(false);
  const [recoverUpdateDialog, setRecoverUpdateDialog] = useState(false);
  const [recoverDialog, setRecoverDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [recoverPassword, setRecoverPassword] = useState('');
  const [passwordResetToken, setPasswordResetToken] = useState(queryParams.get('token'));
  const [passwordResetUser, setPasswordResetUser] = useState(atob(queryParams.get('user')));

  useEffect(() => {
    if (passwordResetUser && passwordResetToken) {
      setRecoverUpdateDialog(true);
    }
  }, []);

  const handlePasswordRecovery = async () => {
    setRecoverResetDialog(false)

    const response = await fetch('/api/password/reset', {
      method: 'POST',
      body: new URLSearchParams(`email=${encodeURIComponent(email)}`),
    });
    if (response.ok) {
      setRecoverDialog(`Foram enviadas instruções de recuperação de password para o endereço de e-mail "${email}".`)
    } else {
      setRecoverDialog(`Não foi possível enviar as instruções de recuperação de password!`)
    }
  }

  const handlePasswordRecoverySetPassword = async () => {
    setRecoverUpdateDialog(false)

    const response = await fetch('/api/password/update', {
      method: 'POST',
      body: new URLSearchParams(`token=${encodeURIComponent(passwordResetToken)}&password=${encodeURIComponent(recoverPassword)}`),
    });
    if (response.ok) {
      setRecoverDialog(`Foi definida a nova password para o utilizador "${passwordResetUser}".`)
    } else {
      setRecoverDialog(`Não foi possível definir a nova password!`)
    }
  }

  document.getElementsByTagName("body")[0].style.background = `url(images/backgrounds/${backgroundIndex}.jpg) no-repeat center center fixed`;
  document.getElementsByTagName("body")[0].style.backgroundSize = 'cover';
  document.getElementsByTagName("body")[0].style.height = '100%';
  document.getElementsByTagName("body")[0].style.overflow = 'hidden';

  global.appVersion = packageJson.version;

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    const response = await fetch('/api/session', {
      method: 'POST',
      body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`),
    });
    if (response.ok) {
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));

      global.user_details = user;
      global.language = user.attributes.language || 'pt';

      history.push('/');
      document.getElementsByTagName("body")[0].style.background = 'none';
      document.getElementsByTagName("body")[0].style.overflow = 'auto';
    } else {
      if (response.status == 400) {
        setErrorDialog('Utilizador bloqueado')
      }
      else if (response.status == 401) {
        setErrorDialog('Endereço de e-mail ou password inválidos')
      }
      else {
        setErrorDialog('Erro de login')
      }

      setPassword('');
    }
  }

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
    <main className={classes.root}>

      <Modal
        open={showQrCode}
        onClose={() => setShowQrCode(false)}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Fade in={showQrCode}>
          <IconButton color="primary" onClick={() => setShowQrCode(false)}>
              <img src='/qr.png' width='250px' alt='Código QR' style={{ padding: '25px', backgroundColor: 'white', borderRadius: '25px' }} />
          </IconButton>
        </Fade>
      </Modal>

      <Dialog open={recoverResetDialog}>
        <DialogTitle>
          Recuperação de password
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Serão enviadas instruções de recuperação de password para o seguinte endereço de e-mail:
          </DialogContentText>
          <TextField
            fullWidth
            value={email}
            onChange={handleEmailChange}
            style={{ marginBottom: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={event => setRecoverResetDialog(false)} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
            {t('cancel')}
          </Button>
          <Button 
            disabled={!validateEmail(email)}
            onClick={handlePasswordRecovery} color="primary" variant="contained" style={{paddingLeft: '40px', paddingRight: '40px' }}>
            Enviar pedido
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={recoverUpdateDialog}>
        <DialogTitle>
          Recuperação de password
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Endereço e-mail:
          </DialogContentText>
          <TextField
            fullWidth
            disabled
            value={passwordResetUser}
            style={{ marginBottom: '20px' }}
          />
          <DialogContentText>
            Introduza a nova password:
          </DialogContentText>
          <TextField
            type="password"
            fullWidth
            autoComplete="off"
            value={recoverPassword}
            onChange={event => setRecoverPassword(event.target.value)}
            style={{ marginBottom: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={event => setRecoverUpdateDialog(false)} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
            {t('cancel')}
          </Button>
          <Button 
            onClick={handlePasswordRecoverySetPassword}
            color="primary" variant="contained" style={{paddingLeft: '40px', paddingRight: '40px' }}>
            Definir nova password
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={recoverDialog}>
        <DialogTitle>
          Recuperação de password
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {recoverDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={event => setRecoverDialog(false)} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialog}>
        <DialogTitle>
          Erro de acesso
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              <Grid item>
                <Box>
                  <ErrorIcon  fontSize="medium" style={{color: '#bb0000'}}/>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  {errorDialog}
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={event => setErrorDialog(false)} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <img src='/logo3.svg' width={256} className={classes.logoLandscape} onClick={ () => newBackground() } />

      <Paper className={classes.paper}>

        <img src='/logo3.svg' width={256} className={classes.logoPortrait} />
        
        <form onSubmit={handleLogin} style={{ width: '100%' }}>


          <div style={{marginLeft: '0px', marginBottom: '30px', fontSize: '30px', fontWeight: 'bold'}}>
            Login
          </div>

          <div style={{marginLeft: '18px', marginBottom: '20px'}}>
            Endereço e-mail
          </div>

          <input
            className={classes.input}
            name='email'
            type="text"
            value={email}
            onChange={handleEmailChange} />
          
          <div style={{marginLeft: '18px', marginBottom: '20px'}}>
            Password
          </div>

          <input
            className={classes.input}
            name='password'
            type="password"
            value={password}
            onChange={handlePasswordChange} />

          <div className={classes.buttons} style={{ marginBottom: '10px', textAlign: 'center' }}>
            <Button type='submit' variant='contained' style={{color: '#ffffff', backgroundColor: '#78A9FF', padding: '8px 40px 8px 40px', minWidth: '-webkit-fill-available', height: '35px', marginBottom: '30px'}}>
              Login
            </Button>
            <a
              onClick={event => setRecoverResetDialog(true)}
              style={{ cursor: 'pointer', fontSize: '0.9em' }}
              >
              Recuperar password
            </a>
          </div>

          <Grid container spacing={0}>
            <Grid xs={6} item>
              <Tooltip title="Código QR para dispositivos móveis">
                <IconButton color="primary" onClick={() => setShowQrCode(!showQrCode)}>
                  <AndroidIcon style={{ color: '#000000' }}/>
                  <AppleIcon style={{ color: '#000000' }}/>
                  <PhoneIphoneIcon style={{ color: '#000000' }}/>
                </IconButton>                
              </Tooltip>
            </Grid>
            <Grid xs={6} item container justifyContent="center" align="right" direction="column" style={{ fontSize: '0.85rem', color: '#000000', fontWeight: 'bold', cursor: 'pointer'}} onClick={() => window.location.reload() }>
              {global.appVersion}
            </Grid>            
          </Grid>

        </form>
      </Paper>
    </main>
    </div>
  );
}

export default LoginPage;
