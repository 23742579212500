import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useEffectAsync } from '../reactHelper';

const StreetImage = ({ position }) => {

    const [streetImage, setStreetImage] = useState(false);

    useEffectAsync(async () => {
        const locationIndex = position.latitude+','+position.longitude+','+position.course

        if (global.streetImageCache[locationIndex]) {
            setStreetImage(global.streetImageCache[locationIndex])
        } else {
            var url = `/report/mapillary.php?lat=${position.latitude}&lon=${position.longitude}&heading=${position.course}&userId=${global.user_details.id}`
            if (position.deviceId) url += `&deviceId=${position.deviceId}`
            if (position.id) url += `&positinId=${position.id}`
            const response = await fetch(url);
            if (response.ok) {
                const result = await response.json();
                if (result.id) {
                    setStreetImage(result)
                    global.streetImageCache[locationIndex] = result
                }
            }
        }
    }, [position]);

  return (
    <>
    { streetImage &&
        <a href={`https://www.mapillary.com/app/?focus=photo&pKey=${streetImage.id}`}
            target="_blank"
            style={{position: 'relative'}}>
            <img src={streetImage.thumb_256_url}
                alt="Vista de rua"
                style={{ borderRadius: '10px', boxShadow: '0 0 0.3em black' }}/>
            <div
                style={{
                    position: 'absolute',
                    right: '15px',
                    bottom: '15px',
                    padding: '0px 6px 0px 6px',
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '12px'
                    }}>
                {Math.round(streetImage.distance) + 'm'}
            </div>                  
        </a>
    }
    </>
  );
}

export default StreetImage;
