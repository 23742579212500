import pt from '../l10n/pt.json';
import pt_original from '../l10n/pt_original.json';
import en from '../l10n/en.json';
import es from '../l10n/es.json';
import fr from '../l10n/fr.json';
import ar from '../l10n/ar.json';

const supportedLanguages = {
  'pt': { data: pt, name: 'Português' },
  'en': { data: en, name: 'English' },
  'es': { data: es, name: 'Español' },
  'fr': { data: fr, name: 'Francais' },
  'ar': { data: ar, name: 'Arabic'}
};

const languages = window.navigator.languages !== undefined ? window.navigator.languages.slice() : [];
let language = window.navigator.userLanguage || window.navigator.language;
languages.push(language);
languages.push(language.substring(0, 2));
languages.push('en');
for (let i = 0; i < languages.length; i++) {
  language = languages[i].replace('-', '_');
  if (language in supportedLanguages) {
    break;
  }
  if (language.length > 2) {
    language = languages[i].substring(0, 2);
    if (language in supportedLanguages) {
      break;
    }
  }
}

export const findStringKeys = (predicate) => {
  language = global.language || 'pt';
  const selectedLanguage = supportedLanguages[language];

  return Object.keys(selectedLanguage.data).filter(predicate);
}

export default key => {
  language = global.language || 'pt';
  const selectedLanguage = supportedLanguages[language];

  return selectedLanguage.data[key] || key;
};
