import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportPerformance = () => {
  global.selectedMenu = "/report/activity"

  const report_name = t('reportPerformance')
  const endpoint = '/report/report_performance.php'

  const [items, setItems] = useState([]);

  const rowStyle = (data) => {
    var style = {};

    var points = data[data.length - 1];;

    const colorRed = '#ffb3b3';
    const colorYellow = '#ffffb3';
    const colorGreen = '#b3ffb3';
    
    if (points >= 80) {
      style.backgroundColor = colorGreen;
    } else if (points >= 50 && points < 80) {
      style.backgroundColor = colorYellow;
    } else if (points < 50) {
      style.backgroundColor = colorRed;
    }

    return style;
  }

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    showVehicles={false}
    showDrivers={false}
    showVehiclesOrDrivers={true}
    >

    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items} rowStyle={rowStyle}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportPerformance;
