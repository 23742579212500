import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'positions',
  initialState: {
    items: {},
  },
  reducers: {
    update(state, action) {
      let maxPositions = 5;
      action.payload.forEach(item => {
        var positions = state.items[item['deviceId']] ? Object.assign([], state.items[item['deviceId']].positions) : [];
        if (!positions.includes([item.longitude, item.latitude])) positions.push([item.longitude, item.latitude]);
        if(positions.length>maxPositions) positions.shift();

        item.positions = positions;
        state.items[item['deviceId']] = item;
      });
    },
  }
});

export { actions as positionsActions };
export { reducer as positionsReducer };
