import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { map } from './Map';




const SelectedDeviceMap = ({listVisible}) => {


  const mapCenter = useSelector(state => {
    if (state.devices.selectedId) {
      const position = state.positions.items[state.devices.selectedId] || null;
      if (position) {
        return [position.longitude - (listVisible ? 0.004 : 0), position.latitude+0.004];
      }
    }
    return null;
  });

  useEffect(() => {
    if (mapCenter != null && global.selectedId && global.showPopup) {
      if(global.openThroughMap === true) {
      } else {
        map.flyTo({ center: mapCenter, zoom: 15, speed: 3 });
        //global.selectedId = null;  
        global.openThroughMap = true
      }
    };
  }, [mapCenter]);

  return null;
}

export default SelectedDeviceMap;
