import React, { useState } from 'react';

import t from '../common/localization';

import { Button, Checkbox, Input, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, makeStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import AddAttributeDialog from './AddAttributeDialog';

const useStyles = makeStyles(theme => ({
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  removeButton: {
    marginRight: theme.spacing(1.5),
  },
}));

const EditAttributesView = ({ attributes, setAttributes, definitions }) => {
  const classes = useStyles();

  const [addDialogShown, setAddDialogShown] = useState(false);

  const convertToList = (attributes) => {
    let booleanList = [];
    let otherList = [];
    for (const key in attributes) {
      const value = attributeDecode(key, attributes[key]);
      const type = getAttributeType(value);
      if (getAttributeVisible(key)) {
        if (type === 'boolean') {
          booleanList.push({ key, value, type });
        } else {
          otherList.push({ key, value, type });
        }
      }
    }
    return otherList.concat(booleanList);
  }

  const handleAddResult = (definition) => {
    setAddDialogShown(false);
    if (definition) {
      switch(definition.type) {
        case 'number':
          updateAttribute(definition.key, 0);
          break;
        case 'boolean':
          updateAttribute(definition.key, false);
          break;
        default:
          updateAttribute(definition.key, "");
          break;
      }
    }
  }

  const updateAttribute = (key, value) => {
    value = attributeEncode(key, value);
    let updatedAttributes = {...attributes};
    updatedAttributes[key] = value;
    setAttributes(updatedAttributes);
  };

  const deleteAttribute = (key) => {
    let updatedAttributes = {...attributes};
    delete updatedAttributes[key];
    setAttributes(updatedAttributes);
  };

  const getAttributeName = (key) => {
    const definition = definitions[key];
    return definition ? definition.name : key;
  };

  const getAttributeType = (value) => {
    if (typeof value === 'number') {
      return 'number';
    } else if (typeof value === 'boolean') {
      return 'boolean';
    } else {
      return 'string';
    }
  };

  const getAttributeVisible = (key) => {
    const visibleAttributes = [
      'speedLimit'
    ]
    return (visibleAttributes.includes(key))
  };
  
  const attributeDecode = (key, value) => {
    if (key == 'speedLimit') value = value * 1.852;
    return value;
  };
  const attributeEncode = (key, value) => {
    if (key == 'speedLimit') value = value / 1.852;
    return value;
  };

  return (
    <>
      {convertToList(attributes).map(({ key, value, type }) => {
        if (type === 'boolean') {
          return (
            <Grid container direction="row" justify="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={e => updateAttribute(key, e.target.checked)}
                    />
                }
                label={getAttributeName(key)} />
              <IconButton className={classes.removeButton} onClick={() => deleteAttribute(key)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          );
        } else {
          return (
            <FormControl margin="normal" key={key} fullWidth={true}>
              <InputLabel>{getAttributeName(key)}</InputLabel>
              <Input
                type={type === 'number' ? 'number' : 'text'}
                value={value || ''}
                onChange={e => updateAttribute(key, e.target.value)}
                />
            </FormControl>
          );
        }
      })}
    </>
  );
}

export default EditAttributesView;
