import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportTrips = () => {
  global.selectedMenu = "/report/stops"

  const report_name = t('reportStops')
  const endpoint = '/report/report_stops.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    showVehicles={true}
    showDrivers={false}
    showVehiclesOrDrivers={false}
    extraFieldList = {{
      "order": "select1,text1",

      "select1_name": "Mostrar os seguintes tipos de paragem:",
      "select1_options": [[0, "Ralenti e Ignição desligada"], [1, "Apenas ralenti"], [2, "Apenas ignição desligada"]],
      "select1_value": 0,

      "text1_value": 5,
      "text1_name": 'Mostrar apenas paragens superiores a (minutos):',
    }}>
    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportTrips;
