import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useEffectAsync } from '../reactHelper';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, MenuItem, ListItemText, FormControl, InputLabel, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkField from '../form/LinkField';
import SelectField from '../form/SelectField';
import { addEditString } from '../common/stringUtils';
import { getDeviceName } from '../map/mapUtil';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const UserPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({});

  const [availablePermissions, setAvailablePermissions] = useState({});
  const [associatedPermissions, setAssociatedPermissions] = useState(false);

  useEffect(() => {
    if (global.user_details && global.user_details.attributes && global.user_details.attributes.web_permissions) {
      setAvailablePermissions(global.user_details.attributes.web_permissions.map(permission => ({ id: permission, name: t('permission_'+permission) })))
    }
  }, [])


  const allowAddRemove = !item.id || (item.id && item.id != global.user.id);

  useEffect(() => {

    if (item && !item.hasOwnProperty('profile')) {
      if (item.disabled) {
        var profile = 0;
      } else if (item.readonly) {
        var profile = 1;
      } else if (!item.readonly && item.userLimit == 0) {
        var profile = 2;
      } else if (!item.readonly && item.userLimit != 0) {
        var profile = 3;
      }
      if (!item.hasOwnProperty('readonly') && !item.hasOwnProperty('userLimit')) {
        var profile = 2;
      }
      setItem({...item, profile: profile})
    }

    if ( item && item.attributes && associatedPermissions === false) {
      if (item.attributes.hasOwnProperty('web_permissions')) {
        setAssociatedPermissions(item.attributes.web_permissions)
      } else {
        setItem({...item, attributes: {...item.attributes, web_permissions: []}})
        setAssociatedPermissions([])
      }
    }
  }, [item])

  const languages = [
    {"id": "pt", "name": "Português"},
    {"id": "en", "name": "English"},
    {"id": "es", "name": "Español"},
    {"id": "fr", "name": "Français"},
    {"id": "ar", "name": "Arabic"}
  ];

  const timezones = [
    {"id": "Africa/Luanda", "name": "Africa/Luanda"},
    {"id": "Africa/Maputo", "name": "Africa/Maputo"},
    {"id": "Africa/Blantyre", "name": "Africa/Blantyre"},
    {"id": "Europe/Lisbon", "name": "Europe/Lisbon"},
    {"id": "Europe/Paris", "name": "Europe/Paris"},
    {"id": "Europe/Madrid", "name": "Europe/Madrid"},
    {"id": "Atlantic/Cape_Verde", "name": "Atlantic/Cape_Verde"},
  ];

  return (
    <EditItemView endpoint="users" item={item} setItem={setItem} title={ t('users') + ' > ' + addEditString(t('addUser'), t('editUser')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.email || ''}
                onChange={event => setItem({...item, email: event.target.value})}
                label={t('email')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                type="password"
                onChange={event => setItem({...item, password: event.target.value})}
                label={t('password')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.phone || ''}
                onChange={event => setItem({...item, phone: event.target.value})}
                label={t('phone')}
                />

              <SelectField
                margin="normal"
                value={item.attributes && item.attributes.timezone ? item.attributes.timezone : 'Africa/Luanda'}
                emptyValue={null}
                onChange={event => setItem({...item, attributes: {...item.attributes, timezone: event.target.value}})}
                data={timezones}
                label={t('timezone')}
                />

             <TextField
                select
                style={{marginTop: '15px'}}
                value={item.attributes && item.attributes.language ? item.attributes.language : 'pt'}
                onChange={event => setItem({...item, attributes: {...item.attributes, language: event.target.value}})}
                label={t('language')}
              >
                {languages.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    <img width="20" src={'images/' + item.id + '.svg'} />
                    <ListItemText primary={ item.name } style={{display: 'inline-block', paddingLeft: '10px'}} />
                  </MenuItem>
                ))}
              </TextField>

              { (!item.id || (item.id && item.id != global.user.id)) &&
                <FormControl style={{ marginTop: '30px', marginBottom: '20px' }}>
                  <InputLabel>
                    {t('profile')}
                  </InputLabel>
                  <Select
                    value={item.profile ?? 0}
                    onChange={event => setItem({...item, profile: event.target.value})}
                  >
                    <MenuItem key={0} value={0}>{t('profileDisabled')}</MenuItem>
                    <MenuItem key={1} value={1}>{t('profileLimited')}</MenuItem>
                    <MenuItem key={2} value={2}>{t('profileOperator')}</MenuItem>
                    <MenuItem key={3} value={3}>{t('profileManager')}</MenuItem>
                  </Select>
                </FormControl>
              }

              <TransferList
                title={t('extraPermissions')}
                item={item}
                setItem={setItem}
                attribute="web_permissions"
                endpoint="web_permissions"

                available={["value", availablePermissions]}
                associated={["value", associatedPermissions]}

                allowAdd={allowAddRemove}
                allowRemove={allowAddRemove}
                />

            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('associations')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

              <TransferList
                title={t('associatedVehicles')}
                item={item}
                setItem={setItem}
                element="associated_devices"
                available={["store", "devices"]}
                associated={["api", "/api/devices", "userId"]}
                allowAdd={allowAddRemove}
                allowRemove={allowAddRemove}
                />

              <TransferList
                title={t('associatedGroups')}
                item={item}
                setItem={setItem}
                element="associated_groups"
                available={["store", "groups"]}
                associated={["api", "/api/groups", "userId"]}
                allowAdd={allowAddRemove}
                allowRemove={allowAddRemove}
                />

              <TransferList
                title={t('associatedZones')}
                item={item}
                setItem={setItem}
                element="associated_geofences"
                available={["store", "geofences"]}
                associated={["api", "/api/geofences", "userId"]}
                allowAdd={allowAddRemove}
                allowRemove={allowAddRemove}
                />

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default UserPage;
