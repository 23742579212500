import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportPassengerPerVehicle = () => {
  global.selectedMenu = "/report/passenger_passenger"

  const report_name = "Relatório de recolhas por passageiro"
  const endpoint = '/report/report_passenger_pickup.php?ReportVersion=3'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    showVehicles = {false}
    showDrivers = {false}
    showPassengers = {true}
    extraFieldList = {{
        "check1_enabled": true,
        "check1_value": false,
        "check1_name": "Mostrar apenas valores totais",        
      }}>
      <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportPassengerPerVehicle;
