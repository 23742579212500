import { FormControl, InputLabel, MenuItem, Select, ListItemText, Checkbox, Chip, Avatar } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffectAsync } from '../reactHelper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getDeviceIconPath } from './../map/mapUtil';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const LinkField = ({
  margin,
  variant,
  label,
  endpointAll,
  endpointLinked,
  baseId,
  keyBase,
  keyLink,
  keyGetter = item => item.id,
  titleGetter = item => item.name,
}) => {
  const [items, setItems] = useState();
  const [linked, setLinked] = useState();

  const classes = useStyles();

  const handleDelete = async id => {
    await fetch('/api/permissions', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(createBody(id)),
    });
    setLinked(linked.filter((item) => item !== id))
  }

  useEffectAsync(async () => {
    const response = await fetch(endpointAll);
    if (response.ok) {
      setItems(await response.json());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(endpointLinked);
    if (response.ok) {
      const data = await response.json();
      setLinked(data.map(it => it.id));
    }
  }, []);

  const createBody = linkId => {
    const body = {};
    body[keyBase] = baseId;
    body[keyLink] = linkId;
    return body;
  }

  const onChange = async event => {
    const oldValue = linked;
    const newValue = event.target.value;
    for (const added of newValue.filter(it => !oldValue.includes(it))) {
      await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(createBody(added)),
      });
    }
    for (const removed of oldValue.filter(it => !newValue.includes(it))) {
      await fetch('/api/permissions', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(createBody(removed)),
      });
    }
    setLinked(newValue);
  };

  if (items && linked) {
    return (
      <FormControl margin={margin} variant={variant}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={linked}
          label={label}

          renderValue={(selected) => (
            <div className={classes.chips}>
              {items.sort(function(a, b) { return titleGetter(a) > titleGetter(b) ? 1 : -1 }).filter(function (item) { return selected.includes(item.id) }).map((item) => (
                <Chip
                  key={item.id}
                  label={titleGetter(item)}
                  className={classes.chip}
                  onMouseDown={e => { e.stopPropagation() }}
                  avatar={
                    keyLink == 'deviceId' ?
                      <Avatar src={getDeviceIconPath(item.category, false)} />
                    :
                      keyLink == 'geofenceId' ?
                        item.attributes.hasOwnProperty('icon') ?
                          <Avatar src={`images/pois/${item.attributes.icon}.png`} />
                        :
                          <Avatar src={`images/pois/geofence.png`} />
                      :
                        <></>
                  }
                  onDelete={() => handleDelete(item.id)}
                  />
              ))}
            </div>
          )}

          onChange={onChange}>
          {items.sort((a, b) => titleGetter(a).localeCompare(titleGetter(b))).map(item => (
            <MenuItem key={keyGetter(item)} value={keyGetter(item)}>
              <Checkbox color="primary" checked={linked.indexOf(keyGetter(item)) > -1} />
              <ListItemText primary={ titleGetter(item) } />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return null;
  }
}
export default LinkField;
