import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import SignalCellularConnectedNoInternet4BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet4Bar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarBattery } from '@fortawesome/free-solid-svg-icons'
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import { faObjectUngroup } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { faGasPump } from '@fortawesome/free-solid-svg-icons'
import { faChargingStation } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { faThermometerFull } from '@fortawesome/free-solid-svg-icons'
import { faSortAlphaDown } from '@fortawesome/free-solid-svg-icons'
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import { faSortNumericDown } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faDrawPolygon } from '@fortawesome/free-solid-svg-icons'
import { faRoute } from '@fortawesome/free-solid-svg-icons'

import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

import { devicesActions } from './store';
import { geofencesActions } from './store';
import { groupsActions } from './store';
import { driversActions } from './store';
import { devicesExtraInfoActions } from './store';
import EditCollectionView from './EditCollectionView';
import { useEffectAsync } from './reactHelper';
import { formatDate, formatTimeAgo, formatBigNumber } from './common/formatter';
import { getItemGroups } from './common/apiHelper';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import t from './common/localization'

import { poiCategories } from './common/poiCategories';

import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SelectField from './form/SelectField';

import { getOdometerTypeCanBus, getOdometerValue, getVehicleIconColor, getBatteryColor, getBatteryValue, getFuelValue, getFuelUnit, getTemperatureValue, getFuelColor, getIgnitionColor, getIgnitionStatus, getImmobilizationColor, getClosestPoi, getDeviceName, immobilizationState, isDriving, isIdle, isStop, hasWarning, inMaintenance, getVehicleIconTip, filterDevice, getDeviceIconPath, getTagNames, getDeviceStatus } from './map/mapUtil';

import Tooltip from '@material-ui/core/Tooltip';

import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import Fab from '@material-ui/core/Fab';
import { faReply } from '@fortawesome/free-solid-svg-icons'
import BuildIcon from '@material-ui/icons/Build';


import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import SvgIcon from '@material-ui/core/SvgIcon';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import CheckIcon from '@material-ui/icons/Check';
import { geofenceColors } from './common/geofenceColors';

const SteeringWheelIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 64c90.53 0 165.82 65.77 181.03 152h-93.9c-11.09-19.05-31.49-32-55.12-32h-64c-23.63 0-44.04 12.95-55.12 32h-93.9C82.18 137.77 157.47 72 248 72zM66.97 288H168l48 64v85.03C140.19 423.65 80.35 363.81 66.97 288zM280 437.03V352l48-64h101.03C415.65 363.81 355.81 423.65 280 437.03z"></path>
    </SvgIcon>
  );
}


const StopIcon = (props) => {
  //viewBox = 0 0 1000 1000
  return (
    <SvgIcon {...props}>
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path fill="currentColor" d="M2264.7,2843.3L1133.8,1712.3V110v-1602.3l1133-1133l1130.9-1130.9H5000h1602.3l1133,1133l1130.9,1130.9V110v1602.3l-1133,1133L6602.3,3976.2H5000H3397.7L2264.7,2843.3z M2833.3,1154.1c231.6-47.5,227.4-41.3,173.7-237.8c-39.3-148.9-47.6-165.4-84.8-155.1c-126.1,41.4-279.1,72.4-347.3,72.4c-165.4,0-299.8-105.4-272.9-212.9c20.7-78.6,64.1-109.6,320.5-221.2c388.7-169.5,481.7-270.8,494.1-539.6c8.3-198.5-37.2-308.1-177.8-432.1c-210.9-186.1-680.2-223.3-1044.1-84.8l-53.7,20.7l41.3,171.6c24.8,95.1,43.4,173.7,45.5,175.7c2.1,0,72.4-20.7,157.1-49.6c126.1-41.3,184-49.6,322.5-43.4c146.8,6.2,177.8,12.4,223.3,55.8c39.3,37.2,53.8,72.4,53.8,130.2c0,95.1-53.8,155.1-190.2,212.9c-394.9,165.4-512.7,248.1-581,403.2c-55.8,126.1-53.8,299.8,6.2,421.8C2049.7,1112.7,2423.9,1240.9,2833.3,1154.1z M5872.5,1162.4c310.1-66.2,539.6-283.2,638.8-599.6c49.6-157.1,51.7-475.5,8.3-649.2c-119.9-454.9-543.8-725.7-1019.3-651.3c-463.1,74.4-734,421.8-731.9,940.7c2.1,328.7,88.9,550,291.5,742.2C5272.9,1143.8,5576.8,1224.4,5872.5,1162.4z M7813.9,1104.5c235.7-91,359.8-277,357.7-539.6c-2.1-392.8-279.1-618.2-756.7-620.3h-161.3v-330.8V-717h-206.7h-206.8v920v920l47.6,10.3c101.3,22.7,204.7,26.9,500.3,20.7C7648.5,1150,7716.7,1143.8,7813.9,1104.5z M4710.5,968V792.3h-258.4h-258.4V37.6V-717h-206.8h-206.7V37.6v754.6h-258.4h-258.4V968v175.7h723.6h723.6V968z"/>
          <path fill="currentColor" d="M5485.9,800.5c-142.7-74.4-225.4-215-262.6-450.7c-64.1-411.4,136.5-756.7,434.2-756.7c277.1,0,442.5,233.6,442.5,620.2c0,394.9-161.3,620.3-442.5,620.3C5597.5,833.6,5521,819.2,5485.9,800.5z"/>
          <path fill="currentColor" d="M7253.6,554.5V275.4H7388c231.6,0,361.8,103.4,361.8,289.5c0,188.1-105.4,268.8-349.4,268.8h-146.8V554.5z"/>
        </g>
      </g>
    </SvgIcon>
  );
}

/*
    <div className="App">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.9 595.3">
        <path fill="currentColor" d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 64c90.53 0 165.82 65.77 181.03 152h-93.9c-11.09-19.05-31.49-32-55.12-32h-64c-23.63 0-44.04 12.95-55.12 32h-93.9C82.18 137.77 157.47 72 248 72zM66.97 288H168l48 64v85.03C140.19 423.65 80.35 363.81 66.97 288zM280 437.03V352l48-64h101.03C415.65 363.81 355.81 423.65 280 437.03z" class=""></path>
      </svg>
    </div>
*/

const useStyles = makeStyles(() => ({
  list: {
    height: '100%',
    maxHeight: '100%',
  },


  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 }, 
  },
  blink: {
      animationName: '$blinker',
      animationDuration: '0.5s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
  },
  tabs: {
    '& button': {
      minWidth: 50,
      fontSize: '8'
    }
  },
  tab: {
    fontSize: '12px'
  }
}));

const DeviceRow = ({ data, index, style }) => {
  //console.log('DeviceRow')
  const classes = useStyles();
  const dispatch = useDispatch();

  const { devices } = data;
  const { onMenuClick } = data;
  const { hidePortraitList } = data;
  const { toggleLevelDisplay } = data;
  const { toggleOdometerHours } = data;
  const { deviceSettings } = data;
  const { showMenu } = data;

  const device = devices[index]

  const style2 = JSON.parse(JSON.stringify(style))
  style2.borderBottom = 'solid 1px #00000040'

  const selectDevice = () => {
    delete global.openThroughMap
    dispatch(devicesActions.select(device))
    hidePortraitList()
  }

  const openMenu = (event) => {
    console.log('onMenuClick')
    onMenuClick(event.currentTarget, device.id)
  }

  const isPrivate = device && device.position && device.position.attributes && device.position.attributes.privacy && device.position.attributes.privacy == 1;
  
  const iconColor = getIgnitionColor(device.position);
  const batteryColor = getBatteryColor(device.position.attributes, device.attributes)
  const isImobilized = immobilizationState(device)

  return (
    <div style={style2}>
        <ListItem button key={device.id} style={{padding: '0px', margin: '0px', height: '100%'}}>
          <div onClick={selectDevice} style={{textAlign: 'center'}}>
          <Tooltip title={getVehicleIconTip(device.position)}>
            <ListItemAvatar>
              <Avatar style={{
                backgroundColor: 'transparent',
                borderStyle: 'solid',
                borderColor: iconColor+'30',
                borderWidth: '1px',
                margin: '0 auto'
            
            }}>
                <img src={ getDeviceIconPath(device.category, device.position) } alt="" style={{ width: '30px', height: '30px' }}/>
              </Avatar>
            </ListItemAvatar>
          </Tooltip>
            <div style={{fontSize: '0.8em', color: iconColor }}>
              {!isNaN(device.position.speed) && !isPrivate ? Math.round(device.position.speed*1.852) + " km/h" : ''}
            </div>              
          </div>
          <ListItemText>

            <Typography component={'span'}>
              <div style={{display: 'flex'}}>
                <div onClick={selectDevice} style={{float: 'left', fontWeight: 'bold', fontSize: '0.85rem', whiteSpace: 'nowrap'}}>
                  { getDeviceName(device) }
                  { device && device.tags && device.tags.length>0 ?
                    <span>
                      <span style={{ color: '#78A9FF', fontWeight: 'bold' }}>
                        &nbsp;+&nbsp;{device.tags.join(', ')}
                      </span>
                    </span>
                  : "" }
                </div>
              { showMenu &&
                <div style={{float: 'right', right: '2px'}}>
                  <IconButton onClick={openMenu} style={{padding: '0px', position: 'absolute', right: '3px', color: '#78A9FF', backgroundColor: 'white', borderRadius: '50px'}}>
                    <MoreVertIcon />
                  </IconButton>
                </div>                  
              }
              </div>
            </Typography>

            {device.group &&
              <Typography onClick={selectDevice} variant="caption" style={{display: 'block'}} component={'span'}>
                <div style={{display: 'flex'}}>
                  <div style={{textAlign: 'center', minWidth: '15px'}}>
                    <FontAwesomeIcon icon={faObjectUngroup}  style={{color: '#78A9FF'}}/>
                  </div>
                  <div style={{float: 'right', paddingLeft: '5px', whiteSpace: 'nowrap'}}>
                    { device.group }
                  </div>                  
                </div>
              </Typography>
            }

            { !isPrivate &&
              <>
                {device.driver.name &&
                  <Typography onClick={selectDevice} variant="caption" style={{display: 'block'}} component={'span'}>
                    <div style={{display: 'flex'}}>
                      <div style={{textAlign: 'center', minWidth: '15px'}}>
                        <FontAwesomeIcon icon={faUser}  style={{color: '#78A9FF'}}/>
                      </div>
                      <div style={{float: 'right', paddingLeft: '5px', whiteSpace: 'nowrap'}}>
                        { device.driver.name }
                        { device.driver.attributes && device.driver.attributes.phone && !device.driver.name.includes(device.driver.attributes.phone) &&
                          <span>
                            &nbsp;(
                            <FontAwesomeIcon icon={faPhoneAlt} style={{marginLeft: '2px', color: '#78A9FF', fontSize: '0.7rem'}}/>
                            &nbsp;
                            <a href={'tel:' + device.driver.attributes.phone} style={{ marginRight: '2px', textDecoration: 'none' }}>
                              {device.driver.attributes.phone}
                            </a>
                            )
                          </span>
                        }
                      </div>                  
                    </div>
                  </Typography>
                }

                <Typography onClick={selectDevice} variant="caption" style={{display: 'block', whiteSpace: 'nowrap'}} component={'span'}>
                  <div style={{display: 'flex'}}>
                    <div style={{textAlign: 'center', minWidth: '15px'}}>
                      <FontAwesomeIcon icon={device.closestPoi == null ? faHome : faMapMarker}  style={{color: '#78A9FF'}}/>
                    </div>
                    <div style={{float: 'right', paddingLeft: '5px'}}>
                      { device.closestPoi == null ? device.position.address : device.closestPoi}
                    </div>                  
                  </div>
                </Typography>
                <Typography onClick={selectDevice} variant="caption" style={{display: 'block'}} component={'span'}>
                  <div style={{display: 'flex'}}>
                    <div style={{textAlign: 'center', minWidth: '15px'}}>
                      <FontAwesomeIcon icon={faInfoCircle}  style={{color: iconColor}}/>
                    </div>
                    <div style={{float: 'right', paddingLeft: '5px', color: iconColor}}>
                      { getDeviceStatus(device.position) }
                    </div>                  
                  </div>
                </Typography>
              </>
            }

            { !!isPrivate &&
              <Typography variant="caption" style={{display: 'block', whiteSpace: 'nowrap'}} component={'span'}>
                <div style={{display: 'flex'}}>
                  <div style={{textAlign: 'center', minWidth: '15px'}}>
                    <FontAwesomeIcon icon={faUserSecret}  style={{color: '#78A9FF'}}/>
                  </div>
                  <div style={{float: 'right', paddingLeft: '5px'}}>
                    { t('private') }
                  </div>                  
                </div>
              </Typography>
            }

            <Typography onClick={selectDevice} variant="caption" component={'span'}>
              <table style={{width: '100%'}}>
                <tbody>
                  <tr>

                    <td style={{width: '25%'}}>
                      <Tooltip title={t('batteryStatus')}>
                        <span className={ batteryColor == '#990000' ? classes.blink : null } style={{ color: batteryColor }}>
                          <FontAwesomeIcon icon={faCarBattery} /> { getBatteryValue(device.position.attributes, device.attributes) }
                        </span>
                      </Tooltip>
                    </td>

                    <td style={{width: '25%'}}>
                      { getFuelValue(device.position, device) &&
                        (
                          <Tooltip title={ device.attributes && device.attributes.fuel_type==1 ? t('batteryLevel') : t('fuelLevel') }>
                            <span onClick={() => toggleLevelDisplay(device.id)} className={ getFuelColor(device.position, device) == '#990000' ? classes.blink : null }
                            style={device.attributes && device.attributes.fuel_type==1 ? {
                                color: getFuelColor(device.position, device),

                                padding: '5px 10px 5px 10px',
                                borderRadius: '15px',
                                backgroundColor: 'rgba(120, 169, 255, 0.20)',
                                borderRadius: '15px',
                                whiteSpace: 'nowrap',
                              } : {
                                color: getFuelColor(device.position, device),
                              }
                            }>
                              <FontAwesomeIcon
                                icon={device.attributes && device.attributes.fuel_type==1 ? faChargingStation : faGasPump}
                              />
                              &nbsp;
                              { getFuelValue(device.position, device, deviceSettings[device.id] ? (deviceSettings[device.id] ? deviceSettings[device.id].levelDisplay : 0) : 0) }
                              &nbsp;
                              { getFuelUnit(device, deviceSettings[device.id] ? deviceSettings[device.id].levelDisplay : 0) }
                            </span>
                          </Tooltip>
                        )
                      }
                      { device.has_temperature && getTemperatureValue(device.position.attributes) &&
                        (
                          <Tooltip title={t('temperature')}>
                            <span style={{ color: '#000099' }}>
                              <FontAwesomeIcon icon={faThermometerFull} /> { getTemperatureValue(device.position.attributes, device, deviceSettings[device.id]) }
                            </span>
                          </Tooltip>
                        )
                      }
                    </td>

                    <td style={{width: '15%', textAlign: 'right'}}>
                      <span style={{display: 'flex'}}>
                      { 
                          device.attributes.in_maintenance &&
                          (
                            <Tooltip title={t('inMaintenance')}>
                              <span className={classes.blink}>
                                <BuildIcon style={{fontSize: 14, color: '#BB0000', marginRight: '10px'}}/>
                              </span>
                            </Tooltip>
                          )
                      }
                      { 
                          !device.position.valid &&
                          device.position.attributes.hasOwnProperty('event') && device.position.event!=150 && device.position.event!=151 && device.position.event!=152 && //CAN-Bus messages has valid=0
                          !(device.position.attributes.io200 > 0) &&
                          (
                            <Tooltip title={t('noGpsSignal')}>
                              <span className={classes.blink}>
                                <FontAwesomeIcon icon={faSatelliteDish}  style={{color: '#BB0000', marginRight: '10px'}}/>
                              </span>
                            </Tooltip>
                          )
                      }
                      {
                          device.position.attributes.hasOwnProperty('rssi') && device.position.attributes.rssi<=1 &&
                          (
                            <Tooltip title={t('lowGsmSignal')}>
                              <span className={classes.blink}>
                                <SignalCellularConnectedNoInternet4BarIcon style={{fontSize: 16, color: '#BB0000', marginRight: '10px'}}/>
                              </span>
                            </Tooltip>
                          )
                      }
                      { 
                        device.has_immobilization && isImobilized &&
                          (
                            <Tooltip title={t('blockedVehicle')}>
                              <span style={{ color: getImmobilizationColor(true) }} className={global.pendingCommands.includes(device.position.id) ? classes.blink : ''}>
                                <FontAwesomeIcon icon={faLock} />
                              </span>
                            </Tooltip>
                          )
                      }
                      { 
                        device.has_immobilization && !isImobilized &&
                          (
                            <Tooltip title={t('unBlockedVehicle')}>
                              <span style={{ color: getImmobilizationColor(false) }} className={global.pendingCommands.includes(device.position.id) ? classes.blink : ''}>
                                <FontAwesomeIcon icon={faLockOpen} />
                              </span>
                            </Tooltip>
                          )
                      }
                      </span>
                    </td>

                    <td style={{width: '35%', textAlign: 'left'}}>
                      <Tooltip title={deviceSettings[device.id] && deviceSettings[device.id].showHours && device.position.attributes.hours ? t('hoursVirtual') : (t('odometerVirtual') + (getOdometerTypeCanBus(device.position.attributes) ? " (CAN-Bus)" : " (GPS)"))}>
                        <span onClick={() => toggleOdometerHours(device.id)}>
                          {
                            deviceSettings[device.id] && deviceSettings[device.id].showHours && device.position.attributes.hours
                            ?
                            <>
                              <FontAwesomeIcon icon={faClock} /> { Math.floor(device.position.attributes.hours/1000/3600)} h
                            </>
                            :
                            <>
                              <FontAwesomeIcon icon={faRoad} /> { getOdometerValue(device.position.attributes) }
                            </>
                          }
                        </span>
                      </Tooltip>
                    </td>

                  </tr>
                </tbody>
              </table>
            </Typography>

          </ListItemText>

        </ListItem>
    </div>
  );
};


const GeofenceRow = ({ data, index, style }) => {
  //console.log('GeofenceRow')
  const classes = useStyles();
  const dispatch = useDispatch();

  const { geofences } = data;
  const { onMenuClick } = data;
  const { hidePortraitList } = data;
  const { showMenu } = data;
  const { anchorGeofenceMenuEl } = data;
  const { handleGeofenceMenuOpen } = data;
  const { handleGeofenceMenuClose } = data;
  const { handleEditGeofence } = data;
  const { handleRemoveGeofence } = data;

  const geofence = geofences[index]

  const style2 = JSON.parse(JSON.stringify(style))
  style2.borderBottom = 'solid 1px #00000040'

  const selectGeofence = () => {
    delete global.openThroughMap
    dispatch(geofencesActions.select(geofence))
    hidePortraitList()
  }
  
  return (
    <div style={style2}>
      <ListItem button key={geofence.id} style={{padding: '0px', margin: '0px', height: '100%'}}>
        <ListItemAvatar onClick={selectGeofence}>
          <Avatar style={{
            backgroundColor: 'transparent',
            borderStyle: 'none',
            borderWidth: '1px',
            margin: '0 auto'
          }}>
            <img src={`images/pois/${'icon' in geofence.attributes && geofence.area.includes('CIRCLE') ? (poiCategories.map(poi => poi.name).includes(geofence.attributes.icon) ? geofence.attributes.icon : 'generic') : 'geofence'}.png`} alt="" width='20px' />
          </Avatar>
        </ListItemAvatar>

        <ListItemText onClick={selectGeofence} style={{ height: '30px' }}>
          <span
            style={{
              fontSize: '0.85rem' ,
              width: (geofence.attributes.hasOwnProperty('speedLimit') && geofence.attributes.speedLimit > 0) ? 220 : 240,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              display: 'inline-flex',
            }}>
            { geofence.name }
          </span>

          { showMenu &&
            <IconButton onClick={(event) => handleGeofenceMenuOpen(event, geofence)}
              style={{
                padding: '0px',
                color: '#78A9FF', 
                float: 'right',
                marginRight: '10px',
                marginTop: '3px'
                }}>
              <MoreVertIcon />
            </IconButton>
          }

          { geofence.attributes.hasOwnProperty('speedLimit') && geofence.attributes.speedLimit > 0 &&
            <span style={{
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              borderColor: 'rgba(255, 0, 0, 0.75)',
              borderWidth: '5px',
              borderStyle: 'solid',
              textAlign: 'center',
              display: 'inline-block',
              fontSize: '0.75rem',
              lineHeight: '1.66',
              float: 'right',
              marginRight: '10px',
              position: 'absolute',
              right: '25px'
              }}>
              { Math.round(geofence.attributes.speedLimit*1.852, 0) }
            </span>
          }

          <Menu
            anchorEl={anchorGeofenceMenuEl}
            open={!!anchorGeofenceMenuEl && geofence.id == global.selectedGeofenceIdMenu}
            onClose={handleGeofenceMenuClose}
          >
            <MenuItem id={ geofence.id } name={ geofence.name } onClick={handleEditGeofence}>{t('edit')}</MenuItem>
            <MenuItem id={ geofence.id } name={ geofence.name } onClick={handleRemoveGeofence}>{t('remove')}</MenuItem>
          </Menu>

        </ListItemText>
      </ListItem>
    </div>
  );
};

const DeviceView = ({ updateTimestamp, onMenuClick, height, extraData, extraData2, setDeviceFilterGroup, setDeviceFilterSearch, setDeviceFilterTab }) => {
  //console.log('DeviceView')
  global.selectedMenu = "/"
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([]);

  const [showSortMenu, setShowSortMenu] = React.useState(null);
  const [sortOption, setSortOption] = React.useState(cookies.sort || 0);

  var geofences = useSelector(state => Object.values(state.geofences.items));
  var devices = useSelector(state => Object.values(state.devices.items));
  var allDevices = devices;
  var groups = useSelector(state => state.groups.items);
  var drivers = useSelector(state => state.drivers.items);
  const positions = useSelector(state => state.positions.items);

  const [deviceSettings, setDeviceSettings] = React.useState([]);

  const [driverKeys, setDriverKeys] = React.useState([]);

  const [searchField, setSearchField] = React.useState(global.mainView.searchField);
  const [groupField, setGroupField] = React.useState(global.mainView.groupField);
  const [tabValue, setTabValue] = React.useState(global.mainView.tabValue);
  const [vehicleTabFilter, setVehicleTabFilter] = React.useState(global.mainView.vehicleTabFilter);
  const [geofenceTabFilter, setGeofenceTabFilter] = React.useState(global.mainView.geofenceTabFilter);

  const [groupStatistics, setGroupStatistics] = React.useState(false);

  const temporaryAccess = useSelector(state => state.session.user && state.session.user.email.startsWith('temp_') );

  useEffect(() => {
    if (Object.keys(drivers).length>0) {
      var result = []
      for (var driverId in drivers) {
        result[drivers[driverId].uniqueId] = drivers[driverId]
      }
      setDriverKeys(result)
    }
  }, [drivers])

  useEffect(() => {
    if (!groupStatistics && devices.length>0 && Object.keys(groups).length) {
      updateGroupStatistics();
    }
  }, [devices])

  useEffect(() => {
    if (devices.length>0 && Object.keys(groups).length) {
      updateGroupStatistics();
    }
  }, [searchField])

  const updateGroupStatistics = () => {
    var statistics = []
    allDevices.forEach(device => {
      if (groups[device.groupId] != undefined && device.groupId == groups[device.groupId].id) {
        statistics[device.groupId] = statistics[device.groupId] === undefined ? 1 : statistics[device.groupId]+1
      }
    });
    setGroupStatistics(statistics);
  }

  global.devices = devices
  global.geofences = geofences

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      var devices = await response.json()
      dispatch(devicesActions.refresh(devices));
      
      var defaultSettings = [];
      devices.forEach(device => {
        defaultSettings[device.id] = {};
        defaultSettings[device.id].showHours = device.attributes && device.attributes.show_hours && device.attributes.show_hours === true;
        defaultSettings[device.id].levelDisplay = 0; //0:%, 1:L/kW, 2:km
        defaultSettings[device.id].maxLevelDisplay = device.attributes.fuel_type==1 ? 2 : 1;
      });
      setDeviceSettings(defaultSettings);
    }
  }, [updateTimestamp]);

/*
  useEffectAsync(async () => {
    const response = await fetch('/api/groups');
    if (response.ok) {
      dispatch(groupsActions.refresh(await response.json()));
    }
  }, [updateTimestamp]);

  useEffectAsync(async () => {
    const response = await fetch('/api/drivers');
    if (response.ok) {
      dispatch(driversActions.refresh(await response.json()));
    }
  }, [updateTimestamp]);

  useEffectAsync(async () => {
    loadGeofenceList();
  }, [updateTimestamp]);
*/

  //Carrega a lista de geofences
  const loadGeofenceList = async (refresh = false) => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
        
      //Recarrega das geofences da próxima vez que correr o GeofenceMap
      if (refresh) global.map_reloadGeofences = true;
    }
  };

  //Obtemos detalhes de uma geofence
  function getGeofenceDetails(geofenceId) {
    for (var i = 0; i < geofences.length; i++) {
      if (geofences[i].id == geofenceId) {
        return geofences[i]
      }
    }
    return null  
  }

  devices = devices.map( (item) => Object.assign({}, item, {selected:false}) )

  var deviceStatistics = {
    all: 0,
    driving: 0,
    idle: 0,
    stop: 0,
    warning: 0,
    maintenance: 0
  }
  var geofenceStatistics = {
    pois: 0,
    geofences: 0,
    lines: 0
  }

  //Adiciona elementos ao object "devices"
  //devices.forEach(function(device, index) {
  for (let index = 0; index < devices.length; index++) {

    devices[index].group = devices[index].groupId && groups && groups[devices[index].groupId] ? groups[devices[index].groupId]['name'] : false

    devices[index].position = []
    devices[index].position.fixTime = ''
    devices[index].position.attributes = []
    devices[index].position.attributes.ignition = ''
    devices[index].closestPoi = null
    devices[index].closestPoiDistance = 0
    devices[index].driver = false;
    devices[index].statusSort = 0;
  
    if (typeof(positions[devices[index].id]) !== 'undefined') {
      var position = positions[devices[index].id]
  
      devices[index].position = position;

      if (devices[index].attributes.driverId && drivers[devices[index].attributes.driverId]) {
        devices[index].driver = drivers[devices[index].attributes.driverId];
      } else {
        if (position.attributes.driverUniqueId == null || position.attributes.driverUniqueId == '') {
          devices[index].driver = false
        } else {
          devices[index].driver = typeof driverKeys[position.attributes.driverUniqueId] !== 'undefined' ? driverKeys[position.attributes.driverUniqueId] : position.attributes.driverUniqueId != '0' ? {name: (position.attributes.driverUniqueId.startsWith('x.') ? '' : 'RFID: ' + position.attributes.driverUniqueId)} : false;
        }
      }
  
      devices[index].tags = getTagNames(position);

      //var poi = getClosestPoi(geofences, position.latitude, position.longitude)
      devices[index].closestPoi = null //poi.closestPoi
      devices[index].closestPoiDistance = 100000000000 //poi.closestPoiDistance

      devices[index].statusSort = getIgnitionStatus(position)
    }

    if (filterDevice(devices[index], drivers, false, searchField, groupField, '')) {
      deviceStatistics.all += 1;
      deviceStatistics.driving += isDriving(devices[index]) ? 1 : 0;
      deviceStatistics.idle += isIdle(devices[index]) ? 1 : 0;
      deviceStatistics.stop += isStop(devices[index]) ? 1 : 0;
      deviceStatistics.warning += hasWarning(devices[index]) ? 1 : 0;
      deviceStatistics.maintenance += inMaintenance(devices[index]) ? 1 : 0;
    }
  }

  const handleChangeSearchField = (event) => {
    setSearchField(event.target.value);
    global.mainView.searchField = event.target.value;
    setDeviceFilterSearch(event.target.value);
  };
  const handleChangeGroupField = (event) => {
    setGroupField(event.target.value);
    global.mainView.groupField = event.target.value;
    setDeviceFilterGroup(event.target.value);
  };

  const clearSearch = () => {
    setSearchField('');
    global.mainView.searchField = '';
    setDeviceFilterSearch('');
  };

  const deviceFilter = (item) => {
    var show = false
    if (searchField == '') {
      show = true
    } else {
      show = (getDeviceName(item) + item.group).toLowerCase().indexOf(searchField.toLowerCase()) != -1
    }
    if (show && groupField > 0) {
      show = item.groupId == groupField
    }

    switch (vehicleTabFilter) {
      case 'driving':
        show = show && isDriving(item);
        break;

      case 'idle':
        show = show && isIdle(item);
        break;

      case 'stop':
        show = show && isStop(item);
        break;

      case 'warning':
        show = show && hasWarning(item);
        break;

      case 'maintenance':
        show = show && inMaintenance(item);
        break;
    
      default:
        break;
    }

    return show;
  };

  const geofenceFilter = (item) => {
    var show = false
    if (searchField == '') {
      show = true
    } else {
      show = item.name.toLowerCase().indexOf(searchField.toLowerCase()) != -1
    }
    return show;
  };

  function deviceSortLogic(a, b) {
    if (sortOption == 0) {
      return a.statusSort - b.statusSort || b.position.attributes.lastignition - a.position.attributes.lastignition;
    }
    if (sortOption == 1) {
      return a.name.localeCompare(b.name);
    }

    if (sortOption == 2) {
      //return b.lastUpdate.localeCompare(a.lastUpdate);
      return b.position.fixTime.localeCompare(a.position.fixTime);
    } 
    
    return a.name.localeCompare(b.name);

  }
  function geofenceSortLogic(a, b) {
    return a.name.localeCompare(b.name)
  }
  
  devices = devices.filter((device) => filterDevice(device, drivers, false, searchField, groupField, vehicleTabFilter)).sort(function(a, b) { return deviceSortLogic(a, b) });
  geofences = geofences.filter(geofenceFilter).sort(function(a, b) { return geofenceSortLogic(a, b) });  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    global.mainView.tabValue = newValue;
    loadGeofenceList();
  };

  const handleVehicleTabFilterChange = (event, newValue) => {
    setVehicleTabFilter(newValue);
    global.mainView.vehicleTabFilter = newValue;
    setDeviceFilterTab(newValue);
  };

  const handleGeofenceTabFilterChange = (event, newValue) => {
    setGeofenceTabFilter(newValue);
    global.mainView.geofenceTabFilter = newValue;
  };

  //console.log(geofences)

  ///////////////////////////////////////////////////////////////////////////////////////////////
  //Menu geofence
  const [anchorGeofenceMenuEl, setGeofenceMenuAnchorElement] = React.useState(null);

  const handleGeofenceMenuOpen = (event, geofence) => {
    selectGeofence(geofence)
    setGeofenceMenuAnchorElement(event.currentTarget);

  };

  const handleGeofenceMenuClose = () => {
    setGeofenceMenuAnchorElement(null);
  };


  ///////////////////////////////////////////////////////////////////////////////////////////////
  //Diálogo de remover geofence
  const [removeGeofenceDialogShown, setRemoveGeofenceDialogShown] = React.useState(false);

  const handleRemoveGeofence = (event) => {
    global.selectedGeofenceIdToRemove = event.target.id;
    global.selectedGeofenceNameToRemove = event.target.getAttribute('name');
    setRemoveGeofenceDialogShown(true);
    handleGeofenceMenuClose();
  }

  const handleRemoveGeofenceConfirm = () => {
    removeGeofence(global.selectedGeofenceIdToRemove);
    setRemoveGeofenceDialogShown(false);
    handleGeofenceMenuClose();
  }

  const removeGeofence = async (id) => {
    const response = await fetch(`/api/geofences/${id}`, { method: 'DELETE' });
    global.map_reloadGeofences = true

    if (response.ok) {
      //handleResultStatus(t('zoneRemove'), t('zoneRemoveSuccess'), 'ok')
      loadGeofenceList(true);
    }
    else
    {
      var error_details = response.status + ' ' + response.statusText
      handleResultStatus(t('zoneRemove'), `${t('zoneRemoveSuccess')} (${error_details})`, 'error')
    }
  };  

  const handleRemoveGeofenceCancel = () => {
    setRemoveGeofenceDialogShown(false);
    global.selectedGeofenceIdToRemove = -1; //Faz fechar o popup do geofence que estamos a apagar
    handleGeofenceMenuClose();
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////
  //Diálogo de editar geofence
  const [editGeofenceDialog, setEditGeofenceDialogShown] = React.useState({show: false, askIcon: false, askRadius: false});
  const [geofenceIcon, setGeofenceIcon] = React.useState('blue-dot');
  const [geofenceColor, setGeofenceColor] = React.useState('3bb2d0');
  const [geofenceFill, setGeofenceFill] = React.useState(false);
  const [geofenceName, setGeofenceName] = React.useState('');
  const [geofenceDescription, setGeofenceDescription] = React.useState('');
  const [geofenceRadius, setGeofenceRadius] = React.useState(100);
  const [geofenceSpeedLimit, setGeofenceSpeedLimit] = React.useState(0);
  const [geofenceGroups, setGeofenceGroups] = useState([]);

  const handleEditGeofence = async (event) => {
    const geofenceId = event.target.id;

    global.selectedGeofenceToEdit = getGeofenceDetails(geofenceId);

    setGeofenceName(global.selectedGeofenceToEdit.name);
    setGeofenceDescription(global.selectedGeofenceToEdit.description);

    if (global.selectedGeofenceToEdit.attributes.hasOwnProperty('icon')) {
      setGeofenceIcon(global.selectedGeofenceToEdit.attributes.icon);
    }

    if (global.selectedGeofenceToEdit.attributes.hasOwnProperty('color')) {
      setGeofenceColor(global.selectedGeofenceToEdit.attributes.color);
    }
    
    if (global.selectedGeofenceToEdit.attributes) {
      setGeofenceFill(global.selectedGeofenceToEdit.attributes.fill || !global.selectedGeofenceToEdit.attributes.hasOwnProperty('fill'));
    }
    
    if (global.selectedGeofenceToEdit.area.substring(0, 6) == 'CIRCLE') {
      var radius = global.selectedGeofenceToEdit.area.split(',')[1].split(')')[0]
      setGeofenceRadius(radius)
    }
    
    if (global.selectedGeofenceToEdit.area.startsWith('LINE')) {
      var radius = global.selectedGeofenceToEdit.attributes.hasOwnProperty('polylineDistance') ? global.selectedGeofenceToEdit.attributes.polylineDistance : 25;
      setGeofenceRadius(radius)
    }
    
    setGeofenceSpeedLimit(global.selectedGeofenceToEdit.attributes.speedLimit * 1.852 || 0)

    setEditGeofenceDialogShown({
      show: true,
      askIcon: (global.selectedGeofenceToEdit.area.startsWith('CIRCLE')),
      askColor: (global.selectedGeofenceToEdit.area.startsWith('POLYGON') || global.selectedGeofenceToEdit.area.startsWith('LINE')),
      askFill: (global.selectedGeofenceToEdit.area.startsWith('POLYGON')),
      askRadius: (global.selectedGeofenceToEdit.area.startsWith('CIRCLE') || global.selectedGeofenceToEdit.area.startsWith('LINE') )});
    handleGeofenceMenuClose();

    getItemGroups('geofences', geofenceId, geofenceGroups, setGeofenceGroups);
  
  };

  const handleEditGeofenceCancel = () => {
    setEditGeofenceDialogShown({show: false, askIcon: false, askColor: false, askFill: false, askRadius: false});
  };

  const handleEditGeofenceConfirm = () => {

    var area = global.selectedGeofenceToEdit.area;
    var attributes = {};

    if (editGeofenceDialog.askIcon) {
      attributes.icon = geofenceIcon;
    }

    if (editGeofenceDialog.askColor) {
      attributes.color = geofenceColor;
    }

    if (editGeofenceDialog.askFill) {
      attributes.fill = geofenceFill;
    }

    if (global.selectedGeofenceToEdit.area.startsWith('LINE')) {
      attributes.polylineDistance = geofenceRadius;
    }

    attributes.speedLimit = geofenceSpeedLimit/1.852 || 0;

    if (global.selectedGeofenceToEdit.area.startsWith('CIRCLE')) {
      area = area.split(',')[0] + ', ' + geofenceRadius + ')'
    }

    apiEditGeofence(global.selectedGeofenceToEdit.id, geofenceName, geofenceDescription, area, attributes);
    
    setEditGeofenceDialogShown({show: false, askIcon: false, askRadius: false});
  }

  const apiEditGeofence = async (geofenceId, name, description, area, attributes) => {
    const response = await fetch(`/api/geofences/${geofenceId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({id: geofenceId, name, description, area, attributes})
    });
    global.map_reloadGeofences = true

    if (response.ok) {

      const allGroups = Object.values(groups).map((group) => group.id)
      const groupsToAdd = geofenceGroups
      const groupsToDelete = allGroups.filter((id) => !groupsToAdd.includes(id))

      if (groupsToAdd.length>0) {
        await fetch('/api/permissions/bulk', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(groupsToAdd.map((groupId) => {return {groupId, geofenceId: geofenceId}})),
        });
      }

      if (groupsToDelete.length>0) {
        await fetch('/api/permissions/bulk', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(groupsToDelete.map((groupId) => {return {groupId, geofenceId: geofenceId}})),
        });
      }

      //handleResultStatus(t('zoneEdit'), t('zoneEditSuccess'), 'ok')
      loadGeofenceList(true);
    }
    else
    {
      var error_details = response.status + ' ' + response.statusText
      handleResultStatus(t('zoneEdit'), `${t('zoneEditError')} (${error_details})`, 'error')
    }

  }


  ///////////////////////////////////////////////////////////////////////////////////////////////
  //Caixa de informação
  const [statusDialog, setStatusDialog] = React.useState({title: '', message: '', icon: ''});
  const [resultStatus, setResultStatus] = React.useState(false);

  const handleResultStatus = (title, message, icon) => {
    setStatusDialog({title: title, message: message, icon: icon});
    setResultStatus(true);
  }

  const handleResultStatusCancel = () => {
    setResultStatus(false);
  };

  const getDeviceHeight = (index) => {
    /*
    var itemHeight = 21+20+20+22
    if (devices[index].group) itemHeight += 20
    if (devices[index].driver.name) itemHeight += 20
    return itemHeight
    */
    return 130
  }

  const getGeofenceHeight = (index) => {
    /*
    var itemHeight = 21+20+20+22
    if (devices[index].group) itemHeight += 20
    if (devices[index].driver.name) itemHeight += 20
    return itemHeight
    */
    return 40
  }

  const toggleLevelDisplay = (deviceid) => {
    let temp_state = [...deviceSettings];
    let temp_element = { ...temp_state[deviceid] };
    temp_element.levelDisplay = temp_element.levelDisplay>=temp_element.maxLevelDisplay ? 0 : temp_element.levelDisplay + 1
    temp_state[deviceid] = temp_element;
    setDeviceSettings(temp_state);
  }

  const toggleOdometerHours = (deviceid) => {
    let temp_state = [...deviceSettings];
    let temp_element = { ...temp_state[deviceid] };
    temp_element.showHours = !temp_element.showHours;
    temp_state[deviceid] = temp_element;
    setDeviceSettings(temp_state);
  }

  const [listHeaderHeight, setListHeaderHeight] = useState(0)
  const [listHeaderHeight2, setListHeaderHeight2] = useState(0)
  const ref = useRef(null)
  const ref_search = useRef(null)
  const ref_group = useRef(null)
  const ref_tabs = useRef(null)
  const ref_tabs2 = useRef(null)

  useEffect(() => {
    setListHeaderHeight(ref.current.clientHeight)
    setListHeaderHeight2(ref_search.current.clientHeight + ref_tabs.current.clientHeight + ref_tabs2.current.clientHeight)
  })

  const selectGeofence = (geofence) => {
    dispatch(geofencesActions.select(geofence))
    extraData(); //hidePortraitList
  }

  return (
<>
  <div id="mainDeviceList" ref={ref}>

    <Dialog open={editGeofenceDialog.show} onClose={handleEditGeofenceCancel}>
      <DialogTitle>
        {t('zoneEdit')}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="normal"
          variant="outlined"
          label={t('name')}
          type="text"
          value={geofenceName}
          onChange={event => setGeofenceName(event.target.value)}
          fullWidth
          style={{minWidth: '250px'}}
        />
        <TextField
          margin="normal"
          variant="outlined"
          label={t('notes')}
          type="text"
          value={geofenceDescription}
          onChange={event => setGeofenceDescription(event.target.value)}
          fullWidth
          style={{minWidth: '250px'}}
        />
        {
          editGeofenceDialog.askIcon &&
          <TextField
            select
            margin="normal"
            variant="outlined"
            emptyValue={null}
            value={geofenceIcon}
            onChange={event => setGeofenceIcon(event.target.value)}
            label={t('icon')}
            fullWidth
          >
            {poiCategories.map(icon => (
              <MenuItem key={icon.name} value={icon.name}>
                <img width="20" src={'images/pois/' + icon.name + '.png'} />
                <ListItemText primary={ t('poi_' + icon.name) } style={{display: 'inline-block', paddingLeft: '10px'}} />
              </MenuItem>
            ))}
          </TextField>
        }
        
        <TextField
          margin="normal"
          variant="outlined"
          label={t('speedLimit') + " (km/h)"}
          value={geofenceSpeedLimit}
          type="text"
          onChange={event => setGeofenceSpeedLimit(event.target.value)}
          fullWidth
          style={{width: '45%'}}
        />

        { editGeofenceDialog.askRadius &&
          <TextField
            margin="normal"
            variant="outlined"
            label={t('distance') + " (m)"}
            value={geofenceRadius}
            type="text"
            onChange={event => setGeofenceRadius(event.target.value)}
            fullWidth
            style={{width: '45%', marginLeft: '10%'}}
          />      
        }

        { editGeofenceDialog.askColor &&
          <div>
            <span style={{
              position: 'relative',
              width: 'fit-content',
              top: '10px',
              left: '10px',
              backgroundColor: 'white',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: 'rgba(0, 0, 0, 0.54)',
              fontSize: '0.8rem',
              fontFamily: 'Proxima Nova Regular,Roboto,Arial',
            }}>
              {t('style')}
            </span>

            <div style={{
              border: 'solid 1px rgba(0,0,0,0.2)',
              borderRadius: '5px',
              marginLeft: '0px',
              marginTop: '0px',
              width: '100%',
            }}>

              <div style={{display: 'flex', cursor: 'pointer', margin: '20px 20px 10px 20px'}}>
                {
                  geofenceColors.map(color => (
                    <div
                      onClick={event => setGeofenceColor(color.code)}
                      style={{backgroundColor: '#'+color.code, width: '40px', height: '40px', borderRadius: '40px', marginRight: '10px'}}
                    >
                      {color.code == geofenceColor ? <CheckIcon style={{fontSize: 28, color: color.code == '000000' ? '#ffffff' : '#000000', width: 'inherit', height: 'inherit'}}/> : ""}
                    </div>
                  ))  
                }
              </div>
              { editGeofenceDialog.askFill &&
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={geofenceFill}
                      onClick={(event) => setGeofenceFill(!geofenceFill)}
                    />
                  }
                  label={t('fillGeofence')}
                  style={{marginLeft: '10px', marginBottom: '10px'}}
                />
              }
            </div>
          </div>
        }

        <FormControl variant="outlined" fullWidth style={{ marginTop: '15px' }}>
          <InputLabel>{t('group')}</InputLabel>
          <Select
            multiple
            fullWidth
            label={t('group')}
            variant="outlined"
            value={geofenceGroups}
            onChange={(event) => setGeofenceGroups(event.target.value)}
            input={<Input/>}
            renderValue={(selected) => (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {Object.values(groups)
                  .sort(function(a, b) { return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1 })
                  .filter((group) => geofenceGroups.includes(group.id))
                  .map((item) => (
                    <Chip
                      key={item.id}
                      label={item.name}
                      onMouseDown={e => { e.stopPropagation() }}
                      onDelete={() => setGeofenceGroups(geofenceGroups.filter((geofence) => geofence !== item.id))}
                      />
                ))}
              </div>
            )}
          >
            {Object.values(groups)
              .sort(function(a, b) { return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1 })
              .map((group) => (
              <MenuItem key={group.id} value={group.id} style={{ backgroundColor: 'transparent' }}>
                <Checkbox
                  color="primary"
                  checked={geofenceGroups.indexOf(group.id) > -1}
                  />
                <ListItemText primary={group.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEditGeofenceCancel} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
          {t('cancel')}
        </Button>
        <Button onClick={handleEditGeofenceConfirm} color="primary" variant="contained" style={{paddingLeft: '40px', paddingRight: '40px' }}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={removeGeofenceDialogShown}>
      <DialogTitle>
        {t('zoneRemove')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('zoneRemove')} '{global.selectedGeofenceNameToRemove}'?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRemoveGeofenceCancel} color="primary" variant="outlined">{t('cancel')}</Button>
        <Button onClick={handleRemoveGeofenceConfirm} color="primary" variant="contained">{t('remove')}</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={resultStatus} onClose={handleResultStatusCancel}>
      <DialogTitle>
        {statusDialog.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1} component={'span'}>
            <Grid item component={'span'}>
              <Box display={statusDialog.icon == 'ok' ? 'block' : 'none'} component={'span'}>
                <CheckCircleIcon  fontSize="medium" style={{color: '#00bb00'}}/>
              </Box>
              <Box display={statusDialog.icon == 'error' ? 'block' : 'none'} component={'span'}>
                <ErrorIcon fontSize="medium" style={{color: '#bb0000'}}/>
              </Box>
            </Grid>
            <Grid item component={'span'}>
              <Box component={'span'}>
                {statusDialog.message}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleResultStatusCancel} color="primary" variant="outlined">OK</Button>
      </DialogActions>
    </Dialog>

    <Tabs
      ref={ref_tabs}
      value={tabValue}
      onChange={handleTabChange}
      variant="fullWidth"
      TabIndicatorProps={{style: {background: '#78A9FF'}}}
      textColor="primary"
      style={{minHeight: '0px'}}
      >

        <Tab icon={<DirectionsCarIcon style={{fontSize: '20px', position: 'relative', top: '3px'}}/>} label={
          <Tooltip title={t('vehicles')}>
            <div>&nbsp;{deviceStatistics.all}</div>
          </Tooltip>
          } value="vehicles"/>
          
        <Tab icon={<LocationOnIcon style={{fontSize: '20px', position: 'relative', top: '3px'}}/>} label={
          <Tooltip title={t('zones')}>
            <div>&nbsp;{geofences.length}</div>
          </Tooltip>
          } value="pois"/>
    </Tabs>


    <TextField
      ref={ref_search}
      style={{padding: '5px 5px 0px 5px', width: '100%', textAlign: 'center'}}
      variant="outlined"
      onChange={handleChangeSearchField}
      value={searchField}
      placeholder={t('search')}
      InputProps={{
        style: {
          backgroundColor: searchField == "" ? "" : global.searchFieldColor,
          height: '35px',
          padding: '0px 0px 0px 10px',
          marginTop: '5px',
          borderRadius: '50px'
        },
        startAdornment: (
          <div>
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
            <InputAdornment style={{right: '0px', position: 'absolute'}} position='end'>
              <IconButton
                onClick={clearSearch}
                onMouseDown={clearSearch}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          </div>
        )
      }}
    />

    { tabValue == 'vehicles' && ( 
      <div>

      <div
        ref={ref_group}
        style={{
        marginTop: '10px',
        marginRight: '5px',
        marginLeft: '5px'    
      }}>
        <Select
          value={groupField}
          onChange={handleChangeGroupField}
          variant="outlined"
          style={{
            backgroundColor: groupField == 0 ? "" : global.searchFieldColor,
            width: '280px',
            height: '35px',
            borderRadius: '50px'
          }}>

          <MenuItem value={0}>{t('allGroups')}</MenuItem>
          {Object.values(groups)
          .sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)
          .filter(group => groupStatistics[group.id]>0)
          .map(group => (
            <MenuItem key={group.id} value={group.id}>{group.name}{groupStatistics[group.id]>0 ? ` (${groupStatistics[group.id]})` : ''}</MenuItem>
          ))}
        </Select>  

        <Tooltip title={t('vehicleOrder')}>
          <IconButton
            id="simple-menu"
            onClick={event => {setShowSortMenu(event.currentTarget)}}
            style={{color: '#78A9FF', marginLeft: '5px', position: 'absolute', top: '-5px'}}
            >
            <FontAwesomeIcon icon={
              sortOption == 0 ? faSortAmountDown :
              sortOption == 1 ? faSortAlphaDown :
              sortOption == 2 ? faSortNumericDown : null
              } />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={showSortMenu}
          keepMounted
          open={Boolean(showSortMenu)}
          onClose={event => {setShowSortMenu(null)}}
        >
          <MenuItem onClick={event => {setShowSortMenu(null); setSortOption(0); setCookie('sort', 0, { path: '/' })}}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faSortAmountDown} style={{color: '#78A9FF', fontSize: '1.2rem', marginRight: '10px'}}/>
            </ListItemIcon>
            <ListItemText primary={t('status')}/>
          </MenuItem>
          <MenuItem onClick={event => {setShowSortMenu(null); setSortOption(1); setCookie('sort', 1, { path: '/' })}}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faSortAlphaDown} style={{color: '#78A9FF', fontSize: '1.2rem', marginRight: '10px'}}/>
            </ListItemIcon>
            <ListItemText primary={t('name')}/>
          </MenuItem>
          <MenuItem onClick={event => {setShowSortMenu(null); setSortOption(2); setCookie('sort', 2, { path: '/' })}}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faSortNumericDown} style={{color: '#78A9FF', fontSize: '1.2rem', marginRight: '10px'}}/>
            </ListItemIcon>
            <ListItemText primary={t('lastPosition')}/>
          </MenuItem>
        </Menu>

      </div>

      <Tabs
        ref={ref_tabs2}
        value={vehicleTabFilter}
        onChange={handleVehicleTabFilterChange}
        variant="fullWidth"
        TabIndicatorProps={{style: {background: '#78A9FF'}}}
        textColor="primary"
        style={{minHeight: '0px', margin: '5px'}}
        className={classes.tabs}
        >
        <Tab icon={<FontAwesomeIcon icon={faGlobeAfrica} style={{color: '#78A9FF', fontSize: '18px', position: 'relative', top: '3px'}}/>} className={classes.tab}
        label={
          <Tooltip title={t('filterAll')}>
            <div>&nbsp;{deviceStatistics.all}</div>
          </Tooltip>
          }
        value="all"/>
        
        <Tab icon={<SteeringWheelIcon viewBox="0 0 496 512" style={{color: '#009900', fontSize: '18px', position: 'relative', top: '3px'}}/>} className={classes.tab}
        label={
          <Tooltip title={t('filterDriving')}>
            <div>&nbsp;{deviceStatistics.driving}</div>
          </Tooltip>
          }
        value="driving"/>

        <Tab icon={<FontAwesomeIcon icon={faHandPaper} style={{color: '#e88a25', fontSize: '18px', position: 'relative', top: '3px'}}/>} className={classes.tab}
        label={
          <Tooltip title={t('filterIdle')}>
            <div>&nbsp;{deviceStatistics.idle}</div>
          </Tooltip>
          }
        value="idle"/>

        <Tab icon={<StopIcon viewBox="0 0 1000 1000" style={{color: '#990000', fontSize: '22px', position: 'relative', top: '3px'}}/>} className={classes.tab}
        label={
          <Tooltip title={t('filterParked')}>
            <div>&nbsp;{deviceStatistics.stop}</div>
          </Tooltip>}
        value="stop"/>

        <Tab icon={<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF8C00', fontSize: '16px', position: 'relative', top: '3px'}}/>} className={classes.tab}
        label={
          <Tooltip title={t('filterWarning')}>
            <div>&nbsp;{deviceStatistics.warning}</div>
          </Tooltip>}
        value="warning"/>

        <Tab icon={<BuildIcon style={{color: '#808080', fontSize: '16px', position: 'relative', top: '3px'}}/>} className={classes.tab}
        label={
          <Tooltip title={t('filterMaintenance')}>
            <div>&nbsp;{deviceStatistics.maintenance}</div>
          </Tooltip>}
        value="maintenance"/>

      </Tabs>

      <AutoSizer className={classes.list}>
        {({ height, width }) => (
          <List disablePadding>
            <VariableSizeList
              width={width}
              height={ global.listHeight - listHeaderHeight }
              itemCount={devices.length}
              itemData={{ devices, onMenuClick, hidePortraitList: extraData, toggleLevelDisplay, toggleOdometerHours, deviceSettings, showMenu: !temporaryAccess }}
              itemSize={getDeviceHeight}
              overscanCount={10}
              style={{overflowX: 'hidden'}}
            >
              {DeviceRow}
            </VariableSizeList>
          </List>
        )}
      </AutoSizer>
      </div>
    )}


    { tabValue == 'pois' && (
      <div>


        <Tabs
          ref={ref_tabs2}
          value={geofenceTabFilter}
          onChange={handleGeofenceTabFilterChange}
          variant="fullWidth"
          TabIndicatorProps={{style: {background: '#78A9FF'}}}
          textColor="primary"
          style={{minHeight: '0px', margin: '5px'}}
          className={classes.tabs}
          >

          <Tab icon={<FontAwesomeIcon icon={faMapMarkerAlt} style={{color: '#e88a25', fontSize: '18px', position: 'relative', top: '3px'}}/>} className={classes.tab}
          label={
            <Tooltip title={t('Pontos')}>
              <div>&nbsp;{ geofences.filter(geofence => geofence.area.toLowerCase().startsWith('circle')).length }</div>
            </Tooltip>
            }
          value="circle"/>
          
          <Tab icon={<FontAwesomeIcon icon={faDrawPolygon} style={{color: '#e88a25', fontSize: '18px', position: 'relative', top: '3px'}}/>} className={classes.tab}
          label={
            <Tooltip title={t('Áreas')}>
              <div>&nbsp;{ geofences.filter(geofence => geofence.area.toLowerCase().startsWith('polygon')).length }</div>
            </Tooltip>
            }
          value="polygon"/>

          <Tab icon={<FontAwesomeIcon icon={faRoute} style={{color: '#e88a25', fontSize: '18px', position: 'relative', top: '3px'}}/>} className={classes.tab}
          label={
            <Tooltip title={t('Corredores')}>
              <div>&nbsp;{ geofences.filter(geofence => geofence.area.toLowerCase().startsWith('linestring')).length }</div>
            </Tooltip>
            }
          value="linestring"/>

        </Tabs>

        <AutoSizer className={classes.list}>
          {({ height, width }) => (
            <List disablePadding>
              <VariableSizeList
                width={width}
                height={ global.listHeight - listHeaderHeight2 - 10 }
                itemCount={ geofences.filter(geofence => geofence.area.toLowerCase().startsWith(geofenceTabFilter)).length }
                itemData={{ geofences: geofences.filter(geofence => geofence.area.toLowerCase().startsWith(geofenceTabFilter)), onMenuClick, hidePortraitList: extraData, showMenu: !temporaryAccess, anchorGeofenceMenuEl, handleGeofenceMenuOpen, handleGeofenceMenuClose, handleEditGeofence, handleRemoveGeofence }}
                itemSize={getGeofenceHeight}
                overscanCount={10}
                style={{overflowX: 'hidden'}}
              >
                {GeofenceRow}
              </VariableSizeList>
            </List>
          )}
        </AutoSizer>
      </div>
    )}
  </div>

  { extraData2 &&
  <Box style={{position: 'absolute', bottom: '30px', right: '10px'}}>
    <Fab
      className='mapIconButton'
      onClick={extraData}
      style={{
        color: '#ffffff',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
      }}
      >
      <FontAwesomeIcon icon={faReply} style={{fontSize: 30}}/>
    </Fab>
  </Box>
  }

</>
  );
}

const DevicesList = ({hidePortraitList, showExitButton, setDeviceFilterGroup, setDeviceFilterSearch, setDeviceFilterTab, setCameraDialogOpen}) => {
  //console.log('DevicesList')
  return (
    <EditCollectionView
      content={DeviceView}
      editPath="/settings/device"
      endpoint="devices"
      allowAddRemove = {false}
      style={{overflowX: 'hidden', overflowY: 'hidden'}}
      extraData={hidePortraitList}
      extraData2={showExitButton}
      setDeviceFilterGroup={setDeviceFilterGroup}
      setDeviceFilterSearch={setDeviceFilterSearch}
      setDeviceFilterTab={setDeviceFilterTab}
      setCameraDialogOpen={setCameraDialogOpen}
    />
  );
}

export default DevicesList;
