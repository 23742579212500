import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isWidthUp, makeStyles, withWidth } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ContainerDimensions from 'react-container-dimensions';
import DevicesList from './DevicesList';
import MainToolbar from './MainToolbar';
import Map, { draw } from './map/Map';
import SelectedDeviceMap from './map/SelectedDeviceMap';
import SelectedGeofenceMap from './map/SelectedGeofenceMap';
import CameraMap from './map/CameraMap';
import GeofenceMap from './map/GeofenceMap';
import TrafficSignsMap from './map/TrafficSignsMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';

import ManageDirections from './map/ManageDirections';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 1',
    overflow: 'hidden',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    }
  },
  drawerPaper: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
    [theme.breakpoints.down('xs')]: {
      height: 250,
    }
  },
  mapContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0px'
  },
}));

let firstTimeLoading = true;

const MainPage = ({ width }) => {
  //console.log('MainPage')
  const classes = useStyles();

  const [cameraDialogOpen, setCameraDialogOpen] = useState(false); //###########################################################
  const [listVisible, setListVisible] = useState(false);
  const [deviceFilterGroup, setDeviceFilterGroup] = React.useState(global.mainView.groupField);
  const [deviceFilterSearch, setDeviceFilterSearch] = React.useState(global.mainView.searchField);
  const [deviceFilterTab, setDeviceFilterTab] = React.useState("");
  const temporaryAccess = useSelector(state => state.session.user && state.session.user.email.startsWith('temp_') );

  global.map_registerGeofenceEvent = true;
  global.isLandscape = isWidthUp('sm', width);

  useEffect(() => {
    setListVisible(isWidthUp('sm', width) && !temporaryAccess)
  }, []);

  const toggleList = () => {
    setListVisible(!listVisible)
  };

  const showList = () => {
    setListVisible(true)
  };

  const hidePortraitList = () => {
    if (!isWidthUp('sm', width)) setListVisible(false)
  };

  const ref = useRef(null)
  useEffect(() => {
    global.listWidth = ref.current.clientWidth
    global.listHeight = ref.current.clientHeight
  })
  
  useEffect(() => {
    if (isWidthUp('sm', width)) {
      ref.current.style.height = 'auto';
    } else {
      ref.current.style.height = '100%';
    }
  })

  return (
    <div className={classes.root}>
      <MainToolbar mapView={true}/>
      <div className={classes.content}>
        <div
          style={{
            margin: listVisible ? '5px 15px 15px 15px' : '0px',
            borderRadius: '10px',
            display: 'flex',
            height: 'auto'
            }}
          ref={ref}>

        { listVisible &&
          <Drawer
            PaperProps={{
              style: {
                backgroundColor: 'rgba(255, 255, 255, 0.90)',
                borderRadius: '10px',
                width: isWidthUp('sm', width) ? 350 : '100%',
                height: '100%',
                display: 'block',
                overflow: 'hidden',
                borderStyle: 'solid',
                borderColor: '#cccccc',
                borderWidth: '1px',

                transitionProperty: 'display',
                transitionDuration: '10s',
                transitiontimingFunction: 'linear',
                transitionDelay: '0s',

              }}}
            onClose={ManageDirections({'drawer':'true'})}
            anchor={isWidthUp('sm', width) ? 'left' : 'bottom'}
            variant='permanent'
            style={{width: isWidthUp('sm', width) ? 'auto' : '100%'}}
            classes={{ paper: classes.drawerPaper }}>
            <DevicesList hidePortraitList={hidePortraitList} showExitButton={listVisible && !isWidthUp('sm', width)}
              setDeviceFilterGroup={setDeviceFilterGroup}
              setDeviceFilterSearch={setDeviceFilterSearch}
              setDeviceFilterTab={setDeviceFilterTab}
              setCameraDialogOpen={setCameraDialogOpen}
              />
          </Drawer>  
        }

          <div
            className="sliderButton"
            onClick={toggleList}
            style={{position: 'relative', top: '45%', zIndex: 1, display: isWidthUp('sm', width) ? 'block' : 'none' }}>
              <FontAwesomeIcon
                icon={listVisible ? faChevronLeft : faChevronRight}
                style={{position: 'absolute', left: '5px', top: '35%', cursor: 'pointer'}}
              />
          </div>
        </div>

        <div className={classes.mapContainer}>
          <ContainerDimensions>
            <Map>
              <GeofenceMap deviceFilterSearch={deviceFilterSearch} />
              <TrafficSignsMap />
              <CurrentPositionsMap listVisible={listVisible} 
                deviceFilterGroup={deviceFilterGroup}
                deviceFilterSearch={deviceFilterSearch}
                deviceFilterTab={deviceFilterTab}
                setCameraDialogOpen={setCameraDialogOpen}
                />
              <SelectedDeviceMap listVisible={listVisible} />
              <SelectedGeofenceMap listVisible={listVisible} />
              <CameraMap cameraDialogOpen={cameraDialogOpen} setCameraDialogOpen={setCameraDialogOpen} />
            </Map>
          </ContainerDimensions>
        </div>

        { !listVisible && !isWidthUp('sm', width) &&
          <Box style={{position: 'absolute', top: '50px', left: '10px'}}>
            <Fab
              className='mapIconButton'
              style={{color: '#ffffff'}}
              onClick={showList}>
              <DirectionsCarIcon style={{ fontSize: 30 }} />
            </Fab>
          </Box>
        }

      </div>
    </div>
  );
}

export default withWidth()(MainPage);
