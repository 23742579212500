import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import userAttributes from '../attributes/userAttributes';
import EditItemView from '../EditItemView';
import { Tooltip, IconButton, InputLabel, FormControl, Select, MenuItem, Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../attributes/EditAttributesView';
import LinkField from '../form/LinkField';
import SelectField from '../form/SelectField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import VolumeUpIcon from '@material-ui/icons/VolumeUp';

import { playAudio } from "../WebNotification";

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const notificationSounds = [
  {"id": "alarm", "name": "Alarme"},
  {"id": "ding", "name": "Ding 1"},
  {"id": "ding2", "name": "Ding 2"},
  {"id": "ding3", "name": "Ding 3"},
  {"id": "horn", "name": "Buzina"},
];

const defaultNotificationSound = "horn";

const InterfacePage = () => {
  global.selectedMenu = "/settings/interface"
  const classes = useStyles();
  const [item, setItem] = useState();

  return (
    <EditItemView endpoint="users" item={item} setItem={setItem} title={ t('settings') }>
      {item &&
        <>
          <Accordion defaultExpanded={true} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('map')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              
              <FormControlLabel
                label={t('showVehicleNames')}
                control={
                  <Switch color="primary"
                    checked={item.hasOwnProperty('attributes') && item.attributes.map_showVehicleNames}
                    onChange={event => setItem({...item, attributes: {...item.attributes, map_showVehicleNames: event.target.checked}})}
                  />}
              />
              
              <FormControlLabel
                label={t('groupVehicles')}
                control={
                  <Switch color="primary"
                    checked={item.hasOwnProperty('attributes') && item.attributes.map_groupVehicles}
                    onChange={event => setItem({...item, attributes: {...item.attributes, map_groupVehicles: event.target.checked}})}
                  />}
              />

              <FormControlLabel
                label={t('showZones')}
                control={
                  <Switch
                    color="primary"
                    checked={item.hasOwnProperty('attributes') && item.attributes.map_showGeofences}
                    onChange={event => setItem({...item, attributes: {...item.attributes, map_showGeofences: event.target.checked}})}
                  />}
              />
              
              <FormControlLabel
                label={t('ShowZoneNames')}
                control={
                  <Switch
                    disabled={item.hasOwnProperty('attributes') && !item.attributes.map_showGeofences}
                    color="primary"
                    checked={item.hasOwnProperty('attributes') && item.attributes.map_showGeofenceNames}
                    onChange={event => setItem({...item, attributes: {...item.attributes, map_showGeofenceNames: event.target.checked}})}
                  />}
              />

              <FormControlLabel
                label={t('showTrafficSigns')}
                control={
                  <Switch
                    color="primary"
                    checked={item.hasOwnProperty('attributes') && item.attributes.map_showTrafficSigns}
                    onChange={event => setItem({...item, attributes: {...item.attributes, map_showTrafficSigns: event.target.checked}})}
                  />}
              />
              
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={true}>

            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('alerts')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details} style={{ marginBottom: '20px' }}>

              <div style={{ display: 'inline' }}>
                <FormControl className={classes.FormControl} style={{ minWidth: '200px' }}>
                  <InputLabel>{t('webNotificationSound')}</InputLabel>

                  <Select
                    value={item.hasOwnProperty('attributes') && item.attributes.notification_sound || defaultNotificationSound}
                    onChange={
                      event => {
                        playAudio(event.target.value);
                        setItem({...item, attributes: {...item.attributes, notification_sound: event.target.value}})
                      }
                    }
                    >
                    { notificationSounds.sort((a, b) => a.name > b.name ? 1 : -1).map(sound => (
                        <MenuItem key={sound.id} value={sound.id} style={{ paddingLeft: '20px', paddingRight: '20px' }}>{sound.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>

                <Tooltip title={t('listen')}>
                  <IconButton
                    onClick={(event) => playAudio(item.attributes.notification_sound || defaultNotificationSound)}
                    style={{
                      width: 'fit-content',
                    }}>
                    <VolumeUpIcon/>
                  </IconButton> 
                </Tooltip>  
              </div>

            </AccordionDetails>
          </Accordion>

        </>
      }
    </EditItemView>
  );
}

export default InterfacePage;
