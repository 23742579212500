import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportKm = () => {
  global.selectedMenu = "/report/km"

  const report_name = t('reportKm')
  const endpoint = '/report/report_km.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    showVehicles={false}
    showDrivers={false}
    showVehiclesOrDrivers={true}
    >
    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportKm;
