import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {
    server: null,
    user: null,
  },
  reducers: {
    updateServer(state, action) {
      state.server = action.payload;
      global.server = action.payload;
    },
    updateUser(state, action) {
      if (action.payload) {
        global.user_details = action.payload;
        global.userId = action.payload.id;
        global.language = action.payload.attributes.language || 'pt';
      }
      state.user = action.payload;
      global.user = action.payload;
    },
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
