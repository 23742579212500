import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import ErrorIcon from '@material-ui/icons/Error';
import { Grid, Button } from '@material-ui/core';
import t from '../common/localization';

const ReportLayoutMessage = ({ items }) => {

  const [resultStatus, setResultStatus] = useState(false);
  const [statusDialog, setStatusDialog] = useState({title: '', message: ''});

  const showStatusDialog = (title, message) => {
    setStatusDialog({title: title, message: message});
    setResultStatus(true);
  }

  const hideStatusDialog = () => {
    setResultStatus(false);
  };

  useEffect(() => {
	if (typeof(items.result) != 'undefined') {
		if (items.result.length == 0 || items.result[0].data.length == 0) {
			showStatusDialog('Oops!', t('noDataForTheSelectedPeriod'))
			setResultStatus(true);			
		}
	}
  }, [items]);

  return (
	<Dialog open={resultStatus} onClose={hideStatusDialog}>
		<DialogTitle id="form-dialog-title">
			{statusDialog.title}
		</DialogTitle>
		<DialogContent>
			<DialogContentText component="span">
				<Grid container spacing={1}>
					<Grid item>
						<img src="images/emoji_worried_face.png" width="32px"/>
					</Grid>
				<Grid item style={{ marginTop: '4px' }}>
					<Box>
					{statusDialog.message}
					</Box>
				</Grid>
				</Grid>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={hideStatusDialog} color="primary" variant="outlined">OK</Button>
		</DialogActions>
	</Dialog>
  );
}

export default ReportLayoutMessage;
