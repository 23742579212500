import React, { useState, useEffect, FunctionComponent } from 'react';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper, makeStyles, Link } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { formatBigNumber, formatBigCurrency } from '../common/formatter';

import { getDeviceName } from '../map/mapUtil';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from "recharts";

const useStyles = makeStyles(theme => ({
  row: {
    padding: '5px'
  },
}));

const ReportLayoutData = ({
  items,
  ReportManagementAnalysis = false,
  ReportChartKm = false,
  rowStyle = (data) => { return {} },
}) => {

  const classes = useStyles();

  const [format, setFormat] = useState(false);
  const [border, setBorder] = useState(false);

  useEffect(() => {
    if (items
      && items.hasOwnProperty('result')
      && items.result.length>0
      && items.result[0].hasOwnProperty('data_format')) {
      setFormat(items.result[0].data_format);
      setBorder(items.result[0].data_borders);
    }
    if (document.getElementById("reportResults")) document.getElementById("reportResults").style.filter = 'none'
  }, [items]);

  const cellAlignment = (index) => {
    if (format) {
      switch (format[index]) {
        case 'currency':
          return 'right'
          break;
      }
    }
    return 'center';
  };

  const cellFormat = (index, value) => {
    if (format) {
      switch (format[index]) {
        case 'currency':
          return formatBigCurrency(value)
          break;
      }
    }
    return value;
  };

  const cellStyle = (index, baseStyle = {}) => {

    if (format) {
      switch (format[index]) {
        case 'currency':
          baseStyle.whiteSpace = 'nowrap';
          break;
        case 'red':
          baseStyle.color = 'red';
          break;
        case 'red_gray':
          baseStyle.color = 'red';
          baseStyle.backgroundColor = '#eeeeee';
          break;
        case 'column':
          baseStyle.borderLeft = '1px solid rgba(224, 224, 224, 1)';
          baseStyle.borderRight = '1px solid rgba(224, 224, 224, 1)';  
          break;
      }
    }

    const borderColor = "#999999"

    if (border) {
      if (border[index].includes('T')) {
        cellSbaseStyletyle.borderTopStyle = 'solid';
        baseStyle.borderTopWidth = '1px',
        baseStyle.borderTopColor = borderColor
      }
      if (border[index].includes('R')) {
        baseStyle.borderRightStyle = 'solid';
        baseStyle.borderRightWidth = '1px',
        baseStyle.borderRightColor = borderColor
      }
      if (border[index].includes('B')) {
        baseStyle.borderBottomStyle = 'solid';
        baseStyle.borderBottomWidth = '1px',
        baseStyle.borderBottomColor = borderColor
      }
      if (border[index].includes('L')) {
        baseStyle.borderLeftStyle = 'solid';
        baseStyle.borderLeftWidth = '1px',
        baseStyle.borderLeftColor = borderColor
      }
    }

    return baseStyle;
  };

  
  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={5}
          y={0}
          dy={8}
          textAnchor="start"
          fill="#666"
          transform="rotate(45)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (

    <>


    { ReportChartKm && items.hasOwnProperty('data') &&

          items.data.map((item) => (
            <TableContainer component={Paper} style={{overflowX: 'unset', marginBottom: '20px'}}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#78A9FF30'}}>

                    <TableCell
                      align="left"
                      className={classes.row}
                    >
                      {getDeviceName({name: item.name, attributes: {license_plate: item.license_plate}})}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={classes.row}
                    >
                      Total: {item.total_km} km
                    </TableCell>

                    <TableCell
                      align="right"
                      className={classes.row}
                    >
                      Média: {item.average_km} km/dia
                    </TableCell>

                  </TableRow>
                </TableHead>

              <TableBody>
                <BarChart
                  width={window.innerWidth*(0.25 + (item.data.length*0.03 > 0.7 ? 0.7 : item.data.length*0.03))}
                  height={300}
                  data={item.data}
                  style={{marginTop: '20px', width: 0}}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    tick={<CustomizedAxisTick />}
                    interval={0}
                    height={90}
                    padding={{ left: 0, right: 30 }}
                    />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="km" fill="#78A9FFAA" label={{ fill: '#888888', position: 'top', fontSize: 10 }}/>
                </BarChart>
              </TableBody>
                
              </Table>
            </TableContainer>
          ))

    }


    <div id="reportResults">
    {items.hasOwnProperty('result') &&
    items.result.map((item, index) => (

    <div id="reportResult" style={{marginBottom: item.extra && item.extra.noMarginBottom ? '0px' : '30px'}}>

      { item.title != "" &&
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h2 style={{marginBottom: '2px', textAlign: 'left'}}>
          {item.title}
        </h2> 
        <h2 style={{marginBottom: '2px', textAlign: 'right'}}>
          {item.subtitle}
        </h2>         
      </div>
      }

      { item.extra && item.extra.subtitle3 &&
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h3 style={{marginBottom: '2px', textAlign: 'left'}}>
            {item.extra.subtitle3}
          </h3>        
        </div>
      }

      { item.subtitle2 != '' &&
        <div style={{fontWeight: '500'}}>
          <FontAwesomeIcon icon={item.closestPoi == null ? faHome : faMapMarker} style={{color: '#78A9FF'}} />
          &nbsp;
          Início: {item.subtitle2}      
        </div>
      }

      <TableContainer component={Paper}>

        <Table>

          <TableHead>
            {
              item.hasOwnProperty('pre_header') &&
              item.pre_header.map((pre_header, index) => (
                  <TableRow key={'preHeaderRow_' + index} style={{ backgroundColor: '#78A9FF'}}>
                    {pre_header.map((row, index2) => (
                      <TableCell
                        colSpan={row[0]}
                        align="center"
                        className={classes.row}
                      >
                        {row[1]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            }
            {
              item.hasOwnProperty('header') &&
              item.header.map((header, index) => (
                  <TableRow key={'headerRow_' + index} style={{ backgroundColor: '#78A9FF30'}}>
                    {header.map((row, index2) => (
                      <TableCell
                        key={index + "-" + index2}
                        align={cellAlignment(index2)}
                        className={classes.row}
                      >
                        {row}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            }
          </TableHead>

          <TableBody>
            {
              item.hasOwnProperty('data') &&
              item.data.map((data, index) => (
                  <TableRow key={'dataRow_' + index} hover style={rowStyle(data)}>
                    {data.map((row, index2) => (
                      <TableCell
                        align={cellAlignment(index2)}
                        className={classes.row}
                        style={cellStyle(index2)}
                      >
                        {
                            typeof(row) == 'object' && row !== null
                          ?
                            (<Link underline='hover' target='_blank' href={row[1]}>{row[0]}</Link>)
                          :
                            cellFormat(index2, row)
                        }
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            }

            {
              item.hasOwnProperty('footer') &&
              item.footer.map((footer, index) => (
                  <TableRow key={'footerRow_' + index} style={{ backgroundColor: "#00000010" }}>
                    {footer.map((row, index2) => (
                      <TableCell
                        align={cellAlignment(index2)}
                        className={classes.row}
                        style={{ color: "#000000" }}
                      >
                        {cellFormat(index2, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            }
          </TableBody>

        </Table>
      </TableContainer>

      { item.extra && item.extra.separator &&
        <hr></hr>
      }      

      {ReportManagementAnalysis && items.result[0].data.length > 0 &&
        <div style={{marginTop: '30px'}}>
          <div style={{width: 'max-content'}}>
            <div style={{width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '1.25em'}}>
              {items.result[0].data_pie1.title}
            </div>
            <div style={{float: 'left'}}>
              <PieChart width={220} height={220}>
                <Pie
                  data={items.result[0].data_pie1[0]}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  innerRadius={0}
                  outerRadius={100}
                  startAngle={90}
                  endAngle={-270}
                  dataKey="value"
                >
                </Pie>
              </PieChart>
            </div>
            <div style={{marginTop: '40px', marginLeft: '30px', float: 'left'}}>
              {
                items.result[0].data_pie1[0].map((data, index) => (
                  <span style={{display: 'block', marginBottom: '5px'}}>
                    <span style={{width: '20px', height: '20px', float: 'left', backgroundColor: data.fill}}>
                    </span>
                    <span style={{marginLeft: '10px'}}>
                      {data.name} ({((data.value / items.result[0].data_pie1.total)*100).toFixed(1)}%)
                    </span>                  
                  </span>
                ))
              }
            </div>
          </div>
        </div>
      }
    </div>

    ))}

    </div>

    </>

  );
}

export default ReportLayoutData;
