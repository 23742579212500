import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, FormControl, Checkbox, Grid, Switch, Button, Tabs, Tab, Paper, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../attributes/EditAttributesView';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FormLabel from '@material-ui/core/FormLabel';
import SelectField from '../form/SelectField';
import { addEditString } from '../common/stringUtils';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Webcam from "react-webcam";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import SyncIcon from '@material-ui/icons/Sync';
import CameraIcon from '@material-ui/icons/Camera';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import ReplayIcon from '@material-ui/icons/Replay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const DocPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null)

  moment.locale((global && global.language) ? global.language.replace('pt', 'pt-br').replace('en', 'en-gb').replace('ar', 'en-gb') : 'en-gb');

  const screenshotResolution = [2000, 1500];

  const [item, setItem] = useState({});

  const [selectedFile, setSelectedFile] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [webcamPhoto, setWebcamPhoto] = useState(false);
  const [webcamPhotoResolution, setWebcamPhotoResolution] = useState("");
  const [fileSource, setFileSource] = useState("file");
  const [cameraSide, setCameraSide] = useState("environment");

  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const handleOrientationChange = () => { setIsPortrait(window.innerHeight > window.innerWidth); };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => {  window.removeEventListener('resize', handleOrientationChange); };
  }, []);

  var devices = useSelector(state => Object.values(state.devices.items));

  var deviceId = global.extraData;

  const maxFileSize = 5;

  if (!deviceId) history.push(`/management/summary`)
  
  const get_device_name = (id) => {
    var result = ''
    devices.forEach(function(device, index) {
      if (device.id == id) {
        result = device.attributes.license_plate
      }
    });
    return result;
  }
  
  const handleToggleDocExpiration = () => {
    setItem({...item, warning: item.warning == 0 ? 1 : 0})
  }

  const binaryToHex = (binaryArray) => {
    return binaryArray.map(byte => {
      return byte.toString(16).padStart(2, '0'); // Convert to hex and ensure two-digit representation
    }).join(''); // Join the bytes into a single hex string
  }

  function asciiToHex(asciiString) {
    let hexString = '';
    for (let i = 0; i < asciiString.length; i++) {
      const charCode = asciiString.charCodeAt(i); // Get ASCII value
      const hexValue = charCode.toString(16); // Convert to hexadecimal
      hexString += hexValue;
    }
    return hexString;
  }

  const handleNewFile = (event) => {
    var file = {
      name: event.target.files[0].name,
      size: event.target.files[0].size,
      type: event.target.files[0].type
    }

    if (file.size>maxFileSize*1024*1024) {
      alert(`O tamanho máximo permitido é de ${maxFileSize} MB`)
    } else {
      const fileInput = document.getElementById('file-upload-input');
      const selectedFile = fileInput.files[0]; // Get the first selected file
    
      if (selectedFile) {
        const reader = new FileReader();
    
        reader.onload = function(event) {
          const fileContent = event.target.result;
          file.content = fileContent
          setItem({...item, file: file})
        };
    
        reader.readAsDataURL(selectedFile); //'readAsText' or 'readAsArrayBuffer' or 'readAsDataURL'
      }
    }
  }

  useEffect(() => {
    if (!item.hasOwnProperty('expiry_date')) {
      item.expiry_date = moment().format('YYYY-MM-DD HH:mm:ss')
    }
    if (!item.hasOwnProperty('warning')) {
      item.warning = 0
    }

    console.log("item", item)
    
  }, [item]);
  
  const document_types = [
    { "id": "license", "name": "Licença" },
    { "id": "contract", "name": "Contrato" },
    { "id": "other", "name": "Outro" }
  ];

  useEffect(() => {
    if (showWebcam) {
      setWebcamPhoto(false);
      setWebcamPhotoResolution("");
    }
  }, [showWebcam]);
  
  useEffect(() => {
    if (webcamPhoto) {
      setShowWebcam(false);
      setItem({...item, file: {content: webcamPhoto}})


      const img = new Image();
      img.src = webcamPhoto;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setWebcamPhotoResolution(width + "x" + height)
      };
    }
  }, [webcamPhoto]);
  
  useEffect(() => {
    setShowWebcam(fileSource == "camera");
    if (fileSource == "camera") setItem({...item, file: false});
  }, [fileSource]);

  console.log("item", item)

  //style={{ width: ref.current.clientWidth>500 ? '500px' : '100%' }}

  return (
    <EditItemView endpoint={`../report/api.php/document/${deviceId}`} item={item} setItem={setItem} title={ t('documents') + ' > ' + get_device_name(deviceId) + ' > ' + addEditString(t('addDocument'), t('editDocument'))}>
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

              { addEditString("add", "edit") == "add" &&

                <Paper style={{ textAlign: 'center' }} ref={ref}>

                  <Tabs
                    value={fileSource}
                    onChange={(event, newValue) => setFileSource(newValue)}
                    TabIndicatorProps={{style: {background: '#78A9FF'}}}
                    textColor="primary"
                    centered
                    >
                      <Tab icon={<DescriptionIcon />} label={t('file')} value="file"/>
                      <Tab icon={<CameraAltIcon />} label={t('camera')} value="camera"/>
                  </Tabs>
                

                  { fileSource == 'file' && (
                    <div style={{padding: '20px'}}>
                      <input
                        type="file"
                        accept=".pdf,.jpg,.png"
                        onChange={handleNewFile}
                        style={{ display: 'none' }}
                        id="file-upload-input"
                      />
                      <label htmlFor="file-upload-input">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                        >
                          {t('selectFile')}
                        </Button>
                      </label>
                      { item.file &&
                        <div style={{padding: '20px', fontSize: '1.1em'}}>
                          { (item.file.type == 'image/jpeg' || item.file.type == 'image/png') &&
                            <img src={item.file.content} style={{ height: '200px' }} />
                          }
                          <div>
                            {item.file.name}
                          </div>
                        </div>
                      }
                    </div>
                  )}

                  { fileSource == 'camera' && (
                    <div style={{ padding: '20px' }}>
                      { showWebcam &&
                        <Webcam
                          audio={false}
                          screenshotFormat="image/jpeg"
                          forceScreenshotSourceSize={false}
                          screenshotQuality={0.9}
                          videoConstraints={{
                            facingMode: cameraSide, //"user" "environment"
                          }}
                          style={{ width: ref.current.clientWidth>500 ? '500px' : '-webkit-fill-available' }}
                        >
                          {({ getScreenshot }) => (
                            <div style={{ position: 'relative', bottom: '60px' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                onClick={() => { setWebcamPhoto(getScreenshot({width: isPortrait ? screenshotResolution[1] : screenshotResolution[0], height: isPortrait ? screenshotResolution[0] : screenshotResolution[1]})) }}
                              >
                                <CameraIcon />
                              </Button>
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                onClick={() => setCameraSide(cameraSide == "environment" ? "user" : "environment")}
                              >
                                <FlipCameraIosIcon />
                              </Button> 
                            </div>                          
                          )}

                        </Webcam>
                      }

                      { webcamPhoto &&
                        <div>
                          <img src={webcamPhoto} style={{ height: '200px' }} />
                          <div style={{ position: 'relative', bottom: '60px' }}>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              onClick={() => {setShowWebcam(true)}}
                            >
                              <ReplayIcon />
                            </Button>
                          </div>
                          <div style={{ bottom: '40px', position: 'relative' }}>
                            {webcamPhotoResolution}
                          </div>
                        </div>
                      }

                    </div>
                  )}
                </Paper>
              }

              { addEditString("add", "edit") == "edit" && (item.file_type == 'jpg' || item.file_type == 'png') &&
                <Paper style={{ textAlign: 'center' }} ref={ref}>
                  {
                    <div style={{margin: '20px'}}>
                      
                      <Tooltip title="Abrir documento" placement="right-end">
                        <a href={"/report/api.php/file/" + item.id + '/' + item.license_plate} target="_blank">
                          <img src={"/report/api.php/file/" + item.id} style={{ height: '200px' }} />
                        </a>
                      </Tooltip>
                      
                      <a href={"/report/api.php/file/" + item.id + '/' + item.license_plate} download>
                        <div>
                          Download
                        </div>
                      </a>
                    </div>
                  }
                </Paper>
              }

              { addEditString("add", "edit") == "edit" && item.file_type == 'pdf' &&
                <Paper style={{ textAlign: 'center' }} ref={ref}>
                  {
                    <div style={{margin: '20px'}}>
                      <Tooltip title="Abrir documento" placement="right-end">
                        <a href={"/report/api.php/file/" + item.id + '/' + item.license_plate} target="_blank">
                          <FontAwesomeIcon icon={faFilePdf} style={{color: '#78A9FF', fontSize: '6em'}}/>
                        </a>
                      </Tooltip>
                      <a href={"/report/api.php/file/" + item.id + '/' + item.license_plate} download>
                        <div>
                          Download
                        </div>
                      </a>
                    </div>
                  }
                </Paper>
              }

              <TextField
                margin="normal"
                variant="outlined"
                multiline
                value={item.designation || ''}
                onChange={event => setItem({...item, designation: event.target.value})}
                label="Descrição"
                />

              <SelectField
                margin="normal"
                value={item.document_type || ''}
                emptyValue={null}
                onChange={event => setItem({...item, document_type: event.target.value})}
                data={document_types}
                label="Tipo de documento"
                />
                
              <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <Switch
                  color="primary"
                  onClick={handleToggleDocExpiration}
                  checked={item.warning == 1}
                />
                <FormLabel>Documento com data de expiração</FormLabel>                
              </div>
              
              {
                item.warning == 1 &&
                <>
                  <MuiPickersUtilsProvider utils={MomentUtils} >
                    <DatePicker
                      label="Data de expiração"
                      format="DD/MM/yyyy"
                      ampm={false}
                      variant="dialog"
                      value={item.expiry_date}
                      onChange={event => setItem({...item, expiry_date: event.format('YYYY-MM-DD')})}
                    />
                  </MuiPickersUtilsProvider>             
                </>
              }

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default DocPage;
