import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportChartKm = () => {
  global.selectedMenu = "/report/chart_km"

  const report_name = t('reportChartKm')
  const endpoint = '/report/report_chart_km.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    showVehicles={false}
    showDrivers={false}
    showVehiclesOrDrivers={true}
    availableFormats = {["json", "pdf"]}>
    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items} ReportChartKm={true}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportChartKm;
