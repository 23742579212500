import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, MenuItem, ListItemText, FormControlLabel, FormLabel, FormControl  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../attributes/EditAttributesView';
import deviceAttributes from '../attributes/deviceAttributes';
import SelectField from '../form/SelectField';
import { deviceCategories } from '../common/deviceCategories';
import LinkField from '../form/LinkField';
import { prefixString, addEditString } from '../common/stringUtils';
import Box from '@material-ui/core/Box';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const MaintenanceSettingsPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState();

  useEffect( () => {
    //setItem({...item, drivers: selectedDrivers})
    if (item && item.attributes) {
      if (!item.attributes.automaticMaintenance && item.attributes.automaticMaintenancePeriodicity>0) {
        setItem({...item, attributes: {...item.attributes, automaticMaintenance: "automatic"}})
      }
      if (item.attributes.automaticMaintenance == "manual" && item.attributes.automaticMaintenancePeriodicity != "") {
        setItem({...item, attributes: {...item.attributes, automaticMaintenancePeriodicity: ""}})
      }
    }
  }, [item]);

  return (
    <EditItemView endpoint="devices" item={item} setItem={setItem} title={t('maintenance') + ' > ' + t('settings') + ((item && item.attributes) ? ' (' + item.attributes.license_plate : '') + ')'}>
      {item && item.attributes &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

            <FormControl
              style={{marginTop: '30px'}}
            >
              <FormLabel
                style={{marginBottom: '10px'}}
              >
                Tipo de gestão de manutenção:
              </FormLabel>
              <RadioGroup
                value={item.attributes.automaticMaintenance}
                defaultValue="manual"
                onChange={(event) => setItem({...item, attributes: {...item.attributes, automaticMaintenance: event.target.value}})}
                >
                <FormControlLabel
                  value="manual"
                  control={<Radio color="primary" />}
                  label="Avançada - O utilizador pode inserir cada manutenção individualmente, incluindo todos os detalhes de datas e custos"
                  />
                <FormControlLabel
                  value="automatic"
                  control={<Radio color="primary" />}
                  label="Automática - O sistema considera uma periodicidade de manutenção fixa e periódica"
                  />
              </RadioGroup>
            </FormControl>

              { item.attributes.automaticMaintenance == "automatic" && 
                <TextField
                  style={{marginLeft: '30px', width: '50%'}}
                  margin="normal"
                  variant="outlined"
                  value={item.attributes.automaticMaintenancePeriodicity}
                  onChange={event => setItem({...item, attributes: {...item.attributes, automaticMaintenancePeriodicity: event.target.value}})}
                  label="Periodicidade de Km para a manutenção automática"
                  />
              }
            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default MaintenanceSettingsPage;
