export const deviceCategories = []

export const deviceCategories2D = [
	{name: 'car', 		zoom: 1.20}, //Carro
	{name: 'jeep2', 	zoom: 1.30}, //Jipe
	{name: 'pickup', 	zoom: 1.20}, //Pickup
	{name: 'jeep1', 	zoom: 1.30}, //SUV
	{name: 'van', 		zoom: 1.20}, //Carrinha
	{name: 'truck3', 	zoom: 1.20}, //Camião 3
	{name: 'bike', 		zoom: 1.10}, //Mota
	
	{name: 'truck', 	zoom: 1.70}, //Camião
	{name: 'tanker', 	zoom: 1.80}, //Cisterna
	{name: 'truck2', 	zoom: 1.40}, //Camião 2
	{name: 'crane', 	zoom: 1.70}, //Auto-grua
	{name: 'circle', 	zoom: 0.75}, //Circulo Pequeno
	{name: 'circle2', 	zoom: 1.00}, //Circulo Médio
	{name: 'circle3', 	zoom: 1.25}, //Circulo Grande

	{name: 'machine', 	zoom: 1.50}, //Escavadora
	{name: 'machine4', 	zoom: 1.70}, //Escavadora 2
	{name: 'machine3', 	zoom: 1.40}, //Bulldozer
	{name: 'machine2', 	zoom: 1.10}, //Cilindro
	{name: 'lifter', 	zoom: 1.40}, //Empilhadora
	{name: 'boat', 		zoom: 1.80}, //Barco
];

export const deviceCategories3DStatic = [
	//{name: '3d_static_image1', zoom: 1.5}, //Bobcat
	//{name: '3d_static_image2', zoom: 1.6}, //Escavadora 1
	//{name: '3d_static_image3', zoom: 1.6}, //Dumper
	//{name: '3d_static_image4', zoom: 2.1}, //Escavadora Hidráulica
	//{name: '3d_static_image5', zoom: 1.6}, //Cilindro
	//{name: '3d_static_image6', zoom: 2.0}, //Camião caixa aberta
	//{name: '3d_static_image7', zoom: 2.8}, //Auto Grua
	//{name: '3d_static_image8', zoom: 2.4}, //Motoniveladora
	//{name: '3d_static_image9', zoom: 1.8}, //Escavadora 2
	//{name: '3d_static_image10', zoom: 2.0}, //Betoneira
	//{name: '3d_static_image12', zoom: 2.0}, //Bulldozer
	//{name: '3d_static_image13', zoom: 1.5}, //Carrinha
	//{name: '3d_static_image14', zoom: 1.5}, //pick-up
	//{name: '3d_static_image15', zoom: 1.6}, //Jipe
	//{name: '3d_static_image16', zoom: 1.7}, //Furgão
	//{name: '3d_static_image17', zoom: 1.4}, //Carro
	//{name: '3d_static_image18', zoom: 1.7}, //Tractor agrícola
	//{name: '3d_static_image19', zoom: 1.6}, //Empilhadora
	//{name: '3d_static_image20', zoom: 1.9}, //Barco
	//{name: '3d_static_image21', zoom: 1.6}, //Tractor
	//{name: '3d_static_image22', zoom: 1.8}, //Mini-bus
	//{name: '3d_static_image23', zoom: 2.3}, //Cisterna
	//{name: '3d_static_image24', zoom: 1.3}, //Mota
	//{name: '3d_static_image25', zoom: 1.7}, //Ambulância
	//{name: '3d_static_image26', zoom: 1.8}, //Autocarro
	//{name: '3d_static_image27', zoom: 1.7}, //Camião braço hidráulico
	//{name: '3d_static_image28', zoom: 2.0}, //Camião-grua
	//{name: '3d_static_image29', zoom: 2.0}, //Camião de lixo
	//{name: '3d_static_image30', zoom: 1.8}, //Camião caixa aberta pequeno
	//{name: '3d_static_image31', zoom: 2.3}, //Camião bomba de betão
];

export const deviceCategories3D = [
    {name: '3d_vehicle_1',  zoom: 1.5}, //Carro
    {name: '3d_vehicle_3',  zoom: 1.5}, //Jipe
    {name: '3d_vehicle_29', zoom: 1.3}, //Jipe pequeno
    {name: '3d_vehicle_16', zoom: 1.6}, //SUV
    {name: '3d_vehicle_7',  zoom: 1.7}, //Pickup
    {name: '3d_vehicle_5',  zoom: 1.5}, //Carrinha
    {name: '3d_vehicle_21', zoom: 1.9}, //Mini-bus

    {name: '3d_vehicle_2',  zoom: 1.6}, //Furgão
    {name: '3d_vehicle_28', zoom: 1.9}, //Carrinha caixa aberta
    {name: '3d_vehicle_20', zoom: 1.6}, //Ambulância
    {name: '3d_vehicle_10', zoom: 1.5}, //Tractor agrícola
    {name: '3d_vehicle_13', zoom: 1.3}, //Empilhadora
    {name: '3d_vehicle_17', zoom: 1.4}, //Mota
    {name: '3d_vehicle_31', zoom: 1.9}, //Gerador

    {name: '3d_vehicle_30', zoom: 1.7}, //Camião Tractor
    {name: '3d_vehicle_32', zoom: 2.4}, //Camião contentor
    {name: '3d_vehicle_9',  zoom: 2.4}, //Autocarro
    {name: '3d_vehicle_8',  zoom: 1.9}, //Camião caixa aberta
    {name: '3d_vehicle_25', zoom: 2.0}, //Camião caixa basculante
	{name: '3d_vehicle_23', zoom: 2.2}, //Camião braço hidráulico
    {name: '3d_vehicle_11', zoom: 1.9}, //Camião do lixo

    {name: '3d_vehicle_24', zoom: 2.5}, //Camião bomba de betão
    {name: '3d_vehicle_12', zoom: 2.4}, //Camião grua
    {name: '3d_vehicle_6',  zoom: 2.0}, //Betoneira
    {name: '3d_vehicle_15', zoom: 2.3}, //Cisterna
    {name: '3d_vehicle_19', zoom: 2.1}, //Escavadora Hidráulica
    {name: '3d_vehicle_26', zoom: 2.0}, //Retro-escavadora
    {name: '3d_vehicle_22', zoom: 1.6}, //Bobcat

    {name: '3d_vehicle_34', zoom: 1.7}, //Dumper
    {name: '3d_vehicle_18', zoom: 2.1}, //Escavadora
    {name: '3d_vehicle_4',  zoom: 1.8}, //Bulldozer
    {name: '3d_vehicle_14', zoom: 1.8}, //Cilindro
    {name: '3d_vehicle_27', zoom: 2.0}, //Motoniveladora
    {name: '3d_vehicle_33', zoom: 2.1}, //Britadeira
];
