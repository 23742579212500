import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, Button } from '@material-ui/core';

import t from '../common/localization';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: '1rem',
    fontFamily: 'Proxima Nova Regular,Roboto,Arial',
    fontWeight: '600',
    lineHeight: '1.5'
  },
}));

const GeofenceStatusView = ({
  geofence,
  onEditClick, 
  onRemoveClick, 
  onToggleRadiusClick, 
}) => {
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const temporaryAccess = useSelector(state => state.session.user && state.session.user.email.startsWith('temp_') );

  const closeMenu = () => {
    setMenuAnchorEl(null);
  }

  const handleEdit = (e) => {
    e.preventDefault();
    closeMenu();
    onEditClick(geofence.geofenceId);
  };

  const handleRemove = (e) => {
    e.preventDefault();
    closeMenu();
    onRemoveClick(geofence.geofenceId);
  };

  const handleToggleRadius = (e) => {
    e.preventDefault();
    onToggleRadiusClick(geofence.geofenceId);
  };
  
  return (
    <>
      <div style={{ display: 'flex', cursor: 'default', marginLeft: '10px', marginRight: '10px' }}>
        <table style={{ padding: '10px 0px 0px 0px', fontFamily: 'Proxima Nova Regular', fontWeight: '600', float: 'left' }}>
          <tbody>
            <tr>
              <td colSpan={2} style={{ textAlign: 'left', paddingTop: '0px'}}>
                <div style={{height: '35px', fontWeight: 'bold', fontSize: '1.1rem', display: 'contents'}}>
                  <span style={{ paddingTop: '12px' }}>
                  { geofence.name }
                  </span>
                </div>
              </td>
            </tr>
            <tr style={{ paddingTop: '10px' }}>
              <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '0.75rem' }}>
                <span>
                  { geofence.description }
                </span>
              </td>
            </tr>
            <tr style={{ paddingTop: '10px' }}>
              <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '0.75rem' }}>
                <div style={{display: 'flex', marginBottom: '5px'}}>
                  <div style={{textAlign: 'center', minWidth: '15px'}}>
                    <FontAwesomeIcon icon={faGlobe} style={{color: '#78A9FF'}}/>
                  </div>
                  <div style={{float: 'right', paddingLeft: '5px'}}>
                    { geofence.latitude.toFixed(6) + ', ' + geofence.longitude.toFixed(6) }
                    <a className="tooltip" href={'https://www.google.com/maps/place/' + geofence.latitude + ',' + geofence.longitude + '/@' + geofence.latitude + ',' + geofence.longitude + ',12z'} target='_blank' style={{color: 'black', textDecoration: 'none', marginLeft: '5px', position: 'relative', top: '2px', fontSize: '1rem'}}>
                      <FontAwesomeIcon icon={faLocationArrow}  style={{color: '#78A9FF'}}/>
                      <span className="tooltiptext" style={{ marginLeft: '0px' }}>
                        Navegar
                      </span>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{ paddingTop: '0px' }}>
              <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '0.75rem' }}>
                <div style={{display: 'flex', marginBottom: '5px'}}>
                  <div onClick={handleToggleRadius} style={{ cursor: 'pointer' }} className="tooltip">
                    <span style={{textAlign: 'center', minWidth: '15px'}}>
                      <FontAwesomeIcon icon={faArrowsAltH} style={{color: '#78A9FF'}}/>
                    </span>
                    <span style={{float: 'right', paddingLeft: '5px'}}>
                      { geofence.radius } m
                    </span>
                    <span className="tooltiptext" style={{ marginLeft: '0px' }}>
                      Raio
                    </span>
                  </div>
                  { false && //!temporaryAccess && 
                    <Button
                      variant="outlined"
                      style={{color: '#78A9FF', fontSize: '10px', padding: '0px 10px', float: 'right', marginLeft: '50px'}}
                      color="primary"
                      onClick={ (event) => {setMenuAnchorEl(event.currentTarget)} }
                      >
                        {t('options')}
                    </Button>
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        >
        
        <MenuItem onClick={handleEdit}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <EditIcon style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('edit')}
          </div>
        </MenuItem>

        <MenuItem onClick={handleRemove}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <DeleteIcon style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('remove')}
          </div>
        </MenuItem>

      </Menu>

    </>
  );
};

export default GeofenceStatusView;
