import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { makeStyles, InputAdornment, Tooltip, Grid, Checkbox, InputLabel, Button, TextField, IconButton, Switch, List, ListItem,ListItemIcon, ListItemText, Card, Divider, CardHeader, FormControlLabel } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDoubleRight, faAngleLeft, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import { getDeviceName } from '../map/mapUtil';
import t from './localization';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 'auto',
    maxWidth: '600px',
	  paddingRight: '20px',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
    minWidth: '40px',
    height: '30px',
  },
  checkbox: {
    padding: '0px',
  },
  transferList: {
    margin: 'auto',
    justifyContent: 'left'
  }
}));

const TransferListControl = ({
  availableItems,
  selectedItems,

  item,
  setItem,

  title,
  allowShowGroups,
  element = false,
  attribute = false,
  csv = false,
  fixedWidth = false,
  allowAdd = true,
  allowRemove = true,
}) => {

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [loadedSelectedItems, setLoadedSelectedItems] = React.useState(false);
  const groups = useSelector(state => state.groups.items);

  useEffect(() => {
    if (!loadedSelectedItems && availableItems.length>0 && selectedItems!==false) {
      var available = [];
      var selected = [];
      availableItems.forEach(function(item) {
        if (selectedItems.includes(item.id)) {
          selected.push(item);
        }
        else
        {
          available.push(item);
        }
      });
      setRight(selected);
      setLeft(available);
      setLoadedSelectedItems(true)
    }
    return () => {}
  }, [selectedItems, availableItems]);
  
  useEffect(() => {
    if (selectedItems && availableItems.length>0) {

      var selectedValues = right.map(value => value.id)

      if (element) setItem({...item, [element]: {
        values: csv ? selectedValues.join(',') : selectedValues,
        toAdd: selectedValues.filter((item) => !selectedItems.includes(item)),
        toDelete: selectedItems.filter((item) => !selectedValues.includes(item)),
      }})

      if (attribute) setItem({...item, attributes: {...item.attributes, [attribute]: csv ? selectedValues.join(',') : selectedValues}})
    }
    return () => {}
  }, [right]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  
  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleAllRight = () => {
    setRight(right.concat(sortAndFilterItems(left)));
    setLeft(not(left, sortAndFilterItems(left)));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(sortAndFilterItems(right)));
    setRight(not(right, sortAndFilterItems(right)));
  };

  const itemFilter = (item) => {
    var show = false
    if (searchField == '') {
      show = true
    } else {
      show = getDeviceName(item, showGroups, groups).toLowerCase().indexOf(searchField.toLowerCase()) != -1
    }
    return show;
  };

  const sortAndFilterItems = (items) => {
    return sortItems(items.filter(itemFilter))
  };

  const sortItems = (items) => {
    return items.sort((a, b) => getDeviceName(a, showGroups, groups).toUpperCase() > getDeviceName(b, showGroups, groups).toUpperCase() ? 1 : -1);
  };

  //SEARCH BOX
  const [searchField, setSearchField] = React.useState('');
  const [showGroups, setShowGroups] = React.useState(false);

  const customList = (title, items, readOnly) => (
    <Card style={fixedWidth ? {width: fixedWidth} : {minWidth: '300px'}}>
      <CardHeader
        title={title + ': ' + items.length}
        className={classes.cardHeader}
        style={{paddingLeft: '7px', paddingRight: '40px', textAlign: 'center', fontWeight: 'bold' }}
        avatar={
          <Checkbox
            color='primary'
            className={classes.checkbox}
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={readOnly || items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)} style={{padding: '2px 0px 2px 7px' }}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <Checkbox
                  color='primary'
                  className={classes.checkbox}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  disabled={readOnly}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={getDeviceName(value, showGroups, groups)} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (

  <Card style={{padding: '20px', width: 'max-content', marginTop: '20px'}}>

    <InputLabel>{title}</InputLabel>

    <Grid>

      <TextField
        style={{padding: '5px 5px 0px 5px', textAlign: 'center', width: '300px'}}
        variant="outlined"
        onChange={(event) => setSearchField(event.target.value)}
        value={searchField}
        InputProps={{
          style: {
            height: '35px',
            padding: '0px 20px 0px 10px',
            marginTop: '10px',
            borderRadius: '50px'
          },
          startAdornment: (
            <div>
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
              <InputAdornment position="start" style={{right: '0px', position: 'absolute'}}>
                <IconButton
                  onClick={() => setSearchField('')}
                  onMouseDown={() => setSearchField('')}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            </div>
          )
        }}
      />

      { allowShowGroups &&
        <FormControlLabel
          style={{marginTop: '10px', marginLeft: '10px'}}
          label={t('showGroups')}
          control={
            <Switch color="primary"
              checked={showGroups}
              onChange={(event) => setShowGroups(event.target.checked)}
            />}
        />
      }

    </Grid>

    <Grid container spacing={0} style={{marginTop: '20px'}}>

      <Grid item>

          <Grid container alignItems="center" className={classes.transferList}>
            
            <Grid item>
              {customList(t('available'), sortItems(left.filter(itemFilter), !allowAdd))}
            </Grid>

            <Grid item style={{margin: '0px 10px 0px 10px'}}>
              <Grid container direction="column" alignItems="center">
                <Tooltip title={t('moveAll')}>
                  <>
                  <Button variant="outlined" color="primary" className={classes.button} onClick={handleAllRight} disabled={!allowAdd || left.length === 0} aria-label="move all right" style={{ height: '45px', minWidth: '45px', fontSize: '1.2em'}}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </Button>
                  </>                
                </Tooltip>
                <Tooltip title={t('moveSelected')}>
                  <>
                  <Button variant="outlined" color="primary" className={classes.button} onClick={handleCheckedRight} disabled={!allowAdd || leftChecked.length === 0} aria-label="move selected right" style={{ height: '45px', minWidth: '45px', fontSize: '1.2em'}} >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                  </>      
                </Tooltip>
                <Tooltip title={t('moveSelected')}>
                  <>
                  <Button variant="outlined" color="primary" className={classes.button} onClick={handleCheckedLeft} disabled={!allowRemove || rightChecked.length === 0} aria-label="move selected left" style={{ height: '45px', minWidth: '45px', fontSize: '1.2em'}} >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </Button>
                  </>                      
                </Tooltip>
                <Tooltip title={t('moveAll')}>
                  <>
                  <Button variant="outlined" color="primary" className={classes.button} onClick={handleAllLeft} disabled={!allowRemove || right.length === 0} aria-label="move all left" style={{ height: '45px', minWidth: '45px', fontSize: '1.2em'}} >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </Button>
                  </>                       
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item>
              {customList(t('selected'), right.filter(itemFilter), !allowRemove)}
            </Grid>

          </Grid>

      </Grid>

    </Grid>

  </Card>
  );
}

export default TransferListControl;
