import { useEffect, useState, useCallback } from 'react';

import { map, draw } from './Map';
import { useEffectAsync } from '../reactHelper';
import { geofenceToFeature } from './mapUtil';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from '../store';
import ReactDOM from 'react-dom';

import { poiCategories } from '../common/poiCategories';
import { geofenceColors } from '../common/geofenceColors';
import t from '../common/localization';

import CheckIcon from '@material-ui/icons/Check';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SelectField from '../form/SelectField';

import { geofencesActions } from './../store';

import { renderPopupGeofence } from './mapUtil';
import AnimatedPopup from 'mapbox-gl-animated-popup';

import { MenuItem, ListItemText } from '@material-ui/core';
import { FlareSharp } from '@material-ui/icons';
import * as turf from "@turf/turf";
import GeofenceStatusView from './GeofenceStatusView';

const GeofenceMap = ({deviceFilterSearch=""}) => {

  const id = 'geofences';
  const dispatch = useDispatch();

  var groups = useSelector(state => Object.values(state.groups.items));
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [geofenceDialog, setGeofenceDialogOpen] = useState({status: false, askIcon: false, askColor: false, askFill: false, askRadius: false});
  const [geofenceIcon, setGeofenceIcon] = useState('generic');
  const [geofenceRadius, setGeofenceRadius] = useState(100);
  const [geofenceColor, setGeofenceColor] = useState('3bb2d0');
  const [geofenceFill, setGeofenceFill] = useState(true);
  const [geofenceSpeedLimit, setGeofenceSpeedLimit] = useState(0);
  const [geofenceName, setGeofenceName] = useState('');
  const [geofenceDescription, setGeofenceDescription] = useState('');
  const [showRadius, setShowRadius] = useState(0);

  if (global.map_registerGeofenceEvent) {
    map.on('draw.create', askGeofenceDetails);
    global.map_registerGeofenceEvent = false;
  }

  function askGeofenceDetails (event) {
    setSelectedGroups([]);
    setGeofenceDialogOpen({
      status: true,
      askIcon: (event.features[0].geometry.type == 'Point'),
      askColor: (event.features[0].geometry.type == 'Polygon' || event.features[0].geometry.type == 'LineString'),
      askFill: (event.features[0].geometry.type == 'Polygon'),
      askRadius: (event.features[0].geometry.type == 'Point' || event.features[0].geometry.type == 'LineString' )
    });
  }

  const geofenceCreatedConfirm = () => {
    
    var newGeofenceData = draw.getAll();
    var newGeofenceData = newGeofenceData.features[newGeofenceData.features.length-1].geometry
    var area = newGeofenceData.coordinates;

    if (newGeofenceData.type == 'Polygon') {
      for (var i = 0; i < area[0].length; i++) { area[0][i] = area[0][i].reverse().join(' ') }
      area = 'POLYGON((' + area[0].join(',') + '))'
    } else if (newGeofenceData.type == 'Point') {
      area = 'CIRCLE(' + area.reverse().join(' ') + ', ' + geofenceRadius + ')'
    } else if (newGeofenceData.type == 'LineString') {
      if (area.length==2) {
        //Longitude Latitude
        for (var i = 0; i < area.length; i++) { area[i] = area[i].join(' ') }
      } else {
        //Latitude Longitude
        for (var i = 0; i < area.length; i++) { area[i] = area[i].reverse().join(' ') }
      }
      area = 'LINESTRING(' + area.join(',') + ')'      
    }

    var attributes = {};

    if (geofenceDialog.askIcon) {
      attributes.icon = geofenceIcon;
    }

    if (geofenceDialog.askColor) {
      attributes.color = geofenceColor;
    }

    if (geofenceDialog.askFill) {
      attributes.fill = geofenceFill;
    }

    if (geofenceDialog.askRadius && newGeofenceData.type == 'LineString') {
      attributes.polylineDistance = geofenceRadius;
    }

    attributes.speedLimit = geofenceSpeedLimit/1.852 || 0;

    apiGeofencePost(geofenceName, geofenceDescription, area, attributes);

    geofenceCreatedCancel();
  };

  const apiGeofencePost = async (name, description, area, attributes) => {
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({name, description, area, attributes})
    });

    if (response.ok) {
      var newGeofence = await response.json()
      if (newGeofence.id) {
        if (selectedGroups.length>0) {
          await fetch('/api/permissions/bulk', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(selectedGroups.map((groupId) => {return {groupId, geofenceId: newGeofence.id}})),
          });          
        }
        getGeofencesFromApi();
      }
    }
  }
  
  const getGeofencesFromApi = async () => {
    console.log('Getting geofences from API')
    const response2 = await fetch('/api/geofences');
    if (response2.ok) {
      updateGeofences(await response2.json());
    }
  }

  const geofenceCreatedCancel = () => {
    setGeofenceDialogOpen({status: false, askIcon: false, askColor: false, askFill: false, askRadius: false});
    setGeofenceRadius(100);
    setGeofenceSpeedLimit(0);
    draw.deleteAll();
  };

  const updateGeofences = (result) => {
    updateGeofencesOnMap(result)
    updateGeofencesOnList(result);
  }

  if (global.map_reloadGeofences) {
    global.map_reloadGeofences = false;
    getGeofencesFromApi();
  }

  const updateGeofencesOnMap = (result) => {
    setGeofences(result)
  }

  const updateGeofencesOnList = (result) => {
    dispatch(geofencesActions.refresh(result));
  }
  
  const [geofences, setGeofences] = useState([]);

  var popup = new AnimatedPopup({
    //https://easings.net/
    closeButton: true,
    closeOnClick: false,
    anchor: 'bottom',
    offset: 20,      
    openingAnimation: {
        duration: 200,
        easing: 'easeInOutSine'
    },
    closingAnimation: {
        duration: 200,
        easing: 'easeInOutSine'
    }
  });

  useEffectAsync(async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      setGeofences(await response.json());
    }
  }, []);

  useEffect(() => {
    if (!map.getSource(id))
    map.addSource(id, {
      'type': 'geojson',
      'data': {
        type: 'FeatureCollection',
        features: []
      }
    });

    if (!map.getSource('geofences-radius'))
    map.addSource('geofences-radius', {
      'type': 'geojson',
      'data': {
        type: 'FeatureCollection',
        features: []
      }
    });

    if (global.user_details.attributes.map_showGeofences) {

    map.addLayer({
      'source': id,
      'id': 'geofences-fill',
      'type': 'fill',
      'filter': [
         'all',
         ['==', '$type', 'Polygon'],
      ],
      'paint': {
         'fill-color': ['get', 'color'],
         'fill-outline-color': ['get', 'color'],
         'fill-opacity': ['get', 'fill'],
      },
    });
    map.addLayer({
      'source': id,
      'id': 'geofences-line',
      'type': 'line',
      'paint': {
         'line-color': ['get', 'color'],
         'line-width': 2,
      },
    });

    map.addLayer({
      'source': 'geofences-radius',
      'id': 'geofences-radius-fill',
      'type': 'fill',
      'filter': [
        'all',
        ['==', '$type', 'Polygon'],
      ],
      'paint': {
        'fill-color': '#'+geofenceColors[0].code,
        'fill-outline-color': '#'+geofenceColors[0].code,
        'fill-opacity': 0.1,
      },
      'layout': {
        'visibility': true ? 'visible' : 'none' 
      }
    });

    map.addLayer({
      'source': 'geofences-radius',
      'id': 'geofences-radius-line',
      'type': 'line',
      'paint': {
         'line-color': '#'+geofenceColors[0].code,
         'line-width': 2,
      },
    });

    map.addLayer({
      'source': id,
      'id': 'geofences-title',
      'type': 'symbol',
      'layout': {
        'icon-image': '{icon}',
        'icon-size': getIconSize(),
        'icon-anchor': 'bottom',
        'icon-allow-overlap': true,
        'text-field': (global.user_details.attributes.map_showGeofenceNames ? '{name}' : ''),
        'text-font': ['Roboto Regular'],
        'text-size': 12,
        'text-allow-overlap': false,
        'text-anchor': 'bottom',
        'text-offset': [0, 1.6],
      },
      'paint': {
        'text-color': "#444444",
        'text-halo-color': 'white',
        'text-halo-width': 1,
      },
    });
    }

    map.on('zoom', () => {
      if (map.getLayer('geofences-title')) map.setLayoutProperty('geofences-title', 'icon-size', getIconSize());
    });

    function getIconSize() {
      var zoomLevelFactor = map.getZoom()/20 + 0.3;
      return keepInRange(zoomLevelFactor, 0, 1);
    }

    function keepInRange(value, min, max)
    {
      if (value>=max) return max;
      if (value<=min) return min;
      return value;
    }

    return () => {
      
      if (map.getLayer('geofences-fill')) {
        map.removeLayer('geofences-fill');
      }
      if (map.getLayer('geofences-line')) {
        map.removeLayer('geofences-line');
      }
      if (map.getLayer('geofences-title')) {
        map.removeLayer('geofences-title');
      }
      if (map.getLayer('geofences-radius-fill')) {
        map.removeLayer('geofences-radius-fill');
      }
      if (map.getLayer('geofences-radius-line')) {
        map.removeLayer('geofences-radius-line');
      }
      if (map.getSource(id)) map.removeSource(id);
      if (map.getSource('geofences-radius')) map.removeSource('geofences-radius');
    };
  }, []);

  useEffect(() => {
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: geofences
      .filter((geofence) => deviceFilterSearch=="" || geofence.name.toLowerCase().includes(deviceFilterSearch.toLowerCase()))
      .map((geofence) => geofenceToFeature(geofence)
      )
    });

    map.getSource('geofences-radius').setData({
      type: 'FeatureCollection',
      features: Object.values(geofences)
      .filter((geofence) => geofence.area && geofence.area.startsWith("CIRCLE") && showRadius == geofence.id)
      .map((geofence) => getCircleFromArea(geofence.area)),
    });

  }, [geofences, deviceFilterSearch, showRadius]);

  const getCircleFromArea = (area) => {
    const match = area.match(/[-+]?\d*\.\d+|\d+/g)
    return match ? turf.circle([parseFloat(match[1]), parseFloat(match[0])], parseInt(match[2])*0.001) : {};
  }

  useEffect(() => {
    if (!global.popupsRegisteredPoi) {
      global.popupsRegisteredPoi = true;
      console.log('Registering map events for geofences')
      map.on('click', 'geofences-title', function (e) { e.preventDefault(); showGeofencePopup(e) });
      map.on('click', function(e) { if (e.defaultPrevented === false) { hidePopup(e) }});
      map.on('mouseenter', 'geofences-title', function (e) { map.getCanvas().style.cursor = 'pointer' });
      map.on('mouseleave', 'geofences-title', function (e) { map.getCanvas().style.cursor = '' });
    }

    return () => {
      popup.remove();
      setShowRadius(0);
      global.popUpGeofence = {id: -1, geofenceId: -1}
      map.off('click', 'geofences-title', function (e) { e.preventDefault(); showGeofencePopup(e) });
      map.off('click', function(e) { if (e.defaultPrevented === false) { hidePopup(e) }});
      map.off('mouseenter', 'geofences-title', function (e) { map.getCanvas().style.cursor = 'pointer' });
      map.off('mouseleave', 'geofences-title', function (e) { map.getCanvas().style.cursor = '' });
    };
  }, []);

  const showGeofencePopup = useCallback((e) => {
    if (popup.isOpen() && global.popUpGeofence.geofenceId == e.features[0].properties.geofenceId) {
      popup.remove();
      setShowRadius(0);
    }
    else
    {
      showPopup(e.features[0].properties);
    }
  }, []);


  //clicar no poi da lista  
  const updateGeofencePopup = useCallback((geofence) => {
    popup.remove();
    if (global.selectedGeofenceIdToRemove != geofence.geofenceId) showPopup(geofence); //Só mostra se não o formoas apagar
  }, []);


  const geofenceSelected = useSelector(state => {

    if (state.geofences.selectedGeofenceId) {

      const geofence = geofenceToFeature(state.geofences.items[state.geofences.selectedGeofenceId]) || null;
      if (geofence.geometry.type == "Point")
      {
        updateGeofencePopup(geofence.properties);        
      }
    }
    return null;
  });


  const getGeofence = (geofenceId) => {
    return global.geofences.filter(function (geofence) { return geofence.id == geofenceId })[0];
  }

  function showPopup(geofence) {
    if (typeof(geofence) != 'undefined' && geofence.latitude && geofence.longitude) {

      const placeholder = document.createElement('div');
      
      setTimeout(() => {

        ReactDOM.render(
          <Provider store={store}>
              <GeofenceStatusView
                geofence={geofence}
                onEditClick={(geofenceId) => optionEdit(geofenceId)}
                onRemoveClick={(geofenceId) => optionRemove(geofenceId)}
                onToggleRadiusClick={(geofenceId) => optionToggleRadius(geofenceId)}
              />
          </Provider>,
          placeholder,
        );
  
        popup.setDOMContent(placeholder)
        popup.setLngLat([geofence.longitude, geofence.latitude])
        popup.addTo(map);
  
        global.popUpGeofence = geofence
        
        setShowRadius(0);

      },1);

    }
  }

  const optionEdit = (geofenceId) => {
    console.log('Edit geofenceId', geofenceId)
  }

  const optionRemove = (geofenceId) => {
    console.log('Remove geofenceId', geofenceId)
  }

  const optionToggleRadius = (geofenceId) => {
    console.log('Show radius for geofenceId', geofenceId)
    console.log('showRadius', showRadius)
    setShowRadius(showRadius => showRadius == 0 ? geofenceId : 0)
  }

  function hidePopup(e) {
    //console.log('hidePopup GEOFENCES')
    popup.remove();
    setShowRadius(0);
  }

  return (
    <Dialog open={geofenceDialog.status} onClose={geofenceCreatedCancel}>
    <DialogTitle id="form-dialog-title">
      {t('zoneCreate')}
    </DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="normal"
        variant="outlined"
        label={t('name')}
        type="text"
        onChange={event => setGeofenceName(event.target.value)}
        fullWidth
        style={{minWidth: '300px'}}
      />
      <TextField
        margin="normal"
        variant="outlined"
        label={t('notes')}
        type="text"
        onChange={event => setGeofenceDescription(event.target.value)}
        fullWidth
        style={{minWidth: '300px'}}
      />
      {
        geofenceDialog.askIcon ?
        <TextField
          select
          margin="normal"
          variant="outlined"
          emptyValue={null}
          value={geofenceIcon}
          onChange={event => setGeofenceIcon(event.target.value)}
          label={t('icon')}
          fullWidth
        >
          {poiCategories.map(icon => (
            <MenuItem key={icon.name} value={icon.name}>
              <img width="20" src={'images/pois/' + icon.name + '.png'} />
              <ListItemText primary={ t('poi_' + icon.name) } style={{display: 'inline-block', paddingLeft: '10px'}} />
            </MenuItem>
          ))}
        </TextField>
        : ''
      }
      
      <TextField
        margin="normal"
        variant="outlined"
        label={t('speedLimit') + " (km/h)"}
        value={geofenceSpeedLimit}
        type="text"
        onChange={event => setGeofenceSpeedLimit(event.target.value)}
        fullWidth
        style={{width: '45%'}}
      />

      { geofenceDialog.askRadius &&
        <TextField
          margin="normal"
          variant="outlined"
          label={t('distance') + " (m)"}
          value={geofenceRadius}
          type="text"
          onChange={event => setGeofenceRadius(event.target.value)}
          fullWidth
          style={{width: '45%', marginLeft: '10%'}}
        />      
      }

      { geofenceDialog.askColor &&
        <div>
          <span style={{
            position: 'relative',
            width: 'fit-content',
            top: '10px',
            left: '10px',
            backgroundColor: 'white',
            paddingLeft: '5px',
            paddingRight: '5px',
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.8rem',
            fontFamily: 'Proxima Nova Regular,Roboto,Arial',
          }}>
            {t('style')}
          </span>

          <div style={{
            border: 'solid 1px rgba(0,0,0,0.2)',
            borderRadius: '5px',
            marginLeft: '0px',
            marginTop: '0px',
            width: '100%',
          }}>

            <div style={{display: 'flex', cursor: 'pointer', margin: '20px 20px 10px 20px'}}>
              {
                geofenceColors.map(color => (
                  <div
                    onClick={event => setGeofenceColor(color.code)}
                    style={{backgroundColor: '#'+color.code, width: '40px', height: '40px', borderRadius: '40px', marginRight: '10px'}}
                  >
                    {color.code == geofenceColor ? <CheckIcon style={{fontSize: 28, color: color.code == '000000' ? '#ffffff' : '#000000', width: 'inherit', height: 'inherit'}}/> : ""}
                  </div>
                ))  
              }
            </div>
            { geofenceDialog.askFill &&
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={geofenceFill}
                    onClick={(event) => setGeofenceFill(!geofenceFill)}
                  />
                }
                label={t('fillGeofence')}
                style={{marginLeft: '10px', marginBottom: '10px'}}
              />
            }
          </div>
        </div>
      }





      <FormControl variant="outlined" fullWidth style={{ marginTop: '15px' }}>
        <InputLabel>{t('group')}</InputLabel>
        <Select
          multiple
          fullWidth
          label={t('group')}
          variant="outlined"
          value={selectedGroups}
          onChange={(event) => setSelectedGroups(event.target.value)}
          input={<Input/>}
          renderValue={(selected) => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {groups
                .sort(function(a, b) { return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1 })
                .filter((group) => selectedGroups.includes(group.id))
                .map((item) => (
                  <Chip
                    key={item.id}
                    label={item.name}
                    onMouseDown={e => { e.stopPropagation() }}
                    onDelete={() => setSelectedGroups(selectedGroups.filter((geofence) => geofence !== item.id))}
                    />
              ))}
            </div>
          )}
        >
          {groups
            .sort(function(a, b) { return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1 })
            .map((group) => (
            <MenuItem key={group.id} value={group.id} style={{ backgroundColor: 'transparent' }}>
              <Checkbox
                color="primary"
                checked={selectedGroups.indexOf(group.id) > -1}
                />
              <ListItemText primary={group.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </DialogContent>
    <DialogActions>
      <Button onClick={geofenceCreatedCancel} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
        {t('cancel')}
      </Button>
      <Button onClick={geofenceCreatedConfirm} color="primary" variant="contained" style={{paddingLeft: '40px', paddingRight: '40px' }}>
        {t('save')}
      </Button>
    </DialogActions>
  </Dialog>
  );
}

export default GeofenceMap;