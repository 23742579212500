import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportActivity = () => {
  global.selectedMenu = "/report/activity"

  const report_name = t('reportActivity')
  const endpoint = '/report/report_activity.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    showVehicles={false}
    showDrivers={false}
    showVehiclesOrDrivers={true}
    extraFieldList = {{
        "check1_enabled": true,
        "check1_value": false,
        "check1_name": t('showDailyDetails'),
        "week": true,
        "weekdays_value": true,
        "weekdays_start": '09:00',
        "weekdays_end": '18:00',
        "saturday_value": true,
        "saturday_start": '09:00',
        "saturday_end": '13:00',
        "sunday_value": false,
        "sunday_start": '09:00',
        "sunday_end": '13:00',
        "order": "week,check1",
      }}>

    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportActivity;
