import React, { useState } from 'react';
import MainToolbar from '../MainToolbar';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, Paper } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import t from '../common/localization';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import { prefixString, exactAlertType } from '../common/stringUtils';
import { formatBoolean } from '../common/formatter';
import MenuTitle from '../MenuTitle';
import { useWindowDimensions } from '../common/WindowDimensions';

const useStyles = makeStyles(theme => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#78A9FF11 !important"
    }
  },
  paper: {
    padding: theme.spacing(1, 2, 2), minWidth: 'max-content'
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
}));

const NotificationsView = ({ updateTimestamp, onMenuClick, searchText = "" }) => {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  
  const { pageHeight, pageWidth } = useWindowDimensions();
  document.getElementById('root').style.overflow = 'hidden';

  useEffectAsync(async () => {
    const response = await fetch('/api/notifications');
    if (response.ok) {
      setItems(await response.json());
    }
  }, [updateTimestamp]);

  const formatList = (prefix, value) => {
    if (value) {
      return value
        .split(/[, ]+/)
        .filter(Boolean)
        .map(it => t(prefixString(prefix, it)))
        .join(', ');
    }
    return '';
  };

  return (
    <TableContainer style={{ maxHeight: pageHeight - 160 }}>
    <Table stickyHeader style={{}}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.columnAction} />
          <TableCell>{t('alertType')}</TableCell>
          <TableCell>{t('associatedVehicles')}</TableCell>
          <TableCell>{t('deliveryMethod')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items
        .sort(function(a, b) { return t(prefixString('event', exactAlertType(a))) > t(prefixString('event', exactAlertType(b))) ? 1 : -1 })
        .map(item => (
          <TableRow key={item.id} className={classes.tableRow}>
            <TableCell className={classes.columnAction} padding="none">
              <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                <MoreVertIcon />
              </IconButton>
            </TableCell>
            <TableCell>{t(prefixString('event', exactAlertType(item)))}</TableCell>
            <TableCell>{item.always ? 'Todos' : 'Associados ao alerta'}</TableCell>
            <TableCell>{formatList('notificator', item.notificators)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  );
}

const NotificationsPage = () => {
  global.selectedMenu = "/settings/notifications"
  const classes = useStyles();

  return (
    <>
      <MainToolbar />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <MenuTitle title={t('alertNotifications')} />
          <EditCollectionView content={NotificationsView} editPath="/settings/notification" endpoint="notifications" allowAddRemove = {true} searchInput={false} itemName="alerta"/>
        </Paper>
      </div>
    </>
  );
}

export default NotificationsPage;
