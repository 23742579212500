import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportSpeeding = () => {
  global.selectedMenu = "/report/speeding"

  const report_name = t('reportSpeed')
  const endpoint = '/report/report_speeding.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    showVehicles={false}
    showDrivers={false}
    showVehiclesOrDrivers={true}
    extraFieldList = {{
        "text1_value": 0,
        "text1_name": t('tolerance') + ' (km/h)',
        "text2_value": 0,
        "text2_name": t('tolerance') + ' (' + t('secondsAbreviation') + ')',
        "check1_enabled": true,
        "check1_value": false,
        "check1_name": t('useRoadLimits'),
        "geofence_filter_enabled": true,
        "geofence_filter_with_speedLimit": true,
        "geofence_filter_value": false,
      }}>
    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportSpeeding;
