import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { driversActions } from './../store';
import { useHistory } from 'react-router-dom';
import MainToolbar from '../MainToolbar';
import { Typography, Select, MenuItem, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, Paper, Button, CircularProgress, Box } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import t from '../common/localization';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import MenuTitle from '../MenuTitle';
import { generateRandomString } from '../common/stringUtils';
import moment from 'moment';

import Spreadsheet from "react-spreadsheet";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
	paper: {
	  padding: theme.spacing(1, 2, 2), minWidth: 'max-content'
	},
	content: {
	  flex: 1,
	  overflow: 'auto',
	  padding: theme.spacing(2),
	  padding: '5px'
	},
  }));

const DriverImportPage = () => {
	global.selectedMenu = "/settings/drivers"
	const classes = useStyles();
	const history = useHistory();

	const [progress, setProgress] = useState(-1);
	const [currentGroups, setCurrentGroups] = useState([]);
	const [importing, setImporting] = useState(false);
	const [waitingForApi, setWaitingForApi] = useState(false);

	useEffectAsync(async () => {
		const response = await fetch('/api/groups');
		if (response.ok) {
		  const result = await response.json();
		  setCurrentGroups(result);
		}
	  }, []);

	const blankSpreadsheet = [
		[
			{ value: "" },
			{ value: "" },
			{ value: "" },
			{ value: "" },
		],
	]

	const reset = () => {
		setData(blankSpreadsheet);
		resetLog();
		setWaitingForApi(false);
		setImporting(false);
		setProgress(-1);
	}

	const [data, setData] = useState(blankSpreadsheet);

	const [newDrivers, setNewDrivers] = useState([]);

	useEffect(() => {

		var items = []

		for (let index = 0; index < data.length; index++) {
			const row = data[index];
			if (row[0] && row[0].value != "") {
				items.push({
					name: row[0] ? row[0].value : "",
					phone: row[1] ? row[1].value : "",
					notes: row[2] ? row[2].value : "",
					uniqueId: row[3] ? row[3].value : ("x." + generateRandomString(14)),
					ststus: 0,
				});				
			}

		}

		setNewDrivers(items)
	}, [data]);

	const [groupField, setGroupField] = React.useState(0);

	const handleChangeGroupField = (event) => {
		setGroupField(event.target.value);
	  };

	const importNewDrivers = () => {
		if (confirm("Import " + newDrivers.length + " drivers?")) {
			importNewDriversGo();			
		}
	}

	const addToLog = (line, timestamp = true, newLine = true) => {

		var element1 = ''
		var element2 = ''
		if (line.startsWith("ERRO")) {
			element1 = '<span style="background-color: #ff5555;">'
			element2 = '</span>'
		}

		document.getElementById("log").innerHTML += element1 + (timestamp ? moment().format('YYYY-MM-DD HH:mm:ss') : "") + " " + line + element2 + (newLine ? "<br>" : "")
	}

	const resetLog = () => {
		document.getElementById("log").innerHTML = ""
	}

	useEffect(() => {
		if (importing && !waitingForApi) {
			for (let index = 0; index < newDrivers.length; index++) {
				const newDriver = newDrivers[index];
				if (newDriver.status == 0) {
					newDrivers[index].status = 1;
					callApi(index);
					break;
				}
			}
		}

	}, [waitingForApi, importing]);

	const callApi = async (index) => {

		const newDriver = newDrivers[index];

		setWaitingForApi(true);

		addToLog(`A inserir condutor ` + newDriver.name + " (" + newDriver.uniqueId + ")...", true, false);

		setProgress(((index+1)/newDrivers.length)*100);
		
		const options = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				name: newDriver.name,
				uniqueId: newDriver.uniqueId,
				attributes: {
					phone: newDriver.phone,
					notes: newDriver.notes
				}
			})
		};
		const response = await fetch('/api/drivers', options)
		if (response.ok) {
			var createdDriver = await response.json()
			console.log("createdDriver", createdDriver);
			addToLog(`OK`, false);

			if (groupField>0) {
				const options = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ groupId: groupField, driverId: createdDriver.id })
				};

				addToLog(`A adicionar condutor ao grupo...`, true, false);

				const response2 = await fetch('/api/permissions', options)

				if (response2.ok) {
					addToLog(`OK`, false);
					setWaitingForApi(false);
				} else {
					addToLog(`ERRO`, false);
					setWaitingForApi(false);
				}

			} else {
				setWaitingForApi(false);
			}

		}
		else
		{
			addToLog(`ERRO`, false);
			setWaitingForApi(false);
		}

		if (index+1 == newDrivers.length) {
			addToLog(`Importação concluída!`);
			setImporting(false);
		}
	}

    const importNewDriversGo = async () => {

		const response = await fetch('/api/drivers');
		if (response.ok) {

			for (let index = 0; index < newDrivers.length; index++) {
				newDrivers[index].status = 0;
			}

			setProgress(0);
			resetLog();

			setImporting(true);

		}
    }

	return (
    <>
      <MainToolbar />
      <div className={classes.content}>
        <Paper className={classes.paper}>
			<MenuTitle title="Importação de Condutores" />
			<br></br>

			{ newDrivers.length>0 &&
				<Typography style={{margin: '10px'}}>
					
					<Select
						value={groupField}
						onChange={handleChangeGroupField}
						variant="outlined"
						style={{
						width: 'auto',
						height: '35px',
						borderRadius: '50px'
						}}>

						<MenuItem value={0}>Importar {newDrivers.length} condutores sem grupo</MenuItem>
						{currentGroups.map(group => (
							<MenuItem key={group.id} value={group.id}>Importar {newDrivers.length} condutores no grupo "{group.name}"</MenuItem>
						))}
					</Select>
					<Button
						onClick={importNewDrivers}
						color="primary"
						variant="outlined"
						style={{marginLeft: '20px'}}
						>
						Importar
					</Button>					
					<span style={{ position: 'absolute', marginLeft: '20px' }}>
						{ progress>-1 &&
							<CircularProgressWithLabel value={progress} />
						}
					</span>

					<Button
						onClick={reset}
						color="primary"
						variant="outlined"
						style={{marginLeft: '80px'}}
						>
						RESET
					</Button>

				</Typography>
			}

			<Spreadsheet
				data={data}
				onChange={setData}
				columnLabels={['Nome', 'Telefone', 'Observações', 'RFID']}
				style={{display: 'inline-table'}}
				/>
			<span id="log" style={{ marginLeft: '30px', position: 'absolute', borderLeft: 'solid 2px', paddingLeft: '5px' }}>
			</span>
        </Paper>
      </div>
    </>
  );
}

export default DriverImportPage;
