import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'devices_extrainfo',
  initialState: {
    items: {}
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach(item => state.items[item['id']] = item);
    },
  }
});

export { actions as devicesExtraInfoActions };
export { reducer as devicesExtraInfoReducer };
