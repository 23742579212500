import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';

import t from './common/localization';
import RemoveDialog from './RemoveDialog';
import SetKm from './SetKm';
import SetBlocking from './SetBlocking';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VideocamIcon from '@material-ui/icons/Videocam';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoad } from '@fortawesome/free-solid-svg-icons'

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from '@material-ui/core/IconButton';
import BuildIcon from '@material-ui/icons/Build';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import LaunchIcon from '@material-ui/icons/Launch';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { followDevice, stopFollowingDevice } from "./map/FollowDevice";

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const EditCollectionView = ({ content, editPath, endpoint, allowAddRemove = true, allowEdit = true, denyAdd = false, searchInput = false, itemName, extraData = false , extraData2 = false, groupSwicth = false, setDeviceFilterGroup = 0, setDeviceFilterSearch = '', setDeviceFilterTab = '', setCameraDialogOpen = false}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [selectedImmobilization, setSelectedImmobilization] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
  const [showSetKm, setShowSetKm] = useState(false);
  const [showSetBlocking, setShowSetBlocking] = useState(false);

  const menuShow = (anchorId, itemId) => {

    if (endpoint == 'devices') {
      global.devices.forEach(function(device, index) {
        if (device.id == itemId) {
          setSelectedImmobilization(device.attributes.has_immobilization == "1")
          setSelectedCamera(device.attributes.device_type == "13") //FMC125 DualCam
        }
      });
    }

    setSelectedAnchorEl(anchorId);
    setSelectedId(itemId);
  }

  const menuHide = () => {
    setSelectedAnchorEl(null);
  }

  const handleAdd = () => {
    global.extraData = extraData;
    history.push(editPath + (global.ctrlKey ? "_import" : ""));
    menuHide();
  }

  const handleEdit = () => {
    global.extraData = extraData;
    history.push(`${editPath}/${selectedId}`);
    menuHide();
  }

  const handleSetKm = () => {
    setShowSetKm(true);
    menuHide();
  }

  const handleSetBlocking = () => {
    setShowSetBlocking(true);
    menuHide();
  }

  const handleSeeHistory = () => {
    history.push({pathname: `/replay`, data: {selectedId: selectedId}})
  };

  const handleFollowDevice = () => {
    menuHide();
    extraData();
    followDevice(global.devices.filter(device => device.id == selectedId)[0]);
  };

  const handleMaintenances = () => {
    global.extraData = selectedId;
    history.push(`/management/maintenances`)
  };

  const handleCosts = () => {
    global.extraData = selectedId;
    history.push(`/management/costs`)
  };

  const handleDocs = () => {
    global.extraData = selectedId;
    history.push(`/management/documents`)
  };

  const handleRefills = () => {
    global.extraData = selectedId;
    history.push(`/management/refills`)
  };

  const handleMaintenaceSettings = () => {
    global.extraData = selectedId;
    history.push(`/management/settings/${selectedId}`)
  };

  const handleRemove = () => {
    setRemoveDialogShown(true);
    menuHide();
  }

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    setUpdateTimestamp(Date.now());
  }

  const handleCloseSetKm = () => {
    setShowSetKm(false);
    setUpdateTimestamp(Date.now());
  }

  const handleCloseSetBlocking = () => {
    setShowSetBlocking(false);
    setUpdateTimestamp(Date.now());
  }

  const handleOpenDocument = () => {
    window.open("/report/api.php/file/" + selectedId, '_blank');
    setUpdateTimestamp(Date.now());
  }

  const handleDownloadDocument = () => {
    forceDownload("/report/api.php/file/" + selectedId);
    setUpdateTimestamp(Date.now());
  }

  const Content = content;

  //Campo de pesquisa
  const [searchField, setSearchField] = React.useState('');

  const handleChangeSearchField = (event) => {
    setSearchField(event.target.value);
  };

  const clearSearch = () => {
    setSearchField('');
  };

  const [grouping, setGrouping] = useState(false);

  const userHasPermission = (permission) => {
    return global.user.attributes.web_permissions && global.user.attributes.web_permissions.includes(permission)
  }

  useEffect(() => {
    if (global.searchField[location.pathname]) {
      setSearchField(global.searchField[location.pathname])
    }
  }, []);

  useEffect(() => {
    global.searchField[location.pathname] = searchField;
  }, [searchField]);

  const forceDownload = (url) => {
    fetch(url)
        .then(response => {
            // Check if the response is okay
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            // Extract the filename from the Content-Disposition header
            const disposition = response.headers.get('Content-Disposition');
            let filename = '';
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            // If filename is not specified, create one from the URL
            if (!filename) {
                filename = url.split('/').pop();
            }
            return response.blob().then(blob => ({ blob, filename }));
        })
        .then(({ blob, filename }) => {
            // Create a link element and use it to download the file
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }, 0);
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
  }

  return (
    <>
      {
        searchInput &&
        <TextField
          style={{padding: '5px 5px 0px 5px', width: '100%', textAlign: 'center'}}
          variant="outlined"
          onChange={handleChangeSearchField}
          value={searchField}
          InputProps={{
            style: {
              backgroundColor: searchField == "" ? "" : global.searchFieldColor,
              height: '35px',
              padding: '0px 0px 0px 10px',
              marginTop: '10px',
              borderRadius: '50px'
            },
            startAdornment: (
              <div>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                <InputAdornment position="end" style={{right: '0px', position: 'absolute'}}>
                  <IconButton
                    aria-label="Apagar filtro"
                    onClick={clearSearch}
                    onMouseDown={clearSearch}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              </div>
            )
          }}
        />
      }

      {
        groupSwicth &&
        <FormControlLabel
          label={t('groupResults')}
          style={{marginLeft: '0px', marginBottom: '5px'}}
          control={
            <Switch color="primary"
              checked={grouping}
              onChange={event => setGrouping(event.target.checked)}
            />}
        />
      }

      <Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} searchText={searchField} selectedId={selectedId} extraData={extraData} extraData2={extraData2} grouping={grouping} setDeviceFilterGroup={setDeviceFilterGroup} setDeviceFilterSearch={setDeviceFilterSearch} setDeviceFilterTab={setDeviceFilterTab} />

      { allowAddRemove && !denyAdd &&
      <Box>
        <Fab size="medium" color="primary" className={classes.fab} style={{position: 'fixed'}} onClick={handleAdd}>
          <AddIcon />
        </Fab>
      </Box>      
      }
      <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>

        {
          allowEdit &&
          <MenuItem onClick={handleEdit}>
            <ListItemIcon style={{minWidth: '30px'}}>
              <EditIcon style={{color: '#78A9FF' }} />
            </ListItemIcon>
            <Typography>{t('edit')}</Typography>
          </MenuItem>
        }

        { allowAddRemove &&
        <Box>
          <MenuItem onClick={handleRemove}>
            <ListItemIcon style={{minWidth: '30px'}}>
              <DeleteIcon style={{color: '#78A9FF' }} />
            </ListItemIcon>
            <Typography>{t('remove')}</Typography>
          </MenuItem>  
        </Box>
        }

        { endpoint == 'devices' && global.user.userLimit != 0 &&
        <Box>
          <MenuItem onClick={handleSetKm}>
            <ListItemIcon style={{minWidth: '30px'}}>
              <FontAwesomeIcon icon={faRoad} style={{color: '#78A9FF' }} />
            </ListItemIcon>
            <Typography>{t('fixKm')}</Typography>
          </MenuItem>
        </Box>
        }

        { endpoint == '/report/api.php/maintenance_summary' &&
          <Box>
            <MenuItem onClick={handleMaintenances}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <BuildIcon fontSize="small" style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('maintenances')}</Typography>
            </MenuItem>

            <MenuItem onClick={handleCosts}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <AttachMoneyIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('costs')}</Typography>
            </MenuItem>

            <MenuItem onClick={handleRefills}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <LocalGasStationIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('refills')}</Typography>
            </MenuItem>
            <MenuItem onClick={handleDocs}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <DescriptionIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('documents')}</Typography>
            </MenuItem>
            <MenuItem onClick={handleMaintenaceSettings}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <SettingsIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('settings')}</Typography>
            </MenuItem>


          </Box>
        }

        { endpoint.includes('/report/api.php/document') &&
          <Box>
            <MenuItem onClick={handleOpenDocument}>
              <LaunchIcon style={{ color: '#78A9FF', minWidth: '30px' }}/>
              <Typography>Ver</Typography>
            </MenuItem>
            <MenuItem onClick={handleDownloadDocument}>
              <GetAppIcon style={{ color: '#78A9FF', minWidth: '30px' }}/>
              <Typography>Download</Typography>
            </MenuItem>
          </Box>
        }

        { endpoint == 'devices' && selectedImmobilization && !global.user.readonly && userHasPermission('block') &&
          <Box>
            <MenuItem onClick={handleSetBlocking}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <BlockIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('blocking')}</Typography>
            </MenuItem>
          </Box>
        }

        { endpoint == 'devices' &&
          <Box>
            <MenuItem onClick={handleSeeHistory}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <AccessTimeIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('seeHistory')}</Typography>
            </MenuItem>
          </Box>
        }

        { endpoint == 'devices' &&
          <Box>
            <MenuItem onClick={handleFollowDevice}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <VisibilityIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('followVehicle')}</Typography>
            </MenuItem>
          </Box>
        }

        { endpoint == 'devices' &&
          <Box>
            <MenuItem onClick={handleRefills}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <LocalGasStationIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('refills')}</Typography>
            </MenuItem>
          </Box>
        }

        { endpoint == 'devices' && selectedCamera &&
          <Box>
            <MenuItem onClick={() => { menuHide(); setCameraDialogOpen(selectedId)}}>
              <ListItemIcon style={{minWidth: '30px'}}>
                <VideocamIcon style={{color: '#78A9FF' }} />
              </ListItemIcon>
              <Typography>{t('cameras')}</Typography>
            </MenuItem>
          </Box>
        }

      </Menu>
      <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId} onResult={handleRemoveResult} itemName={itemName} />

      <SetKm
        open={showSetKm}
        setOpen={handleCloseSetKm}
        deviceId={selectedId}
        />

      <SetBlocking
        open={showSetBlocking}
        setOpen={handleCloseSetBlocking}
        deviceId={selectedId}
        />
    </>
  );
}

export default EditCollectionView;
