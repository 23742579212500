import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportPassengerPerVehicle = () => {
  global.selectedMenu = "/report/passenger_vehicle"

  const report_name = "Relatório de recolhas por veí­culo"
  const endpoint = '/report/report_passenger_pickup.php?ReportVersion=1'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    extraFieldList = {{
        "check1_enabled": true,
        "check1_value": false,
        "check1_name": "Mostrar apenas valores totais",

        "check2_enabled": true,
        "check2_value": false,
        "check2_name": "Mostrar veículos sem dados",        
      }}>
      <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items} report="report_passenger_pickup"></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportPassengerPerVehicle;
