import React, { useState, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import MaterialTable from 'material-table'

import {  makeStyles, IconButton, Paper } from '@material-ui/core';

import MainToolbar from '../MainToolbar';
import MenuTitle from '../MenuTitle';
import t from '../common/localization';
import PeriodSelector from '../common/PeriodSelector';
import EditCollectionView from '../EditCollectionView';
import ExportButton from '../common/ExportButton';
import { useEffectAsync } from '../reactHelper';
import { formatBigNumber } from '../common/formatter';
import { useWindowDimensions } from '../common/WindowDimensions';
import { getDevice } from '../map/mapUtil';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableTranslations={
  body: {
    emptyDataSourceMessage: "Sem dados",
    filterRow: {
      filterTooltip: 'Procurar'
    }
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'itens por página',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Página seguinte',
    lastTooltip: 'Última página'
  }
}

const useStyles = makeStyles(theme => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#78A9FF11 !important"
    }
  },
  paper: {
    padding: theme.spacing(1, 2, 2), minWidth: 'max-content'
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
}));

const CostsView = ({ updateTimestamp, onMenuClick, searchText = ""}) => {
  document.getElementById('root').style.overflow = 'hidden';

  const history = useHistory();
  const { pageHeight } = useWindowDimensions();

  const [items, setItems] = useState([]);
  
  const [period, setPeriod] = useState(global.management.period);
  const [periodFrom, setPeriodFrom] = useState(false);
  const [periodTo, setPeriodTo] = useState(false);

  var deviceId = global.extraData;
  if (!deviceId) history.push(`/management/summary`)

  useEffectAsync(async () => {
    const response = await fetch('/report/api.php/cost/' + deviceId);
    if (response.ok) {
      var costs = await response.json();
      setItems(costs.sort((a, b) => a.cost_date < b.cost_date ? 1 : -1));
    }
  }, [updateTimestamp]);

  const columns = [
    {
      title: '',
      field: 'id',
      cellStyle: {padding: '0px'},
      width: "1%",
      render: rowData =>
        <IconButton onClick={(event) => onMenuClick(event.currentTarget, rowData.id)}>
          <MoreVertIcon />
        </IconButton>
    },
    {
      title: 'Data',
      field: 'cost_date',
      render: row => row.cost_date != null ? moment(row.cost_date).format('DD/MM/YYYY') : '',
      defaultSort: 'desc',
    },
    {
      title: 'Tipo',
      field: 'cost_type',
      render: row => costType(row.cost_type),
      cellStyle: {whiteSpace: 'nowrap'},
    },
    {
      title: 'Entidade',
      field: 'entity',
      cellStyle: {whiteSpace: 'nowrap'},
    },
    {
      title: 'Descrição',
      field: 'designation',
      cellStyle: ({ whiteSpace: 'break-spaces' }),
    },
    {
      title: 'Factura',
      field: 'invoice',
    },
    {
      title: 'Custo ('+global.currency+')',
      field: 'cost',
      render: row => formatBigNumber(row.cost),
    },
    {
      title: 'Expiração (dias)',
      field: 'expiry_date',
      render: row => row.expiry_date != null ? moment(row.expiry_date).diff(moment(), 'days')+1 : '',
      cellStyle: (column, row) => ({
        backgroundColor: cost_color(row.expiry_date)
      }),
    },
  ];
  
  const filteredItems = (items, periodFrom, periodTo) => {
    if (periodFrom && periodTo) {
      return items.filter(item => moment(item.cost_date, "YYYY-MM-DD").isBetween(periodFrom, periodTo, null, '[]'))
    } else {
      return items
    }
  }

  const red = '#ff000040'
  const yellow = '#ffff0040'
  const green = '#00ff0040'

  const costType = (type) => {
    var result = '';
    if (type == 'insurance') result = 'Seguro'; 
    if (type == 'license') result = 'Licença'; 
    if (type == 'tax') result = 'Imposto';
    if (type == 'cleaning') result = 'Lavagem';
    if (type == 'other') result = 'Outro';
    return result;
  }
  
  const cost_color = (expiry_date) => {
    if (expiry_date) {
      var days_left = moment(expiry_date).diff(moment(), 'days')+1
      if (days_left<0) return red;
      if (days_left<30) return yellow;
    }
    return '';
  }
  
  return (
    <>
      <MenuTitle title={t('costs') + ' > ' + getDevice(deviceId).attributes.license_plate} style={{ position: 'fixed', top: '50px', left: '20px', zIndex: '100' }}/>

      <MaterialTable
        title=""
        icons={tableIcons}
        localization={tableTranslations}
        columns={columns}
        data={filteredItems(items, periodFrom, periodTo)}
        onSearchChange={(value) => global.list_search_costs = value}
        options={{
          sorting: false,
          toolbar: true,
          
          search: true,
          searchText: global.list_search_costs ? global.list_search_costs : '',
          searchFieldVariant: 'outlined',
          searchFieldStyle: {
            height: '35px',
            padding: '0px 0px 0px 10px',
            marginTop: '10px',
            borderRadius: '50px',
          },
          
          paging: false,
          pageSize: 50,
          pageSizeOptions: [10, 50, 100],
          emptyRowsWhenPaging: false,

          padding: "dense",
          maxBodyHeight: pageHeight - 160,
        }}
        actions={[
          {
            isFreeAction: true,
          }
        ]}
        components={{
          Action: props => (
            <div style={{ marginLeft: '20px', position: 'relative' }}>
              <PeriodSelector
                period={period}
                setPeriod={setPeriod}
                setPeriodFrom={setPeriodFrom}
                setPeriodTo={setPeriodTo}
                total={true}
                months={true}
                years={5}
              />
              &nbsp;
              &nbsp;
              <ExportButton
                filename="Costs"
                title={t('costs')}
                columns={columns}
                data={filteredItems(items, periodFrom, periodTo)}
              />
            </div>
          )
        }}
      />
    </>
  );
}

const CostsPage = () => {
  global.selectedMenu = "/management/summary"
  const classes = useStyles();
  return (
    <>
      <MainToolbar />
      <div className={classes.content}>
        <Paper className={classes.paper} style={{display: 'contents'}}>
          <EditCollectionView
            content={CostsView}
            editPath={`/management/cost`}
            endpoint={`../report/api.php/cost/${global.extraData}`}
            extraData={global.extraData}
            allowAddRemove={true}
            itemName={t('cost').toLowerCase()}/>
        </Paper>
      </div>
    </>
  );
}

export default CostsPage;
