import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import t from './common/localization';
import SetDialog from './SetDialog';

import { getOdometerTypeCanBus, getOdometerValue } from './map/mapUtil';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const SetKm = ({ open, setOpen, deviceId }) => {

    const devices = useSelector(state => state.devices);
    const positions = useSelector(state => state.positions);

    const [statusDialog, setStatusDialog] = useState({title: '', message: '', icon: ''});
    const [resultStatus, setResultStatus] = useState(false);

    const [selectedKm, setSelectedKm] = useState(0);
    const [selectedHours, setSelectedHours] = useState(0);
    const [selectedName, setSelectedName] = useState('');
    
    useEffect(() => {
        if (devices.items && devices.items[deviceId]) setSelectedName(devices.items[deviceId].name);
    }, [deviceId]);

    useEffect(() => {
        if (positions.items && positions.items[deviceId]) {
          setSelectedKm(getOdometerValue(positions.items[deviceId].attributes, false));
          setSelectedHours(Math.floor(positions.items[deviceId].attributes.hours/1000/3600));
        }
    }, [deviceId]);

    const handleSetCountersConfirm = () => {
        apiAccumulatorsPut(deviceId, parseInt(selectedKm, 10)*1000, parseInt(selectedHours, 10)*1000*3600);
        handleSetCountersCancel();
    };  

    const handleSetCountersCancel = () => {
        setOpen(false);
    };

    const apiAccumulatorsPut = async (deviceId, totalDistance, hours = 0) => {
        const response = await fetch(`/api/devices/${deviceId}/accumulators`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({deviceId, totalDistance, hours})
        });
    
        if (response.ok) {
          handleResultStatus('Correcção de km', 'Km corrigidos com sucesso', 'ok')
        }
        else
        {
          var error_details = response.status + ' ' + response.statusText
          handleResultStatus('Correcção de km', `Erro ao corrigir km (${error_details})`, 'error')
        }
    }

    const handleResultStatus = (title, message, icon) => {
        setStatusDialog({title: title, message: message, icon: icon});
        setResultStatus(true);
      }
    
    const handleResultStatusCancel = () => {
        setResultStatus(false);
    };

  return (
    <>
      <Dialog open={open} onClose={handleSetCountersCancel}>
        <DialogTitle>
          {t('fixKm')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('insertCurrentKm')} "{selectedName}"
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            variant="outlined"
            label="Km"
            type="number"
            value={selectedKm}
            disabled={positions.items[deviceId] && getOdometerTypeCanBus(positions.items[deviceId].attributes)}
            onChange={event => setSelectedKm(event.target.value)}
            inputProps={{min: 0, style: { width: '100px', textAlign: 'center' }}}
          />
          <span style={{ marginLeft: '20px' }}>
          </span>
          <TextField
            margin="normal"
            variant="outlined"
            label={t('hours')}
            type="number"
            value={selectedHours}
            onChange={event => setSelectedHours(event.target.value)}
            inputProps={{min: 0, style: { width: '100px', textAlign: 'center' }}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSetCountersCancel} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSetCountersConfirm} color="primary" variant="contained" style={{paddingLeft: '40px', paddingRight: '40px' }}>
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>

      <SetDialog open={resultStatus} setOpen={handleResultStatusCancel} dialogContent={statusDialog} />
    </>
  );
}

export default SetKm;
