import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainToolbar from '../MainToolbar';
import { makeStyles, IconButton, Paper, FormControlLabel, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import CropFreeIcon from '@material-ui/icons/CropFree';

import {getDeviceQrCodeUrl, downloadDeviceQrCode} from '../common/common';

import t from '../common/localization';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import MenuTitle from '../MenuTitle';
import ExportButton from '../common/ExportButton';
import { formatDate } from '../common/formatter';
import { getOdometerValue, getHourmeterValue } from '../map/mapUtil';
import { useWindowDimensions } from '../common/WindowDimensions';

import MaterialTable from 'material-table'
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#78A9FF11 !important"
    }
  },
  paper: {
    padding: theme.spacing(1, 2, 2), minWidth: 'max-content'
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
}));

const DeviceQrCodesView = ({ updateTimestamp, onMenuClick }) => {

  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showQrCode, setShowQrCode] = useState(false);
    
  const { pageHeight, pageWidth } = useWindowDimensions();
  document.getElementById('root').style.overflow = 'hidden';

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      var devices = await response.json();

      const response3 = await fetch('/api/groups');
      if (response3.ok) {
        var groups = await response3.json();

        var itemList = []

        for (let index = 0; index < devices.length; index++) {
          var item = devices[index]
          item.license_plate = item.attributes.license_plate
          item.group = ''
          for (let index2 = 0; index2 < groups.length; index2++) {
            if (groups[index2].id == devices[index].groupId) item.group = groups[index2].name
          }
          itemList.push(item)
        }
        setItems(itemList);
      }
    }
  }, [updateTimestamp]);

  useEffect(() => {
    setColumns([
      { title: '',
      field: 'id',
      cellStyle: {padding: '0px'},
      render: device =>
        <>
          <IconButton onClick={() => setShowQrCode(device)} style={{ padding: '5px' }}>
            <CropFreeIcon style={{color: '#78A9FF'}}/>
          </IconButton>
          <IconButton onClick={() => downloadDeviceQrCode(device, 500)} style={{ padding: '5px' }}>
            <GetAppIcon style={{color: '#78A9FF'}}/>
          </IconButton>
        </>

      },
      { title: t('name'), field: 'name', defaultSort: 'asc', cellStyle: {whiteSpace: 'nowrap'} },
      { title: t('licensePlate'), field: 'license_plate', cellStyle: {whiteSpace: 'nowrap'} },
      { title: t('group'), field: 'group' },
      { title: t('model'), field: 'model' },      
    ].filter((item) => item !== null));
  }, [updateTimestamp]);

  return (
    <>
      <MenuTitle title={t('vehicles')} style={{ position: 'fixed', top: '50px', left: '20px', zIndex: '100' }}/>

      <Dialog open={showQrCode} onClose={() => setShowQrCode(false)}>
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          {showQrCode.license_plate}
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogContentText component={'span'}>
            <img
              src={getDeviceQrCodeUrl(showQrCode)}
              style={{
                width: '50%',
                height: '50%',
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => downloadDeviceQrCode(showQrCode, 500)} color="primary" variant="outlined">
            {t('download')}
          </Button>
          <Button onClick={() => setShowQrCode(false)} color="primary" variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <MaterialTable
        title=""
        icons={tableIcons}
        columns={columns}
        data={items}
        onSearchChange={(value) => global.list_search = value}
        options={{
          sorting: true,
          toolbar: true,
          
          search: true,
          searchText: global.list_search ? global.list_search : '',
          searchFieldVariant: 'outlined',
          searchFieldStyle: {
            height: '35px',
            padding: '0px 0px 0px 10px',
            marginTop: '10px',
            borderRadius: '50px'
          },
          
          paging: true,
          pageSize: 50,
          pageSizeOptions: [10, 50, 100],
          emptyRowsWhenPaging: false,

          padding: "dense",
          maxBodyHeight: pageHeight - 160,
        }}
        actions={[
          {
            isFreeAction: true,
          }
        ]}
        components={{
          Action: props => (
            <div style={{ marginLeft: '20px', top: '5px', position: 'relative' }}>
            </div>
          )
        }}
      />
    </>
  )
}

const DeviceQrCodes = () => {
  global.selectedMenu = "/refills"
  const classes = useStyles();
  return (
    <>
      <MainToolbar />
      <div className={classes.content}>
        <Paper className={classes.paper} style={{display: 'contents'}}>
          <EditCollectionView
            content={DeviceQrCodesView}
            editPath="/settings/device"
            endpoint="devices"
            allowAddRemove = {false}
            allowEdit = {false}
            denyAdd = {true}
            />
        </Paper>
      </div>
    </>
  );
}

export default DeviceQrCodes;
