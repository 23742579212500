import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportManagementAnalysis = () => {
  global.selectedMenu = "/report/management_analysis"

  const report_name = t('costAnalysis')
  const endpoint = '/report/report_management_analysis.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name} showYear={true} maximumPeriodDays={365*10}>
    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items} ReportManagementAnalysis={true}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportManagementAnalysis;
