import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportTrips = () => {
  global.selectedMenu = "/report/trips"

  const report_name = t('reportTrips')
  const endpoint = '/report/report_trips.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name}
    showVehicles={false}
    showDrivers={false}
    showVehiclesOrDrivers={true}
    extraFieldList = {{
      "order": "select1,check1",
      "check1_enabled": true,
      "check1_value": false,
      "check1_name": t('hideShortTrips'),
      "select1_name": t('tripFilter'),
      "select1_options": [[0, t('tripFilterShowAll')], [1, t('tripFilterShowWork')], [2, t('tripFilterShowPersonal')]],
      "select1_value": 0,
      "week": false,
      "weekdays_value": true,
      "weekdays_start": '09:00',
      "weekdays_end": '18:00',
      "saturday_value": true,
      "saturday_start": '09:00',
      "saturday_end": '13:00',
      "sunday_value": false,
      "sunday_start": '09:00',
      "sunday_end": '13:00',

    }}>
      <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportTrips;
