import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportFuelAnalysis = () => {
  global.selectedMenu = "/report/fuel_analysis"

  const report_name = t('reportFuelAnalysis')
  const endpoint = '/report/report_fuel_analysis.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name} devicesWithCanbus={true} devicesWithFuelSensor={true}
    extraFieldList = {{
      "select1_name": t('analysisType'),
      "select1_options": [[0, t('refills') + ' + ' + t('drops')], [1, t('refills')], [2, t('drops')]],
      "select1_value": 0,
    }}>
    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportFuelAnalysis;
