import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const Content = () => {

    console.log("client_details", global.client_details)

    return (
        <div style={{ margin: "0px 30px 0px 30px" }}>

            <img src="/logo3.svg" style={{width: '-webkit-fill-available'}}/><br/><br/>

            <table style={{fontSize: '0.9em', borderSpacing: '0px 1em'}}>

                <tr>
                    <td style={{ display: 'block' }}>
                        <FontAwesomeIcon icon={faHome} style={{color: '#78A9FF', fontSize: '1em'}}/>
                    </td>
                    <td style={{ fontWheight: 'bold', color: 'black'}}>
                        {
                            <div>
                                { global.client_details && global.client_details.sales_name ? global.client_details.sales_name : '' }
                            </div>
                        }
                        {
                            global.client_details && global.client_details.sales_address ?
                            global.client_details.sales_address.split("\n").map((address) => (
                                <div>
                                    {address}
                                </div>
                            ))
                            : ''
                        }
                        {
                            global.client_details && global.client_details.sales_gps ?
                            <a href={"https://www.google.com/maps/place/" + global.client_details.sales_gps.replace(" ", "")} target="_blank" style={{textDecoration: 'none', color: 'black'}}>
                                GPS: {global.client_details.sales_gps}
                            </a>
                            : ''
                        }
                    </td>
                </tr>

                <tr>
                    <td style={{ display: 'block' }}>
                        <FontAwesomeIcon icon={faEnvelope} style={{color: '#78A9FF', fontSize: '1em'}}/>
                    </td>
                    <td>
                        {
                            global.client_details && global.client_details.sales_email ?
                            global.client_details.sales_email.split("\n").map((email) => (
                                <div>
                                    <a href={"mailto:" + email} style={{textDecoration: 'none', color: 'black'}}>
                                        {email}
                                    </a>
                                </div>
                            ))
                            : ''
                        }
                    </td>
                </tr>

                <tr>
                    <td style={{ display: 'block' }}>
                        <FontAwesomeIcon icon={faPhoneAlt} style={{color: '#78A9FF', fontSize: '1em'}}/>
                    </td>
                    <td>
                        {
                            global.client_details && global.client_details.sales_phone ?
                            global.client_details.sales_phone.split("\n").map((phone) => (
                                <div>
                                    <a href={"https://wa.me/" + phone.replace(" ", "").replace("+", "")} style={{textDecoration: 'none', color: 'black'}}>
                                        {phone}
                                    </a>
                                </div>
                            ))
                            : ''
                        }
                    </td>
                </tr>

            </table>
        </div>
    );
}

const Conctacts = ({ open, setOpen }) => {
    
    const theme = useTheme();

    return (
        <>
            <Dialog
                open={open}
                onClose={e => setOpen(false)}
                maxWidth="md"
                >
                    
                <DialogContent>
                    <DialogContentText>
                        <Content />
                        <Button autoFocus onClick={e => setOpen(false)} color="primary" style={{ float: 'right' }}>
                            OK
                        </Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Conctacts;