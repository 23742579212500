import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { positionsActions, devicesActions, groupsActions, driversActions, geofencesActions, sessionActions, tagsActions } from './store';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from './reactHelper';
import { getDeviceName } from './map/mapUtil';
import t from './common/localization';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { WebNotification, deployNotification } from "./WebNotification";

const SocketController = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authenticated = useSelector(state => !!state.session.user);
  const socketRef = useRef();

  const devices = useSelector(state => Object.values(state.devices.items));
  const geofences = useSelector(state => Object.values(state.geofences.items)); 
  const drivers = useSelector(state => Object.values(state.drivers.items)); 
  const groups = useSelector(state => Object.values(state.groups.items));
  const tags = useSelector(state => Object.values(state.tags.items));

  useEffect(() => {
      global.devices = devices
  }, [devices]);

  useEffect(() => {
      global.geofences = geofences
  }, [geofences]);

  useEffect(() => {
      global.drivers = drivers
  }, [drivers]);

  useEffect(() => {
      global.groups = groups
  }, [groups]);

  useEffect(() => {
      global.tags = tags
  }, [tags]);

  useEffect(() => {
    flushpositionsQueue();
  }, []);

  const flushpositionsQueue = () => {
    if (global.positionsQueue) {

      var positionsToProcess = Array.from(global.positionsQueue);
      if (positionsToProcess && positionsToProcess.length>0) {
        console.log("flush position queue: ", global.positionsQueue)
        dispatch(positionsActions.update(positionsToProcess));
        global.positionsQueue.splice(0, positionsToProcess.length);
      }

      var eventsToProcess = Array.from(global.eventsQueue);
      if (eventsToProcess && eventsToProcess.length>0) {
        console.log("flush events queue: ", global.eventsQueue)
        eventsToProcess.forEach((eventToProcess) => {
          deployNotification(getEventMessage(eventToProcess));
        });
        global.eventsQueue.splice(0, eventsToProcess.length);
      }
      
    }
    setTimeout(flushpositionsQueue, 10000);
  }

  const getEventMessage = (event) => {
    return event.attributes && event.attributes.message ? event.attributes.message.replaceAll("\&\#39\;", "'") : "Alarm: " + message.type;
  }

  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(protocol + '//' + window.location.host + '/api/socket');
    socketRef.current = socket;

    socket.onopen = () => {
      console.log("Socket OPEN")
    };

    socket.onclose = () => {
      console.log("Socket CLOSE")
      setTimeout(() => connectSocket(), 1 * 1000);
    };

    socket.onmessage = (event) => {
      //console.log("Socket MESSAGE")

      const data = JSON.parse(event.data);
      if (data.devices) {
        //console.log("Socket received device message")
        //console.log(data.devices)
        //dispatch(devicesActions.update(data.devices));
      }
      if (data.positions) {
        //console.log("Socket received position message")
        //console.log(data.positions)
        //dispatch(positionsActions.update(data.positions));
        global.positionsQueue.push(data.positions[0]);
      }
      if (data.events) {
        //console.log("Socket received event message")
        //console.log(data.events)
        //deployNotification(getEventMessage(data.events[0]));
        global.eventsQueue.push(data.events[0]);
      }
    };
  }

  useEffectAsync(async () => {
    const response = await fetch('/api/server');
    if (response.ok) {
      dispatch(sessionActions.updateServer(await response.json()));
    }
  }, []);

  useEffectAsync(async () => {
    if (authenticated) {
      const response_groups = await fetch('/api/groups');
      if (response_groups.ok) {
        dispatch(groupsActions.refresh(await response_groups.json()));
      }
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      }
      const response_drivers = await fetch('/api/drivers');
      if (response_drivers.ok) {
        dispatch(driversActions.refresh(await response_drivers.json()));
      }
      const response_geofences = await fetch('/api/geofences');
      if (response_geofences.ok) {
        dispatch(geofencesActions.refresh(await response_geofences.json()));
      }
      const response_tags = await fetch('/report/api.php/tags');
      if (response_tags.ok) {
        dispatch(tagsActions.refresh(await response_tags.json()));
      }
      const response_positions = await fetch('/api/positions');
      if (response.ok) {
        dispatch(positionsActions.update(await response_positions.json()));
      }
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close();
        }
      }
    } else {


      const urlSearchParams = new URLSearchParams(window.location.search);
      const queryString = Object.fromEntries(urlSearchParams.entries());

      console.log("Try session")

      var response = await fetch('/api/session');
      if (response.ok) {

        if (queryString.hasOwnProperty('id'))
        {
          await fetch('/api/session', {method: 'DELETE'});
          response = await fetch('/api/session?token=' + queryString.id);
          if (!response.ok) {
            history.push('/login');
          }
        }
        dispatch(sessionActions.updateUser(await response.json()));
      } else {
        if (queryString.hasOwnProperty('id'))
        {
          response = await fetch('/api/session?token=' + queryString.id);
          if (!response.ok) {
            history.push('/login');
          }
          dispatch(sessionActions.updateUser(await response.json()));
        } else {
          history.push('/login');
        }
      }
    }
  }, [authenticated]);

  return (
    <WebNotification />
  );
}

export default connect()(SocketController);
