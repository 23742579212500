import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useEffectAsync } from '../reactHelper';
import TransferListControl from './TransferListControl';

const TransferList = ({
  title,
  item,
  setItem,
  allowShowGroups,
  element = false,
  attribute = false,
  csv = false,
  fixedWidth = false,
  allowAdd = true,
  allowRemove = true,

  available,
  associated,

}) => {

  const { id } = useParams();

  let availableItems = {};
  if (available[0] == 'store') availableItems = useSelector(state => Object.values(state[available[1]].items));
  if (available[0] == "value") availableItems = available[1];

  const [associatedItemsLoaded, setAssociatedItemsLoaded] = useState(false);
  const [selectedItems, setSelectedItems] = useState(false);

  useEffectAsync(async () => {
    if (associated[0] == "api" && !associatedItemsLoaded && item && item.id) {
      setAssociatedItemsLoaded(true);
      var response = await fetch(`${associated[1]}?${associated[2]}=${item.id}`);
      if (response.ok) setSelectedItems((await response.json()).map(item => item.id))
    }
  }, [item]);

  useEffectAsync(async () => {
    if (associated[0] == "value" && !associatedItemsLoaded && associated[1] !== false) {
      setAssociatedItemsLoaded(true);
      setSelectedItems(associated[1]);
    }
  }, [associated]);
  
  return (
    <TransferListControl
      title = {title}
      item = {item}
      setItem = {setItem}
      availableItems = {availableItems}
      selectedItems = {id == undefined ? [] : selectedItems}
      allowShowGroups = {allowShowGroups}
      element = {element}
      attribute = {attribute}
      csv = {csv}
      fixedWidth = {fixedWidth}
      allowAdd = {allowAdd}
      allowRemove = {allowRemove}
      />
  );
}

export default TransferList;
