import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, IconButton, Button } from '@material-ui/core';

import t from '../common/localization';
import { formatDate, formatBigNumber } from '../common/formatter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarBattery } from '@fortawesome/free-solid-svg-icons'
import { faGasPump } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faDrawPolygon } from '@fortawesome/free-solid-svg-icons'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { getFuelColor, getIgnitionColor, getVehicleIconColor, getBatteryColor, getBatteryValue, getFuelValue, immobilizationState, getImmobilizationColor, getOdometerValue } from './mapUtil'

import StreetImage from './StreetImage';

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: '1rem',
    fontFamily: 'Proxima Nova Regular,Roboto,Arial',
    fontWeight: '600',
    lineHeight: '1.5'
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 }, 
  },  
  blink: {
      animationName: '$blinker',
      animationDuration: '0.5s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
  },
}));

const DeviceStatusHistoryView = ({ position, deviceId, closestGeofence }) => {

  const [showHours, setShowHours] = useState(global.showHours[deviceId]);
  
  const toggleShowHours = (e) => {
    e.preventDefault();
    global.showHours[deviceId] = !showHours
    setShowHours(!showHours)
  };

  return (
    <>

      <div style={{ textAlign: 'center', paddingTop: '5px', float: 'left' }}>
        <StreetImage position={{
            latitude: position.geometry.coordinates[1],
            longitude: position.geometry.coordinates[0],
            course: position.properties.course
        }}/>
      </div>

      <div style={{ padding: '0px 10px 0px 10px', fontFamily: 'Proxima Nova Regular', fontWeight: '600', float: 'right', color: 'black' }}>

        <div style={{fontWeight: 'bold', fontSize: '1.1rem', marginTop: '5px', marginBottom: '5px'}}>
          { position.properties.name }
        </div>
          
        <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '0.75rem', marginBottom: '5px' }}>
          { position.properties.license_plate }
        </div>

        { position.properties.driver &&
          <div style={{display: 'flex', marginBottom: '2px'}}>
            <div style={{textAlign: 'center', minWidth: '15px'}}>
              <FontAwesomeIcon icon={faUser}  style={{color: '#78A9FF'}}/>
            </div>
            <div style={{float: 'right', paddingLeft: '5px'}}>
              { position.properties.driver }
            </div>
          </div>
        }

        { false &&
          <div style={{display: 'flex', marginBottom: '2px'}}>
            <div style={{textAlign: 'center', minWidth: '15px'}}>
              <FontAwesomeIcon icon={faHome}  style={{color: '#78A9FF'}}/>
            </div>
            <div style={{float: 'right', paddingLeft: '5px'}}>
              <a href={'https://www.google.com/maps/place/' + position.geometry.coordinates[1] + ',' + position.geometry.coordinates[0] + '/@' + position.geometry.coordinates[1] + ',' + position.geometry.coordinates[0] + ',12z'} target='_blank' style={{color: 'black', textDecoration: 'none'}}>
              { position.address }
              </a>
            </div>
          </div>
        }

        { closestGeofence.name &&
          <div style={{display: 'flex', marginBottom: '2px'}}>
            <div style={{textAlign: 'center', minWidth: '15px'}}>
              <FontAwesomeIcon icon={closestGeofence.type == "point" ? faMapMarker : faDrawPolygon}  style={{color: '#78A9FF'}}/>
            </div>
            <div style={{float: 'right', paddingLeft: '5px'}}>
              { closestGeofence.name }
            </div>
          </div>
        }

        <div style={{display: 'flex', marginBottom: '2px'}}>
          <div style={{textAlign: 'center', minWidth: '15px'}}>
            <FontAwesomeIcon icon={faGlobe}  style={{color: '#78A9FF'}}/>
          </div>
          <div style={{float: 'right', paddingLeft: '5px'}}>
            { position.geometry.coordinates[1].toFixed(6) + ', ' + position.geometry.coordinates[0].toFixed(6) }
            <a class="tooltip" href={'https://www.google.com/maps/place/' + position.geometry.coordinates[1] + ',' + position.geometry.coordinates[0] + '/@' + position.geometry.coordinates[1] + ',' + position.geometry.coordinates[0] + ',12z'} target='_blank' style={{color: 'black', textDecoration: 'none', marginLeft: '5px', position: 'relative', top: '2px', fontSize: '1rem'}}>
              <FontAwesomeIcon icon={faLocationArrow}  style={{color: '#78A9FF'}}/>
              <span class="tooltiptext" style={{ marginLeft: '0px' }}>
                Navegar
              </span>
            </a>
          </div>
        </div>

        <div style={{display: 'flex', marginBottom: '2px'}}>
          <div style={{textAlign: 'center', minWidth: '15px'}}>
            <FontAwesomeIcon icon={faClock} style={{color: '#78A9FF'}}/>
          </div>
          <div style={{float: 'right', paddingLeft: '5px'}}>
            { position.properties.fixTime }
          </div>                  
        </div>

        { position.properties.event_details &&
          <div style={{display: 'flex', marginBottom: '2px'}}>
            <div style={{textAlign: 'center', minWidth: '15px'}}>
              <FontAwesomeIcon icon={faInfoCircle} style={{color: '#78A9FF'}}/>
            </div>
            <div style={{float: 'right', paddingLeft: '5px'}}>
              { position.properties.event_details }
            </div>                  
          </div>
        }

        <div style={{display: 'block', textAlign: 'center', marginTop: '10px', marginBottom: '5px'}}>

          <span style={{ marginLeft: '10px', color: getBatteryColor(position.properties) }}>
            <FontAwesomeIcon icon={faCarBattery} /> { getBatteryValue(position.properties) }
          </span>

          <span
            class="tooltip"
            onClick={toggleShowHours}
            style={{ marginLeft: '20px', marginRight: '10px', color: '#000000', cursor: 'pointer' }}
            >
            { !showHours &&
              <>
                <span class="tooltiptext">
                  { t('odometerVirtual') }
                </span>
                <FontAwesomeIcon icon={faRoad} /> { getOdometerValue(position.properties) }
              </>
            }
            { showHours &&
              <>
                <span class="tooltiptext">
                  { t('hoursVirtual') }
                </span>
                <FontAwesomeIcon icon={faClock} /> { Math.round(position.properties.totalHours/1000) } h
              </>
            }                   
          </span>

        </div>
    </div>
  </>
  );
};

export default DeviceStatusHistoryView;
