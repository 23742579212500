import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'geofences',
  initialState: {
    items: {},
    selectedGeofenceId: null,
    selectedGeofenceName: null,
    selectedGeofenceIdToRemove: null,
    selectedGeofenceNameToRemove: null
  },
  reducers: {
    refresh(state, action) {
      state.selectedGeofenceId = null;
      state.items = {};
      action.payload.forEach(item => state.items[item['id']] = item);
    },
    update(state, action) {
      state.selectedGeofenceId = null;
      state.selectedGeofenceName = null;
      action.payload.forEach(item => state.items[item['id']] = item);
    },
    update2(state, action) {
      state.selectedGeofenceId = null;
    },
    select(state, action) {
      state.selectedGeofenceId = action.payload.id;
      global.selectedGeofenceId = action.payload.id;
      global.selectedGeofenceIdMenu = action.payload.id;
      
      state.selectedGeofenceName = action.payload.name;
      global.selectedGeofenceName = action.payload.name;
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
  }
});

export { actions as geofencesActions };
export { reducer as geofencesReducer };
