import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionDetails, makeStyles, Switch } from '@material-ui/core';
import { addEditString } from '../common/stringUtils';

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const TemporaryLinkPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({
    expirationTime: moment().add(7, 'day').endOf('day'),
    attributes: {
      allowHistory: false,
      allowHistoryFrom: moment().startOf('day'),
      allowHistoryTo: moment().endOf('day'),
    }
  });

  return (
    <EditItemView endpoint="temporary_links" item={item} setItem={setItem} title={ t('temporaryLinks') + ' > ' + addEditString(t('addTemporaryLink'), t('editTemporaryLink')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                />

              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    label={t('expirationDate')}
                    format="DD/MM/yyyy HH:mm:ss"
                    ampm={false}
                    variant="dialog"
                    value={item.expirationTime}
                    onChange={event => setItem({...item, expirationTime: event})}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <TransferList
                title={t('associatedVehicles')}
                item={item}
                setItem={setItem}
                element="associated_devices"
                available={["store", "devices"]}
                associated={["api", "/api/devices", "userId"]}
                searchKey="userId"
                />
              
              <TransferList
                title={t('associatedZones')}
                item={item}
                setItem={setItem}
                element="associated_geofences"
                available={["store", "geofences"]}
                associated={["api", "/api/geofences", "userId"]}
                searchKey="userId"
                />

              <div style={{ display: 'inline-block', marginTop: '20px' }}>
                <Switch color="primary"
                  checked={item.attributes.allowHistory}
                  onChange={event => setItem({...item, attributes: {...item.attributes, allowHistory: event.target.checked}})}
                  /> {t('allowHistory')}
                  { item.attributes.allowHistory &&
                  ' ' + t('allowHistoryInPeriod')
                  }
              </div>

              { item.attributes.allowHistory &&
                <div style={{ display: 'inline-block', marginLeft: '60px' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      label={t('from')}
                      format="DD/MM/yyyy HH:mm:ss"
                      ampm={false}
                      variant="dialog"
                      value={item.attributes.allowHistoryFrom}
                      onChange={event => setItem({...item, attributes: {...item.attributes, allowHistoryFrom: event.startOf('day')}})}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      label={t('to')}
                      format="DD/MM/yyyy HH:mm:ss"
                      ampm={false}
                      variant="dialog"
                      value={item.attributes.allowHistoryTo}
                      onChange={event => setItem({...item, attributes: {...item.attributes, allowHistoryTo: event.endOf('day')}})}
                    />
                  </MuiPickersUtilsProvider>                  
                </div>
              }
              <div style={{ marginBottom: '20px' }}>

              </div>

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default TemporaryLinkPage;
