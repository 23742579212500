import { map } from './Map';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import mapboxgl from '!mapbox-gl';

import t from '../common/localization';

let directions;

let page = "";
let hide = true;

const ManageDirections = (event) => {
    let fromReplay = event != null && event.hasOwnProperty( "replayPage" ) ? true : false;
    let fromDrawer = event != null && event.hasOwnProperty( "drawer" ) ? true : false;
    let click = event != null && event.hasOwnProperty( "click" ) ? true : false;

    if(!fromReplay && !fromDrawer) {
        hide = !hide
    }

    if(fromReplay) {
        page = "replay"
    } else {
        if(fromDrawer) {
            page = "mainpage"
        }
    }

    const drawerEvent = null;
    const drawer = document.getElementById("mainDeviceList");
    const mobile = (document.getElementsByClassName("MuiBox-root").length == 1);
    const directions_inputs = document.getElementsByClassName("directions-control directions-control-inputs");
    const directions_instructions = document.getElementsByClassName("directions-control directions-control-instructions");

    document.querySelectorAll('.mapboxgl-style-layer').forEach(item => {
      item.addEventListener('click', event => {
          let direction_input = directions_inputs[0];
          if(directions_inputs.length > 0) {
              hide=true;
              removeDirections()  
          }
      })
    })

    if(page == "replay") {
        let direction_input = directions_inputs[0];
        let direction_instruction = directions_instructions[0];

        if(directions_inputs.length == 0) {
            if(!hide) {
                if(click) {
                    addDirections(mobile);
                }

                direction_input = directions_inputs[0];
                direction_instruction = directions_instructions[0];

                const elem = document.getElementById("closeBtn");

                if(!elem) {
                    var closeBtn = document.createElement('div');
                    closeBtn.id = 'closeBtn';
                    closeBtn.innerHTML = '<div style="height: 24px; background-color: white; border-radius: 50%"><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path fill="#FC0000" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></div>';
                    closeBtn.style.marginTop = "-91px"
                    closeBtn.style.marginLeft = "268px"
                    closeBtn.style.cursor = 'pointer'
                    closeBtn.style.zIndex = 15
                    closeBtn.style.position = 'absolute'
                    closeBtn.addEventListener('click', () => {
                        removeDirections()
                        hide=true;
                    } );
                    direction_input.appendChild(closeBtn)
                }

                if(mobile) {
                    direction_input.style.marginTop="100px"
                } else {
                    direction_input.style.marginTop="38px"
                }

                direction_instruction.style.display = "none";
            }
        } else {
          if(hide && click) {
              removeDirections()
          } else {
              /*if(mobile) {
                  direction_input.style.marginTop="100px"
              } else {
                  direction_input.style.marginTop="38px"
              }

              direction_input.style.marginLeft="0px"
              direction_instruction.style.marginLeft = direction_input.style.marginLeft;*/
              removeDirections()
              hide=true;
          }
        }
    } else {
        if(drawer) {
            let drawerWidth = drawer.offsetWidth;
            if(fromDrawer) {
                drawerWidth = drawerWidth == 0 ? 350 : 0
            }

            if(!hide) {
                if(click) {
                    addDirections(mobile);
                }

                const directions_inputs = document.getElementsByClassName("directions-control directions-control-inputs");
                const directions_instructions = document.getElementsByClassName("directions-control directions-control-instructions");            

                const direction_input = directions_inputs[0];
                const direction_instruction = directions_instructions[0];

                const elem = document.getElementById("closeBtn");

                if(!elem) {
                    var closeBtn = document.createElement('div');
                    closeBtn.id = 'closeBtn';
                    closeBtn.innerHTML = '<div style="height: 24px; background-color: white; border-radius: 50%"><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path fill="#FC0000" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></div>';
                    closeBtn.style.marginTop = "-91px"
                    closeBtn.style.marginLeft = "268px"
                    closeBtn.style.cursor = 'pointer'
                    closeBtn.style.zIndex = 15
                    closeBtn.style.position = 'absolute'
                    closeBtn.addEventListener('click', () => {
                        removeDirections()
                        hide=true;
                    } );
                    direction_input.appendChild(closeBtn)
                }

                if(mobile) {
                    direction_input.style.marginTop="100px"
                } else {
                    direction_input.style.marginTop="38px"
                }

                if(drawerWidth > 0) {
                    direction_input.style.marginLeft= (drawerWidth + 20) + "px"
                } else {
                    direction_input.style.marginLeft="0px"
                }

                if(direction_instruction) {
                    direction_instruction.style.marginLeft = direction_input.style.marginLeft;
                    direction_instruction.style.display = direction_input.style.display;
                }
            }
            if(hide && click) {
                removeDirections()
            }
        } else {
            if(fromDrawer) {
                let drawerWidth = 350

                setTimeout(()=>{
                    const directions_inputs = document.getElementsByClassName("directions-control directions-control-inputs");
                    const directions_instructions = document.getElementsByClassName("directions-control directions-control-instructions");            

                    const direction_input = directions_inputs[0];
                    const direction_instruction = directions_instructions[0];

                    const elem = document.getElementById("closeBtn");
                    
                    if(direction_input && elem) {
                        if(drawerWidth > 0) {
                            direction_input.style.marginLeft= (drawerWidth + 20) + "px"
                        } else {
                            direction_input.style.marginLeft="0px"
                        }    

                        direction_instruction.style.marginLeft = direction_input.style.marginLeft;
                    }
                },50)
            }
        }
    }   
}

const addDirections = (mobile) => {
    directions = new MapboxDirections({
        accessToken: mapboxgl.accessToken,
        unit: 'metric',
        profile: 'mapbox/driving',
        alternatives: true,
        language: 'pt-PT',
        controls: {
            instructions: mobile ? false : true,
            profileSwitcher: false
        },
        placeholderOrigin: t('origin'),
        placeholderDestination: t('destination'),
    });
    map.addControl(directions, mobile ? 'bottom-left' : 'top-left');

    directions.interactive(true)
    
    if (!map.getSource('directions')) {
      map.addSource('directions', {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: []
        }
      });
    }

    const layers = map.getStyle().layers;
    let traceId;
    for (const layer of layers) {
        if (layer.id === 'route' || layer.id === 'positions' || layer.id === 'trace') {
            traceId = layer.id;
            break;
        }
    }

    const directionsLayers = [{
        'id': 'directions-route-line-alt',
        'type': 'line',
        'source': 'directions',
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': '#bbb',
          'line-width': 4
        },
        'filter': [
          'all',
          ['in', '$type', 'LineString'],
          ['in', 'route', 'alternate']
        ]
      }, {
        'id': 'directions-route-line-casing',
        'type': 'line',
        'source': 'directions',
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': '#2d5f99',
          'line-width': 12
        },
        'filter': [
          'all',
          ['in', '$type', 'LineString'],
          ['in', 'route', 'selected']
        ]
      }, {
        'id': 'directions-route-line',
        'type': 'line',
        'source': 'directions',
        'layout': {
          'line-cap': 'butt',
          'line-join': 'round'
        },
        'paint': {
          'line-color': {
            'property': 'congestion',
            'type': 'categorical',
            'default': '#00FF00',
            'stops': [
              ['unknown', '#4882c5'],
              ['low', '#4882c5'],
              ['moderate', '#f09a46'],
              ['heavy', '#e34341'],
              ['severe', '#8b2342']
            ]
          },
          'line-width': 7
        },
        'filter': [
          'all',
          ['in', '$type', 'LineString'],
          ['in', 'route', 'selected']
        ]
      }, {
        'id': 'directions-hover-point-casing',
        'type': 'circle',
        'source': 'directions',
        'paint': {
          'circle-radius': 8,
          'circle-color': '#fff'
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'id', 'hover']
        ]
      }, {
        'id': 'directions-hover-point',
        'type': 'circle',
        'source': 'directions',
        'paint': {
          'circle-radius': 6,
          'circle-color': '#3bb2d0'
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'id', 'hover']
        ]
      }, {
        'id': 'directions-waypoint-point-casing',
        'type': 'circle',
        'source': 'directions',
        'paint': {
          'circle-radius': 8,
          'circle-color': '#fff'
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'id', 'waypoint']
        ]
      }, {
        'id': 'directions-waypoint-point',
        'type': 'circle',
        'source': 'directions',
        'paint': {
          'circle-radius': 6,
          'circle-color': '#8a8bc9'
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'id', 'waypoint']
        ]
      }, {
        'id': 'directions-origin-point',
        'type': 'circle',
        'source': 'directions',
        'paint': {
          'circle-radius': 18,
          'circle-color': '#3bb2d0'
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'marker-symbol', 'A']
        ]
      }, {
        'id': 'directions-origin-label',
        'type': 'symbol',
        'source': 'directions',
        'layout': {
          'text-field': 'A',
          'text-allow-overlap': true,
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        'paint': {
          'text-color': '#fff'
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'marker-symbol', 'A']
        ]
      }, {
        'id': 'directions-destination-point',
        'type': 'circle',
        'source': 'directions',
        'paint': {
          'circle-radius': 18,
          'circle-color': '#8a8bc9'
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'marker-symbol', 'B']
        ]
      }, {
        'id': 'directions-destination-label',
        'type': 'symbol',
        'source': 'directions',
        'layout': {
          'text-field': 'B',
          'text-allow-overlap': true,
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        'paint': {
          'text-color': '#fff'
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'marker-symbol', 'B']
        ]
      }];

    for (const layer of directionsLayers) {
        if (map.getLayer(layer.id)) map.removeLayer(layer.id);
        map.addLayer(layer, traceId);
    }
}

const removeDirections = () => {
    const elem = document.getElementById("closeBtn");
    if(elem) {
        elem.remove();
    }   
    //if (map.getSource('directions')) map.removeSource('directions');
    map.off('mousedown', null);
    map.off('mousemove', null);
    map.off('touchstart', null);
    map.off('touchstart', null);
    map.off('click', null);
    map.removeControl(directions); 
}

export default ManageDirections;
