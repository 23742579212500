import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportAlerts = () => {
  global.selectedMenu = "/report/alerts"

  const report_name = t('reportAlerts')
  const endpoint = '/report/report_alerts.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage setItems={setItems} endpoint={endpoint} reportName={report_name} showYear={true} showEventSelector={true}
      showVehicles={false}
      showDrivers={false}
      showVehiclesOrDrivers={true}
      >
        <ReportLayoutMessage items={items}></ReportLayoutMessage>
        <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportAlerts;