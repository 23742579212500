export const getItemGroups = async (itemType, itemId, associatedGroups, setAssociatedGroups) => {
    setAssociatedGroups([])
    const response = await fetch('/api/groups');
    if (response.ok) {
        const groups = await response.json();
        groups.forEach(group => {
            getItemGroupsIteration(itemType, itemId, group.id, associatedGroups, setAssociatedGroups);
        });
    }
}

const getItemGroupsIteration = async (itemType, itemId, groupId, associatedGroups, setAssociatedGroups) => {
    const response = await fetch(`/api/${itemType}?groupId=${groupId}`);
    if (response.ok) {
        var result = await response.json();
        result.forEach(element => {
            if (element.id == itemId) setAssociatedGroups(associatedGroups => [...associatedGroups, groupId]);
        });
    }
}
