import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const deployNotification = (message, sound = true, attributes = false) => {

    if (sound) {
      playAudio(global.user_details && global.user_details.attributes.hasOwnProperty('notification_sound') ? global.user_details.attributes.notification_sound : "horn");
    }

    if (!attributes) {
      attributes = {
        position: "bottom-right",
        theme: "dark",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        pauseOnFocusLoss: true,
      }
    }
    
    toast.info(message, attributes);
}

export const playAudio = (sound) => {
  const audio = new Audio(`sounds/${sound}.mp3`);
  audio.play();
}

export const updateNotification = (id, content) => {
  toast.update(id, {
    render: content,
  });
}

export const closeNotification = (id) => {
  toast.dismiss(id);
}

export const WebNotification = () => {
  return (
    <ToastContainer style={{ width: "33%" }}/>
  );
}
