import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import MenuTitle from './MenuTitle';
import MainToolbar from './MainToolbar';
import t from './common/localization';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Snackbar, InputAdornment, Tooltip, CircularProgress, makeStyles, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Accordion, AccordionSummary, AccordionDetails, Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, TextField, ButtonGroup, IconButton, FormGroup, FormControlLabel, FormLabel, FormHelperText, Switch, Menu, Typography, RadioGroup, Radio, Backdrop } from '@material-ui/core';

import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend, Label } from 'recharts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

const color_red = '#dc2626'
const color_green = '#16a34a'
const color_blue = '#66b2ff'
const color_purple = '#b266ff'
const color_black = '#000000'
const color_orange = '#ffb266'

const color_list = [color_red, color_green, '#00C49F', '#FFBB28', '#FF8042'];

const textSize = 16;
const textColor = "#000000";

const textSize2 = 14;
const textColor2 = "#999";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const dashboardCard = ({children, id}) => {
  return <div>
    {children}
  </div>;
}

const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(2),
      padding: '5px'
    },
    form: {
      padding: theme.spacing(1, 2, 2),
      minWidth: 'max-content'
    },
    dashboardCard: {
      display: 'flex',
      marginTop: '20px',
      backgroundColor: '#ffffff',
      width: 'fit-content',
      borderRadius: '10px',
      padding: '10px',
      position: 'relative',
      //border: '2px solid #006100',
    },
    centerVerticallyLeft: {
      margin: 0,
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      marginLeft: '10px',
    },
    centerVerticallyRight: {
      margin: 0,
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      marginLeft: '10px',
      marginRight: '30px',
      height: 'fit-content',
    },
  }));


const Dashboard = () => {

    global.selectedMenu = "/dashboard"

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [deviceStatistics, setDeviceStatistics] = useState([]);
    
    const [chart_vehicles_with_activity_today, set_chart_vehicles_with_activity_today] = useState(false);
    const [chart_alert_types, set_chart_alert_types] = useState(false);
    const [chart_alert_deviceOverspeed, set_chart_alert_deviceOverspeed] = useState(false);

    useEffect(() => {
      getDeviceStatistics();
    }, [])
    
    useEffect(() => {

      if (deviceStatistics.length>0) {

        var vehicles_with_activity_today = [
          { name: "Com posição", value: 0, color: color_green },
          { name: "Sem posição", value: 0, color: color_red },
        ];
  
        var alert_types = [
          { name: "Excesso de velocidade", value: 0, color: color_red },
          { name: "Aceleração Brusca", value: 0, color: color_green },
          { name: "Travagem Brusca", value: 0, color: color_blue },
          { name: "Curva Brusca", value: 0, color: color_purple },
          { name: "Bateria Baixa", value: 0, color: color_black },
          { name: "Botão de Pânico", value: 0, color: color_orange },
        ];
        
        var max_deviceOverspeed = 0;
  
        deviceStatistics.forEach(device => {
  
          //vehicles_with_activity_today
          if (device.lastignition > device.lastreset) {
            vehicles_with_activity_today[0].value += 1;
          } else {
            vehicles_with_activity_today[1].value += 1;
          }
  
          //alert_types
          alert_types[0].value += device.alert_deviceOverspeed;
          alert_types[1].value += device.alert_hardAcceleration;
          alert_types[2].value += device.alert_hardBraking;
          alert_types[3].value += device.alert_hardCornering;
          alert_types[4].value += device.alert_lowBattery;
          alert_types[5].value += device.alert_sos;
        });
  
        var alert_deviceOverspeed = []
        var sortedList  = deviceStatistics.sort((a, b) => b.alert_deviceOverspeed - a.alert_deviceOverspeed).slice(0, 5)
        for (let index = 0; index < 5; index++) {
          alert_deviceOverspeed.push({
            name: getDeviceLicensePlate(sortedList[index].deviceid),
            value: sortedList[index].alert_deviceOverspeed,
            color: color_list[index]
          })
        }
        
        set_chart_vehicles_with_activity_today(vehicles_with_activity_today);
        set_chart_alert_types(alert_types);
        set_chart_alert_deviceOverspeed(alert_deviceOverspeed);

      }

    }, [deviceStatistics])

    //14937

    const getDeviceName = (id) => {
      var device = getDevice(id)
      if (device) return device.name;
      return "";
    }

    const getDeviceLicensePlate = (id) => {
      var device = getDevice(id)
      if (device && device.attributes) return device.attributes.license_plate;
      return "";
    }

    const getDevice = (id) => {
      return global.devices.filter(device => device.id == id)[0]
    }

    const getDeviceStatistics = async () => {
      setLoading(true);
      const response = await fetch(`/report/api.php/device_statistics`, { method: 'GET' });
      if (response.ok) {
        setDeviceStatistics(await response.json());
        setLoading(false);
      }
    }

    return (

      <div className={classes.root}>
      
        <MainToolbar />

        <div className={classes.content}>

          <Paper className={classes.form}>

            <MenuTitle title="Dashboard" extraData={<span>
              <Tooltip title="Actualizar dados">
                <IconButton
                  onClick={() => { getDeviceStatistics(); }}
                  style={{padding: '0px', color: '#78A9FF', marginLeft: '10px', top: '3px'}}
                  >
                    <FontAwesomeIcon icon={faSyncAlt} style={{color: '#78A9FF', fontSize: '0.8em'}}/>
                </IconButton>
              </Tooltip>
            </span>}
            />
            
            <div id="content" style={{ display: 'inline-flex' }}>
              
              { chart_vehicles_with_activity_today &&
                (
                  chart_vehicles_with_activity_today[0].value
                  + chart_vehicles_with_activity_today[1].value > 0
                )
                &&
                <div id="activityToday" className={classes.dashboardCard}>
                  <PieChart width={150} height={150} className={classes.centerVerticallyLeft}>
                    <Pie
                      data={chart_vehicles_with_activity_today}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      //label={renderCustomizedLabel}
                      labelLine={false}
                      innerRadius={55}
                      outerRadius={70}
                      startAngle={0}
                      endAngle={360}
                    >
                      {chart_vehicles_with_activity_today.map((item, index) => (
                        <Cell key={`cell-${index}`} fill={item.color} />
                      ))}
                    </Pie>

                    <text
                      x="50%"
                      y="48%"
                      textAnchor="middle"
                      fontSize={textSize*1.2}
                      fill={textColor}
                    >
                      { chart_vehicles_with_activity_today[0].value + chart_vehicles_with_activity_today[1].value }
                    </text>

                    <text
                      x="50%"
                      y="62%"
                      textAnchor="middle"
                      fontSize={textSize2*1.2}
                      fill={textColor2}
                    >
                      Veículos
                    </text>
                  </PieChart>

                  <div className={classes.centerVerticallyRight}>
                    {chart_vehicles_with_activity_today.map((item, index) => (
                      <div
                        style={{
                          borderLeft: "solid 5px " + item.color,
                          marginBottom: '5px',
                          paddingLeft: '10px',
                          position: 'relative',
                          //top: '15%',
                          }}>
                        <div style={{ fontSize: textSize, color: textColor }}>{item.name}</div>
                        <div style={{ fontSize: textSize2, color: textColor2 }}>{item.value} Veículos</div>
                      </div>
                    ))}
                  </div>
                </div>
              }





              { chart_alert_types &&
                (
                  chart_alert_types[0].value
                  + chart_alert_types[1].value
                  + chart_alert_types[2].value
                  + chart_alert_types[3].value
                  + chart_alert_types[4].value
                  + chart_alert_types[5].value >0
                ) &&
                <div id="chartTypes" className={classes.dashboardCard}>
                  <PieChart width={150} height={150} className={classes.centerVerticallyLeft}>
                    <Pie
                      data={chart_alert_types}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      //label={renderCustomizedLabel}
                      labelLine={false}
                      innerRadius={55}
                      outerRadius={70}
                      startAngle={0}
                      endAngle={360}
                    >
                      {chart_alert_types.map((item, index) => (
                        <Cell key={`cell-${index}`} fill={item.color} />
                      ))}
                    </Pie>

                    <text
                      x="50%"
                      y="48%"
                      textAnchor="middle"
                      fontSize={textSize*1.2}
                      fill={textColor}
                    >
                      { chart_alert_types[0].value
                        + chart_alert_types[1].value
                        + chart_alert_types[2].value
                        + chart_alert_types[3].value
                        + chart_alert_types[4].value
                        + chart_alert_types[5].value
                      }
                    </text>

                    <text
                      x="50%"
                      y="62%"
                      textAnchor="middle"
                      fontSize={textSize2*1.2}
                      fill={textColor2}
                    >
                      Alertas
                    </text>
                  </PieChart>

                  <div className={classes.centerVerticallyRight}>
                    {chart_alert_types.filter(item => item.value>0).map((item, index) => (
                      <div
                        style={{
                          borderLeft: "solid 5px " + item.color,
                          marginBottom: '5px',
                          paddingLeft: '10px',
                          position: 'relative',
                          //top: '15%',
                          }}>
                        <div style={{ fontSize: textSize, color: textColor }}>{item.name}</div>
                        <div style={{ fontSize: textSize2, color: textColor2 }}>{item.value} Alertas</div>
                      </div>
                    ))}
                  </div>
                </div>
              }


              { chart_alert_deviceOverspeed &&
                <div id="chartTypes" className={classes.dashboardCard}>
                  <PieChart width={150} height={150} className={classes.centerVerticallyLeft}>
                    <Pie
                      data={chart_alert_deviceOverspeed}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      //label={renderCustomizedLabel}
                      labelLine={false}
                      innerRadius={55}
                      outerRadius={70}
                      startAngle={0}
                      endAngle={360}
                    >
                      {chart_alert_deviceOverspeed.map((item, index) => (
                        <Cell key={`cell-${index}`} fill={item.color} />
                      ))}
                    </Pie>

                    <text
                      x="50%"
                      y="39%"
                      textAnchor="middle"
                      fontSize={textSize2*1.2}
                      fill={textColor}
                    >
                      Alertas
                    </text>

                    <text
                      x="50%"
                      y="52%"
                      textAnchor="middle"
                      fontSize={textSize2*1.2}
                      fill={textColor}
                    >
                      Velocidade
                    </text>

                    <text
                      x="50%"
                      y="67%"
                      textAnchor="middle"
                      fontSize={textSize2*1.2}
                      fill={textColor}
                    >
                      TOP 5
                    </text>
                  </PieChart>

                  <div className={classes.centerVerticallyRight}>
                    {chart_alert_deviceOverspeed.filter(item => item.value>0).map((item, index) => (
                      <div
                        style={{
                          borderLeft: "solid 5px " + item.color,
                          marginBottom: '5px',
                          paddingLeft: '10px',
                          position: 'relative',
                          //top: '15%',
                          }}>
                        <div style={{ fontSize: textSize, color: textColor }}>{item.name}</div>
                        <div style={{ fontSize: textSize2, color: textColor2 }}>{item.value} Alertas</div>
                      </div>
                    ))}
                  </div>
                </div>
              }
















            </div>

            <div style={{ width: '100%', marginTop: '40px', textAlign: 'center' }}>
              { deviceStatistics.length>0 && deviceStatistics[0].last_reset &&
                <>Estatísticas desde {deviceStatistics[0].last_reset} UTC</>
              }
            </div>

          </Paper>
        </div>
      </div>

    );
}

export default Dashboard;
