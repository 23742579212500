import React from 'react';

import t from './common/localization';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

const SetDialog = ({ open, setOpen, dialogContent }) => {

  const ganerateHtml = (text) => {
    text = text.replace(/\n/g, "<br>")

    const phoneNumber = text.match(/\+(\d+)\s+(\d+)/); //+xxx xxxxxxxxxxx
    if (phoneNumber && phoneNumber[0]) {
      text = text.replace(phoneNumber[0], `<a href="tel:${phoneNumber[0].replace(" ", "")}">${phoneNumber[0]}</a>`)
    }

    return text;
  }

  return (
    <>
      <Dialog open={open} onClose={setOpen}>
        <DialogTitle>
          {dialogContent.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'}>
            <Grid container spacing={1} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <Box display={dialogContent.icon == 'ok' ? 'block' : 'none'}>
                  <CheckCircleIcon  fontSize="medium" style={{color: '#00bb00'}}/>&nbsp;
                </Box>
                <Box display={dialogContent.icon == 'error' ? 'block' : 'none'}>
                  <ErrorIcon fontSize="medium" style={{color: '#bb0000'}}/>&nbsp;
                </Box>
                <Box display={dialogContent.icon == 'info' ? 'block' : 'none'}>
                  <InfoIcon fontSize="medium" style={{color: '#0000bb'}}/>&nbsp;
                </Box>
                <div dangerouslySetInnerHTML={{ __html: dialogContent.message ? ganerateHtml(dialogContent.message) : '' }} />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpen(false) }} color="primary" variant="outlined">OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SetDialog;
