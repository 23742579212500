import React, { useState } from 'react';
import MainToolbar from '../MainToolbar';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, Paper } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import t from '../common/localization';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import moment from 'moment';
import MenuTitle from '../MenuTitle';
import { useWindowDimensions } from '../common/WindowDimensions';

const useStyles = makeStyles(theme => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#78A9FF11 !important"
    }
  },
  paper: {
    padding: theme.spacing(1, 2, 2), minWidth: 'max-content'
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
}));

const ReportScheduleView = ({ updateTimestamp, onMenuClick, searchText = "" }) => {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  
  const { pageHeight, pageWidth } = useWindowDimensions();
  document.getElementById('root').style.overflow = 'hidden';

  useEffectAsync(async () => {
    const response = await fetch('/report/api.php/report_scheduler');
    if (response.ok) {
      setItems(await response.json());
    }
  }, [updateTimestamp]);

  const periodicities = [
    {"id": "D", "name": t('daily')},
    {"id": "W", "name": t('weekly')},
    {"id": "M", "name": t('monthly')}
  ];

  const reports = [
    {"id": "trips", "name": t('trips')},
    {"id": "activity", "name": t('activity')},
    {"id": "speeding", "name": t('speed')},
    {"id": "luanda_speeding", "name": t('luandaSpeeding')},
    {"id": "km", "name": t('km')},
    {"id": "chart_km", "name": t('chartKm')},
    {"id": "positions", "name": t('positions')},
    {"id": "fuel_analysis", "name": t('fuelAnalysis')},
    {"id": "alerts", "name": t('alerts')},
    {"id": "pto", "name": t('pto')},
    {"id": "vista_waste", "name": "Actividade Vista Waste"},
    {"id": "detailed_activity", "name": t('reportDetailedActivity')},
    {"id": "castel_daily", "name": t('reportCastelDaily')},
    {"id": "castel_monthly", "name": t('reportCastelMonthly')},
    {"id": "castel_monthly_detailed", "name": t('reportCastelMonthlyDetailed')},
    {"id": "zones", "name": t('zones')},
  ];

  const getValue = (list, id) => {
    const result = list.filter((value) => value.id == id)[0]
    if (typeof(result) != 'undefined') {
      if (result.hasOwnProperty('name')) {
        return result.name
      }
    }
  }

  const getPeriodicityDetails = (item) => {

    const wekdays = [
      t('week_sunday'),
      t('week_monday'),
      t('week_tuesday'),
      t('week_wednesday'),
      t('week_thursday'),
      t('week_friday'),
      t('week_saturday'),
    ]

    if (item.report_periodicity == 'W') {
      return " (" + wekdays[item.report_day] + ")";
    }
    if (item.report_periodicity == 'M') {
      return " (" + t('day') + " " + item.report_day + ")";
    }
    return "";
    
  }

  const getNextRun = (periodicity, report_day) => {
    switch(periodicity) {
      case 'D':
        return moment().add(1, 'days').format('DD/MM/YYYY')

      case 'W':
        for (let index = 1; index < 32; index++) {
          if (moment().add(index, 'days').day() == report_day ) {
            return moment().add(index, 'days').format('DD/MM/YYYY');
          }
        }

      case 'M':
        for (let index = 1; index < 32; index++) {
          if (moment().add(index, 'days').date() == report_day ) {
            return moment().add(index, 'days').format('DD/MM/YYYY');
          }
        }
    }
    return '-'
  }

  return (
    <TableContainer style={{ maxHeight: pageHeight - 160 }}>
    <Table stickyHeader style={{}}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.columnAction} />
          <TableCell>{t('name')}</TableCell>
          <TableCell>{t('type')}</TableCell>
          <TableCell>{t('periodicity')}</TableCell>
          <TableCell>{t('format')}</TableCell>
          <TableCell>{t('vehicles')}</TableCell>
          <TableCell>{t('recipient')}</TableCell>
          <TableCell>{t('lastExecution')}</TableCell>
          <TableCell>{t('nextExecution')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          ((
            item.report_name.toLowerCase().indexOf(searchText.toLowerCase())>-1 ||
            item.report_type.toLowerCase().indexOf(searchText.toLowerCase())>-1 ||
            item.report_destination.toLowerCase().indexOf(searchText.toLowerCase())>-1
          ) || searchText == "") &&
          <TableRow key={item.id} className={classes.tableRow}>
            <TableCell className={classes.columnAction} padding="none">
              <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                <MoreVertIcon />
              </IconButton>
            </TableCell>
            <TableCell>{item.report_name}</TableCell>
            <TableCell>{getValue(reports, item.report_type)}</TableCell>
            <TableCell>{getValue(periodicities, item.report_periodicity) + getPeriodicityDetails(item)}</TableCell>
            <TableCell>{item.report_format.toUpperCase()}</TableCell>
            <TableCell>{item.report_ids.split(',').filter(o=>o).map(item => parseInt(item)).length}</TableCell>
            <TableCell style={{maxWidth: '300px'}}>{item.report_destination}</TableCell>
            <TableCell>{item.last_run != null ? moment(item.last_run).format('DD/MM/YYYY') : ''}</TableCell>
            <TableCell>{getNextRun(item.report_periodicity, item.report_day)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  );
}

const ReportSchedulesPage = () => {
  global.selectedMenu = "/report/schedules"
  const classes = useStyles();
  return (
    <>
      <MainToolbar />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <MenuTitle title={t('scheduledReports')} />
          <EditCollectionView
            content={ReportScheduleView}
            editPath="/report/schedule"
            endpoint="../report/api.php/report_scheduler"
            allowAddRemove = {true}
            searchInput={true}
            itemName="agendamento"
          />
        </Paper>
      </div>
    </>
  );
}

export default ReportSchedulesPage;
