import ManageDirections from '../ManageDirections';


export class MagnifyingGlass {

  constructor(origin, destination, language) {
    this.origin = origin
    this.destination = destination
    this.language = language
  }

  onAdd(map) {
    this.map = map;
    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-directions-container');

    //Botão principal
    this.styleButton = document.createElement('button');
    this.styleButton.type = 'button';

    this.styleButton.classList.add('mapboxgl-style-magnifying-glass');
    this.styleButton.addEventListener('click', () =>  ManageDirections({'click':Date.now()}));
    this.controlContainer.appendChild(this.styleButton);

    return this.controlContainer;
  }

  onRemove() {
    if (!this.controlContainer || !this.controlContainer.parentNode || !this.map || !this.styleButton) {
      return;
    }
    this.controlContainer.parentNode.removeChild(this.controlContainer);
    this.map = undefined;
  }
}