export const poiCategories = [
	{name: 'fuel', 		zoom: 0.85},
	{name: 'generic', 	zoom: 0.85},
	{name: 'hospital', 	zoom: 0.85},
	{name: 'airport', 	zoom: 0.85},
	{name: 'bed', 		zoom: 0.85},
	{name: 'building', 	zoom: 0.85},
	{name: 'church', 	zoom: 0.85},
	{name: 'hotel', 	zoom: 0.85},
	{name: 'house', 	zoom: 0.85},
	{name: 'info', 		zoom: 0.85},
	{name: 'plant', 	zoom: 0.85},
	{name: 'question', 	zoom: 0.85},
	{name: 'restaurant',zoom: 0.85},
	{name: 'trash', 	zoom: 0.85},
	{name: 'warehouse', zoom: 0.85},
	{name: 'pharmacy', 	zoom: 0.85},
	{name: 'police', 	zoom: 0.85},
	{name: 'repair', 	zoom: 0.85},
	{name: 'supermarket',zoom: 0.85},
];
